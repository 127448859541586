import {Select, Space} from 'antd';
import React, {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import './LanguageSelector.less';
import {ReactComponent as ARROW_DOWN} from 'assets/icons/arrow_down.svg';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {breakpoint} from 'utils/types';
import {GoTriangleDown} from 'react-icons/go';

const LanguageSelector = () => {
	const [selectedLanguage, setSelectedLanguage] = useState<string>('en');
	const {i18n} = useTranslation();

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	const handleChange = (value: string) => {
		localStorage.setItem('language', value);
		setSelectedLanguage(value);
		i18n.changeLanguage(value);
	};

	useEffect(() => {
		const language = localStorage.getItem('language') || 'en';
		setSelectedLanguage(language);
		i18n.changeLanguage(language);
	}, []);

	return !isMobile ? (
		<Space wrap>
			<Select
				className={`ant-select-selection-item-language header-down-common-text`}
				defaultValue={localStorage.getItem('language') || 'en'}
				style={{width: 120}}
				onChange={handleChange}
				bordered={false}
				options={[
					{value: 'en', label: 'EN'},
					{value: 'ru', label: 'RU'}
				]}
				suffixIcon={<ARROW_DOWN />}
			/>
		</Space>
	) : (
		<Select
			className={`footer-language-selector`}
			defaultValue={localStorage.getItem('language') || 'en'}
			style={{width: 120}}
			onChange={handleChange}
			bordered={false}
			options={[
				{value: 'en', label: 'EN'},
				{value: 'ru', label: 'RU'}
			]}
			suffixIcon={<GoTriangleDown color="#868686" />}
		/>
	);
};

export default LanguageSelector;
