import './Notification.less';
import {RiErrorWarningFill} from 'react-icons/ri';

export interface NotificationProps {
	notificationLabel: string;
	notificationType: 'success' | 'error' | 'warning' | '';
}

const Notification = ({notificationLabel, notificationType}: NotificationProps) => {
	return (
		<div
			className={`notification-wrapper ${
				notificationType === 'success'
					? 'notification-wrapper__success'
					: notificationType === 'error'
					? 'notification-wrapper__error'
					: 'notification-wrapper__warning'
			}`}>
			<div className="notification-label-icon-wrapper">
				<RiErrorWarningFill />
				<span>{notificationLabel}</span>
			</div>
		</div>
	);
};

export default Notification;
