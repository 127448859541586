import BetslipHeader from './components/BetslipHeader';
import './Betslip.less';
import OddsHandlePanel from './components/OddsHandlePanel';
import {useSelector} from 'react-redux';
import {RootState} from 'store';
import {useEffect, useState} from 'react';
import _ from 'lodash';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {SingleBetBoxProps} from './components/SingleBetBox';
import {breakpoint} from 'utils/types';
import {Drawer} from 'antd';
import {useTranslation} from 'react-i18next';
import Banner from 'components/Banner';
import {useNavigate} from 'react-router-dom';
import OpenBetsPanel from './components/OpenBetsPanel';

export interface ActiveTabsState {
	[key: number]: boolean;
}

interface BetslipProps {
	mobileBetslipVisible?: boolean;
	setMobileBetslipOpener?: (visible: boolean) => void;
}

const Betslip = ({mobileBetslipVisible, setMobileBetslipOpener}: BetslipProps) => {
	const {t} = useTranslation();
	const navigate = useNavigate();
	const darkMode = useSelector((state: RootState) => state.darkMode);
	const [isVisible, setIsVisible] = useState(false);
	const [activeTabs, setActiveTabs] = useState<ActiveTabsState>({
		1: true,
		2: false
	});

	const screenWidth = useScreenWidth();
	const oddsData = useSelector((state: RootState) => state.createbetSlice) as {
		[K in string]: SingleBetBoxProps;
	};

	const handleActiveTabs = (id: number) => {
		let newTabs = {...activeTabs};

		if (id === 1 || id === 2) {
			_.assign(newTabs, {1: false, 2: false});
			newTabs[id] = true;
		}

		setActiveTabs(newTabs);
	};

	const handleCloseBetslip = () => {
		if (screenWidth < breakpoint.DESKTOP && setMobileBetslipOpener) {
			setMobileBetslipOpener(false);
		} else {
			setIsVisible(false);
		}
	};

	// useEffect(() => {
	// 	if (Object.keys(oddsData).length === 0) {
	// 		setIsVisible(false);
	// 	}
	// }, [oddsData]);

	useEffect(() => {
		if (mobileBetslipVisible) {
			document.getElementsByTagName('body')[0].classList.add('hide-scroll');
		} else {
			document.getElementsByTagName('body')[0].classList.remove('hide-scroll');
		}
	}, [mobileBetslipVisible]);

	useEffect(() => {
		activeTabs[2] && handleCloseBetslip();
		activeTabs[2] && navigate('/user-bets/history');
	}, [activeTabs]);

	if (screenWidth > breakpoint.DESKTOP) {
		return (
			<div className={`betslip-wrapper ${darkMode ? 'betslip-wrapper__darkmode' : ''}`}>
				<div className="betslip-sticky">
					<BetslipHeader handleActiveTabs={handleActiveTabs} activeTabs={activeTabs} />
					{activeTabs[1] && (
						<OddsHandlePanel oddsData={oddsData} setMobileBetslipOpener={setIsVisible} />
					)}
					{/* {activeTabs[2] && <OpenBetsPanel />} */}
				</div>
				<Banner />
			</div>
		);
	}
	// else if (screenWidth <= breakpoint.DESKTOP && screenWidth > breakpoint.MOBILE) {
	// 	return (
	// 		<div className="animated-betslip-wrapper">
	// 			{!isVisible && screenWidth > breakpoint.MOBILE && Object.keys(oddsData).length ? (
	// 				<div className="betslip-opener-button" onClick={() => setIsVisible(true)}>
	// 					{t('betslip')} {Object.keys(oddsData).length}
	// 				</div>
	// 			) : null}
	// 			{screenWidth <= breakpoint.DESKTOP && isVisible && screenWidth > breakpoint.MOBILE && (
	// 				<div
	// 					className={`popup-betslip-wrapper ${
	// 						isVisible ? 'popup-betslip-wrapper__visible' : 'popup-betslip-wrapper__hidden'
	// 					}`}>
	// 					{isVisible && (
	// 						<div className="betslip-sticky">
	// 							<BetslipHeader
	// 								handleActiveTabs={handleActiveTabs}
	// 								activeTabs={activeTabs}
	// 								handleCloseBetslip={handleCloseBetslip}
	// 							/>
	// 							{activeTabs[1] && (
	// 								<OddsHandlePanel
	// 									oddsData={oddsData}
	// 									setMobileBetslipOpener={setIsVisible}
	// 								/>
	// 							)}
	// 						</div>
	// 					)}
	// 				</div>
	// 			)}
	// 		</div>
	// 	);
	// }
	else if (screenWidth <= breakpoint.DESKTOP && mobileBetslipVisible) {
		return (
			<div className="animated-betslip-wrapper">
				<Drawer
					placement="bottom"
					className="betslip-drawer"
					open={screenWidth < breakpoint.DESKTOP && mobileBetslipVisible}>
					<div className={`popup-betslip-wrapper`}>
						{screenWidth < breakpoint.DESKTOP && mobileBetslipVisible && (
							<div className="betslip-sticky">
								<BetslipHeader
									handleActiveTabs={handleActiveTabs}
									activeTabs={activeTabs}
									handleCloseBetslip={handleCloseBetslip}
								/>
								{activeTabs[1] && (
									<OddsHandlePanel
										oddsData={oddsData}
										setMobileBetslipOpener={setMobileBetslipOpener}
									/>
								)}
								{/* {activeTabs[2] && (
									<OpenBetsPanel
									// oddsData={oddsData}
									// setMobileBetslipOpener={setMobileBetslipOpener}
									/>
								)} */}
							</div>
						)}
					</div>
				</Drawer>
			</div>
		);
	}
	return <></>;
};

export default Betslip;
