import React, {PropsWithChildren, useEffect} from 'react';
import './Popup.less';
import {IoMdClose} from 'react-icons/io';
import {useDispatch} from 'react-redux';
import {setOpenModalState} from 'store/slices/openModalSlice';
import MobileAuthHeader from 'components/Layout/Header/components/MobileAuthHeader';
import useScreenWidth from 'utils/hooks/useScreenWidth';

type PopupProps = {
	children: React.ReactNode;
	open: boolean;
	onClose: () => void;
	headerText: string;
	showSuccess?: boolean;
	type?: string;
};

const Popup = ({
	children,
	open,
	onClose,
	headerText,
	showSuccess,
	type = 'small'
}: PropsWithChildren<PopupProps>) => {
	const dispatch = useDispatch();
	const screenWidth = useScreenWidth();

	useEffect(() => {
		const body = document.body as HTMLElement;

		if (open) {
			body.classList.add('no-scroll');
		} else {
			body.classList.remove('no-scroll');
		}
		dispatch(setOpenModalState(open));
	}, [open]);

	const isAuthPage = window.location.pathname.includes('auth');

	return open ? (
		<div
			className={`popup-container`}
			// onClick={type === 'fullscreen' ? (isAuthPage ? () => {} : onClose) : onClose}
		>
			<div className="popup-wrapper">
				<div
					className="popup"
					onClick={e => {
						e.stopPropagation();
					}}>
					{screenWidth < 600 && (
						<MobileAuthHeader
							closeModal={
								<IoMdClose className="popup-close-button" color="#333333" onClick={onClose} />
							}
						/>
					)}
					<div className="popup-header">
						<span className="popup-header__title">{headerText}</span>
						{screenWidth > 600 && (
							<IoMdClose className="popup-close-button" color="#333333" onClick={onClose} />
						)}
					</div>
					{showSuccess && (
						<span style={{color: '#0d8f0e', paddingBottom: 10}}>
							{' '}
							Your request is send. Please wait for approval
						</span>
					)}
					{children}
				</div>
			</div>
		</div>
	) : null;
};

export default Popup;
