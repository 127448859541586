import {Collapse, Select, Typography} from 'antd';
import LoaderSimple from 'components/Loading/LoaderSimple';
import {useEffect, useRef, useState} from 'react';
import {FaAngleDown} from 'react-icons/fa';
import {useSelector} from 'react-redux';
import {useSearchParams} from 'react-router-dom';
import {RootState} from 'store';
import {useLazyGetNearbyGamesBySportIdQuery} from 'store/services/fixturesAPI';
import {swapTeams} from 'utils/helpers/homeTeamTypeValidator';
import {OddsProps} from 'utils/interfaces';
import {betCloseStatus, betType, collapseShowType} from 'utils/types';
import LiveBetGridHeader from './components/LiveBetGridHeader';
import LiveBetGridTemplate from './components/LiveBetGridTemplate';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {openIndexes} from './components/config';

function groupAndSortByLeagueName(arr: OddsProps[]) {
	const groupedData = {} as {[key: string]: OddsProps[]};

	arr.forEach(item => {
		const {leagueName} = item;

		if (!groupedData[leagueName]) {
			groupedData[leagueName] = [];
		}

		groupedData[leagueName].push(item);
	});

	const sortedKeys = Object.keys(groupedData).sort();

	const sortedAndGroupedData = {} as {[key: string]: OddsProps[]};
	sortedKeys.forEach(key => {
		sortedAndGroupedData[key] = groupedData[key];
	});

	return sortedAndGroupedData;
}

const {Panel} = Collapse;

const LiveOverview = ({
	setCollapseActiveKeys,
	collapseActiveKeys
}: {
	setCollapseActiveKeys: any;
	collapseActiveKeys: any;
}) => {
	const screenWidth = useScreenWidth();
	const [getNearbyGamesBySportId, {data, isFetching, isLoading}] = useLazyGetNearbyGamesBySportIdQuery();
	const [nearbyGamesState, setNearbyGamesState] = useState<{[key: string]: OddsProps[]}>(data);

	const [selectedBetType, setSelectedBetType] = useState<string>(betType.MONEYLINE);

	const prevNearbyGamesRef = useRef<{[key: string]: OddsProps[]}>({});

	const [defaultFields, setDefaultFields] = useState({
		draw: false,
		handicap: false
	});
	const [showCollapses, setShowCollapses] = useState<any>(collapseShowType.SHOW_ALL);

	const currentTopSport = useSelector((state: RootState) => state.topSportSlice) as {
		id: string;
		name: string;
	};

	const [searchParam] = useSearchParams();

	const liveSportId = searchParam.get('liveSportId');

	const id = !liveSportId ? currentTopSport?.id : liveSportId;

	const getDefaultFields = (nearbyGames: any) => {
		const defaultFieldsObj = {handicap: false, draw: false};
		for (let i = 0; i < nearbyGames?.payload?.data?.length; i++) {
			if (
				nearbyGames?.payload.data?.[i]?.odd?.periods?.[0].spreads?.length !== 0 &&
				nearbyGames?.payload.data?.[i]?.odd?.periods?.[0].moneyline?.draw
			) {
				defaultFieldsObj.draw = true;
				defaultFieldsObj.handicap = true;
				break;
			} else if (nearbyGames?.payload?.data?.[i]?.odd?.periods?.[0].spreads?.length !== 0) {
				defaultFieldsObj.handicap = true;
			} else if (nearbyGames?.payload?.data?.[i]?.odd?.periods?.[0].moneyline?.draw) {
				defaultFieldsObj.draw = true;
			}
		}
		setDefaultFields(defaultFieldsObj);
	};

	const fetchNearbyFixtures = async () => {
		prevNearbyGamesRef.current = nearbyGamesState;
		if (id) {
			const response = await getNearbyGamesBySportId({
				id,
				params: {page: 1, pageSize: 1000, isLive: true}
			}).unwrap();
			getDefaultFields(response);

			const sortedAndGroupedData = groupAndSortByLeagueName(
				response?.payload?.data.filter((el: any) => !el.isLinkedLeagueTop)
			);
			const sortedAndGroupedDataTop = groupAndSortByLeagueName(
				response?.payload?.data.filter((el: any) => el.isLinkedLeagueTop)
			);
			setNearbyGamesState({...sortedAndGroupedDataTop, ...sortedAndGroupedData});
		}
	};

	const handleOpenCollapses = () => {
		if (showCollapses === collapseShowType.SHOW_ALL) {
			setShowCollapses(collapseShowType.HIDE_ALL);
		} else if (showCollapses === collapseShowType.HIDE_ALL) {
			setShowCollapses(collapseShowType.SHOW_ALL);
		}
	};

	const handleCollapseChange = (activeKeys: string | string[]) => {
		if (Array.isArray(activeKeys)) {
			setCollapseActiveKeys(activeKeys);
		} else {
			setCollapseActiveKeys([activeKeys]);
		}
	};

	// basketball
	const handleBasketballhandicapCase = () => {
		if (id === '6579e876c629b8d3fb5295c8' || id === '65bd3063dbc21bd75df0031d') {
			setSelectedBetType(betType.HANDICAP);
		} else if (selectedBetType === betType.HANDICAP) {
			setSelectedBetType(betType.MONEYLINE);
		}
	};

	useEffect(() => {
		fetchNearbyFixtures();
		prevNearbyGamesRef.current = {};
		setNearbyGamesState({});
		handleBasketballhandicapCase();
	}, [id]);
	// to do live menu
	useEffect(() => {
		const intervalId = setInterval(fetchNearbyFixtures, 5000);

		return () => {
			clearInterval(intervalId);
		};
	}, [nearbyGamesState]);

	useEffect(() => {
		if (window.location.hash) {
			setShowCollapses(collapseShowType.SHOW_ALL);
		}
	}, [location]);

	useEffect(() => {
		if (showCollapses === collapseShowType.SHOW_ALL && nearbyGamesState) {
			const activeKeys = Object.keys(nearbyGamesState).map((item, index) => `live-game-${index}`);
			setCollapseActiveKeys(activeKeys);
		} else if (showCollapses === collapseShowType.HIDE_ALL) {
			setCollapseActiveKeys([]);
		}
	}, [showCollapses]);

	if (
		isLoading ||
		(nearbyGamesState && Object?.keys(nearbyGamesState)?.length === 0) ||
		nearbyGamesState === undefined
	) {
		return <LoaderSimple />;
	} else if (!Object?.keys(nearbyGamesState)?.length && isLoading === false) {
		return (
			<Typography.Title type="danger" level={3}>
				No live games available at this moment.
			</Typography.Title>
		);
	}

	return (
		<div>
			<div className="collapse-opener-switch-wrapper">
				<div className="in-play-hint">
					<div className="in-play-hint__red-dot"></div>
					<div className="in-play-hint__text-hint">IN-PLAY</div>
				</div>
				<div style={{display: 'flex', alignItems: 'center'}}>
					{screenWidth <= 1200 && (
						<Select
							className={`ant-select-selection-item header-down-common-text`}
							defaultValue={selectedBetType}
							onChange={(value: string) => {
								setSelectedBetType(value);
							}}
							bordered={false}
							options={[
								{value: betType.MONEYLINE, label: 'Money line Match'},
								{value: betType.HANDICAP, label: 'Handicap Match'},
								{value: betType.TOTAL, label: 'Match Total'}
							]}
							suffixIcon={null}
						/>
					)}
					<div className="collapse-opener-text" onClick={handleOpenCollapses}>
						{showCollapses === collapseShowType.SHOW_ALL ? 'Hide All' : 'Show All'}
					</div>
				</div>
			</div>
			<Collapse
				bordered={false}
				onChange={handleCollapseChange}
				expandIconPosition="end"
				className="ant-collapse-live-overview"
				defaultActiveKey={collapseActiveKeys}
				activeKey={collapseActiveKeys}
				expandIcon={({isActive}) => (
					<FaAngleDown
						size={17}
						style={{transform: `rotate(${isActive ? 180 : 0}deg)`, transition: '0.2s'}}
						rotate={isActive ? 180 : 0}
					/>
				)}>
				{nearbyGamesState &&
					Object?.entries(nearbyGamesState)?.map(([key, value], index) => {
						return (
							<Panel
								header={<span className="live-overview-panel-header">{key}</span>}
								key={`live-game-${index}`}
								className="inbox-panel-header">
								<table>
									<LiveBetGridHeader
										defaultFields={defaultFields}
										currentSelectedBetType={selectedBetType}
									/>
									{value?.map((value: OddsProps) => {
										const validatedByHomeTeamType = swapTeams(value);
										const prevGame = prevNearbyGamesRef.current?.[key]?.find(
											item => item?.eventId === value?.eventId
										);
										const validatedPrevFixture = swapTeams(
											prevGame as unknown as OddsProps
										);

										const matchData = {
											eachPeriodScore: validatedByHomeTeamType?.odd?.periods?.map(
												value => {
													if (value?.number !== 0) {
														return {
															club1: value?.homeScore,
															club2: value?.awayScore,
															periodNumber: value?.number
														};
													}
												}
											),
											finalResult: {
												club1: validatedByHomeTeamType?.odd?.periods[0].homeScore,
												club2: validatedByHomeTeamType?.odd?.periods[0].awayScore
											},
											redCards: {
												club1: validatedByHomeTeamType?.odd?.periods[0].homeRedCards,
												club2: validatedByHomeTeamType?.odd?.periods[0].awayRedCards
											}
										} as {
											eachPeriodScore: {
												club1: number | null;
												club2: number | null;
												periodNumber: number;
											}[];
											finalResult: {club1: number | null; club2: number | null};
										};

										const currentDate = new Date();
										const cutoffDate = new Date(
											validatedByHomeTeamType?.odd?.periods?.[0]?.cutoff
										);
										const isBetOpen =
											currentDate < cutoffDate &&
											validatedByHomeTeamType?.odd?.periods?.[0]?.status !==
												betCloseStatus;
										const isSuspended = currentDate > cutoffDate;
										const isBetClosedByStatus =
											validatedByHomeTeamType?.odd?.periods?.[0]?.status ===
											betCloseStatus;
										return (
											<LiveBetGridTemplate
												liveStatus={value.liveStatus}
												isBetOpen={isBetOpen}
												isSuspended={isSuspended}
												isBetClosedByStatus={isBetClosedByStatus}
												parentId={value?.parentId}
												sportName={value?.sportName}
												minutes={value?.elapsedMinutes}
												eventState={value?.eventState}
												periodNumber={
													validatedByHomeTeamType?.odd?.periods?.[0]?.number
												}
												leagueId={validatedByHomeTeamType?.leagueId}
												betID={validatedByHomeTeamType?.id}
												currentSelectedBetType={selectedBetType}
												key={
													validatedByHomeTeamType?.id +
													8 +
													validatedByHomeTeamType?.leagueId
												}
												matchData={matchData}
												club1={validatedByHomeTeamType?.home}
												club2={validatedByHomeTeamType?.away}
												coefficents={
													validatedByHomeTeamType?.odd &&
													// validatedByHomeTeamType?.odd?.periods?.[0].number === 0
													//  &&
													isBetOpen
														? {
																firstWinCF:
																	validatedByHomeTeamType?.odd?.periods?.[0]
																		?.moneyline?.home,
																secondWinCF:
																	validatedByHomeTeamType?.odd?.periods?.[0]
																		?.moneyline?.away,
																drawCF: validatedByHomeTeamType?.odd
																	?.periods?.[0]?.moneyline?.draw,
																handicap1CF:
																	validatedByHomeTeamType?.odd?.periods?.[0]?.spreads.find(
																		i => i?.altLineId === undefined
																	)?.home ?? 0,
																handicap2CF:
																	validatedByHomeTeamType?.odd?.periods?.[0]?.spreads.find(
																		i => i?.altLineId === undefined
																	)?.away ?? 0,
																overCF:
																	validatedByHomeTeamType?.odd.periods?.[0]?.totals.find(
																		i => i?.altLineId === undefined
																	)?.over ?? 0,
																underCF:
																	validatedByHomeTeamType?.odd.periods?.[0]?.totals.find(
																		i => i?.altLineId === undefined
																	)?.under ?? 0,
																handicapDiff:
																	validatedByHomeTeamType?.odd.periods?.[0]?.spreads.find(
																		i => i?.altLineId === undefined
																	)?.hdp ?? 0,
																overDiff:
																	validatedByHomeTeamType?.odd.periods?.[0]?.totals.find(
																		i => i?.altLineId === undefined
																	)?.points ?? 0
														  }
														: {
																firstWinCF: -1,
																secondWinCF: -1,
																drawCF: -1,
																handicap1CF: -1,
																handicap2CF: -1,
																overCF: -1,
																underCF: -1,
																handicapDiff: -1,
																overDiff: -1
														  }
												}
												prevCoefficents={
													validatedPrevFixture?.odd
														? {
																firstWinCF:
																	validatedPrevFixture?.odd?.periods?.[0]
																		?.moneyline?.home,
																secondWinCF:
																	validatedPrevFixture?.odd?.periods?.[0]
																		?.moneyline?.away,
																drawCF: validatedPrevFixture?.odd
																	?.periods?.[0]?.moneyline?.draw,
																handicap1CF:
																	validatedPrevFixture?.odd?.periods?.[0]?.spreads.find(
																		i => i?.altLineId === undefined
																	)?.home ?? 0,
																handicap2CF:
																	validatedPrevFixture?.odd?.periods?.[0]?.spreads.find(
																		i => i?.altLineId === undefined
																	)?.away ?? 0,
																overCF:
																	validatedPrevFixture?.odd.periods?.[0]?.totals.find(
																		i => i?.altLineId === undefined
																	)?.over ?? 0,
																underCF:
																	validatedPrevFixture?.odd.periods?.[0]?.totals.find(
																		i => i?.altLineId === undefined
																	)?.under ?? 0,
																handicapDiff:
																	validatedPrevFixture?.odd.periods?.[0]?.spreads.find(
																		i => i?.altLineId === undefined
																	)?.hdp ?? 0,
																overDiff:
																	validatedPrevFixture?.odd.periods?.[0]?.totals.find(
																		i => i?.altLineId === undefined
																	)?.points ?? 0
														  }
														: {
																firstWinCF: -1,
																secondWinCF: -1,
																drawCF: -1,
																handicap1CF: -1,
																handicap2CF: -1,
																overCF: -1,
																underCF: -1,
																handicapDiff: -1,
																overDiff: -1
														  }
												}
												eventId={validatedByHomeTeamType?.eventId}
												id={validatedByHomeTeamType?.id}
												startsAt={new Date(
													validatedByHomeTeamType?.starts
												).toLocaleString('en-US', {
													month: 'long',
													day: '2-digit',
													hour: 'numeric',
													minute: 'numeric',
													hour12: true
												})}
												nearbyGames
												leagueName={validatedByHomeTeamType?.leagueName}
												marketsCount={
													validatedByHomeTeamType?.marketsCount +
													validatedByHomeTeamType?.specialMarketsCount
												}
												maxBets={{
													maxMoneyline:
														validatedByHomeTeamType?.odd?.periods?.[0]
															?.maxMoneyline,
													maxSpread:
														validatedByHomeTeamType?.odd?.periods?.[0]?.spreads?.find(
															i => i?.altLineId === undefined
														)?.max ||
														validatedByHomeTeamType?.odd?.periods?.[0]?.maxSpread, // if in object there is not a max, take from parent object
													maxTotal:
														validatedByHomeTeamType?.odd?.periods?.[0]?.maxTotal
												}}
												defaultFields={defaultFields}
												homeTeamType={validatedByHomeTeamType?.homeTeamType}
												startsAtNotFormatted={validatedByHomeTeamType?.starts}
											/>
										);
									})}{' '}
								</table>
							</Panel>
						);
					})}
			</Collapse>
		</div>
	);
};

export default LiveOverview;
