import {oddTypes} from 'utils/types';
import {homeTeamType as homeTeamTypes} from 'utils/types';

export const oddConverter = (coefficent: number, type: string) => {
	switch (type) {
		case oddTypes.DECIMAL:
			return coefficent;
		case oddTypes.AMERICAN_ODD:
			if (coefficent >= 2) {
				return Math.round((coefficent - 1) * 100);
			} else {
				return Math.round(-100 / (coefficent - 1));
			}
		default:
			return 0;
	}
};

export const oddConverterToShow = (coefficent: number, type: string) => {
	switch (type) {
		case oddTypes.DECIMAL:
			return coefficent?.toFixed(3).padEnd(5, '0');
		case oddTypes.AMERICAN_ODD:
			if (coefficent >= 2) {
				return `+${Math.round((coefficent - 1) * 100)}`;
			} else {
				return `${Math.round(-100 / (coefficent - 1))}`;
			}
		default:
			return '0';
	}
};

export const getSinglePayoutByOddType = (coefficent: number, value: number | null, oddType: string) => {
	switch (oddType) {
		case oddTypes.DECIMAL:
			return value ? +(value * coefficent).toFixed(2) : null;
		case oddTypes.AMERICAN_ODD:
			if (coefficent >= 0 && value) {
				return +(coefficent * (value / 100) + value).toFixed(2);
			} else if (coefficent < 0 && value) {
				return +((100 / Math.abs(coefficent)) * value + value).toFixed(2);
			}
			return 0;
		default:
			return 0;
	}
};

export const handleHandicapIdicator = (
	handicap: number | undefined,
	club: string,
	homeTeamType: string | undefined,
	isHomeOwner?: boolean
) => {
	if (handicap !== undefined && homeTeamType === homeTeamTypes.TEAM1 && isHomeOwner) {
		if (handicap > 0) {
			return `${club} +${handicap}`;
		}
		return `${club} ${handicap}`;
	} else if (handicap !== undefined && homeTeamType === homeTeamTypes.TEAM1) {
		handicap = -handicap;
		if (handicap > 0) {
			return `${club} +${handicap}`;
		}
		return `${club} ${handicap}`;
	} else if (handicap !== undefined && homeTeamType === homeTeamTypes.TEAM2 && isHomeOwner) {
		handicap = -handicap;
		if (handicap > 0) {
			return `${club} +${handicap}`;
		}
		return `${club} ${handicap}`;
	} else if (handicap !== undefined && homeTeamType === homeTeamTypes.TEAM2) {
		if (handicap > 0) {
			return `${club} +${handicap}`;
		}
		return `${club} ${handicap}`;
	}
	return '0';
};

export const getHandicapValue = (
	handicap: number | undefined,
	club: string,
	homeTeamType: string | undefined,
	isHomeOwner?: boolean
) => {
	return parseFloat(handleHandicapIdicator(handicap, '', homeTeamType, isHomeOwner).split(' ')[1]);
};
