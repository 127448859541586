import {configureStore} from '@reduxjs/toolkit';
import {setupListeners} from '@reduxjs/toolkit/query/react';
import {authApi} from './services/authAPI';
import {createBetApi} from './services/createBetApi';
import {fixturesApi} from './services/fixturesAPI';
import {sportsApi} from './services/sportsAPI';
import allLeaguesGetterSlice from './slices/allLeaguesGetterSlice';
import userAuthReducer from './slices/authSlice';
import createBetSlice from './slices/createBetSlice';
import currentSelectedSportSlice from './slices/currentSelectedSportSlice';
import darkModeReducer from './slices/darkModeSlice';
import fixturesReducer from './slices/fixturesSlice';
import menuSlice from './slices/menuSlice';
import oddsTypeSlice from './slices/oddsTypeSlice';
import openModalSlice from './slices/openModalSlice';
import topSportSlice from './slices/topSportSlice';
import showBalanceSlice from './slices/showBalanceSlice';
import {transactionsApi} from './services/transactionsAPI';
import {withdrawApi} from './services/withdrawApi';
import {depositApi} from './services/depositApi';

export const store = configureStore({
	reducer: {
		[sportsApi.reducerPath]: sportsApi.reducer,
		[authApi.reducerPath]: authApi.reducer,
		[fixturesApi.reducerPath]: fixturesApi.reducer,
		[createBetApi.reducerPath]: createBetApi.reducer,
		[transactionsApi.reducerPath]: transactionsApi.reducer,
		[withdrawApi.reducerPath]: withdrawApi.reducer,
		[depositApi.reducerPath]: depositApi.reducer,
		darkMode: darkModeReducer,
		userAuth: userAuthReducer,
		fixturesReducer: fixturesReducer,
		currentSelectedSportSlice: currentSelectedSportSlice,
		allLeagues: allLeaguesGetterSlice,
		createbetSlice: createBetSlice,
		menuSlice: menuSlice,
		topSportSlice: topSportSlice,
		oddsTypeSlice: oddsTypeSlice,
		openModalSlice: openModalSlice,
		showBalanceSlice: showBalanceSlice
	},
	middleware: getDefaultMiddleware =>
		getDefaultMiddleware().concat([
			sportsApi.middleware,
			authApi.middleware,
			fixturesApi.middleware,
			createBetApi.middleware,
			transactionsApi.middleware,
			withdrawApi.middleware,
			depositApi.middleware
		])
});

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
