import {useSearchParams} from 'react-router-dom';
import {OddsDataProps} from '../SingleGameBets';
import './../SingleGameBets.less';
import './TimeTable.less';
import MatchCount from 'views/LiveOverview/components/MatchCount';
import {Col, Divider, Row} from 'antd';
import LiveIcon from 'views/LiveOverview/components/LiveIcon';
import live_statistics from 'assets/icons/TimeTable/live_statistics.svg';
import live_game_stats from 'assets/icons/TimeTable/live_game_stats.svg';
import game_info from 'assets/icons/TimeTable/game_info.svg';
import yellow_card from 'assets/icons/TimeTable/yellow_card.svg';
import red_card from 'assets/icons/TimeTable/red_card.svg';
import angle from 'assets/icons/TimeTable/angle.svg';
import {useState} from 'react';
import useScreenWidth from 'utils/hooks/useScreenWidth';

interface TimeTableProps {
	oddsData: OddsDataProps;
}

function convertDate(dateString: string, row: string): string {
	const date = new Date(dateString);
	const month = date.toLocaleString('default', {month: 'long'});
	const day = date.toLocaleString('default', {day: '2-digit'});
	const time = date.toLocaleString('en-US', {hour: 'numeric', minute: '2-digit', hour12: true});

	return row === '1' ? `${month} ${day}` : `${time}`;
}

export const modifiedEventState = (type: number, screenWidth: any) => {
	let resultText = '';
	if (type === 1) {
		resultText = '1H';
		return resultText;
	} else if (type === 2) {
		resultText = 'HT';
		return resultText;
	} else if (type === 3) {
		resultText = '2H';
		return resultText;
	} else if (type === 4) {
		resultText = 'END';
		return resultText;
	} else if (type === 5) {
		resultText = 'ET 1H';
		return resultText;
	} else if (type === 6) {
		resultText = 'ET HT';
		return resultText;
	} else if (type === 7) {
		resultText = 'ET 2H';
		return resultText;
	} else if (type === 8) {
		resultText = 'ET END';
		return resultText;
	} else if (type === 9) {
		if (screenWidth < 450) {
			resultText = 'FT';
		} else {
			resultText = 'FULL TIME';
		}
		return resultText;
	} else if (type === 10) {
		resultText = 'SUSPENDED';
		return resultText;
	} else if (type === 11) {
		resultText = 'PENALTIES';
		return resultText;
	}
	return resultText;
};
const TimeTable = ({oddsData}: TimeTableProps) => {
	const screenWidth = useScreenWidth();
	const [searchParam] = useSearchParams();
	const isLiveOverview = searchParam.get('isLive');
	const [active, setActive] = useState({
		gameStatistics: false,
		gameAction: false
	});

	const finalResult = {
		club1: oddsData?.payload?.odd?.periods[0]?.homeScore,
		club2: oddsData?.payload?.odd?.periods[0]?.awayScore
	};
	const redCards = {
		club1: oddsData?.payload?.odd?.periods[0]?.homeRedCards,
		club2: oddsData?.payload?.odd?.periods[0]?.awayRedCards
	};

	const header = [
		{
			title: (
				<div className="live-game-hint">
					<LiveIcon />
					{oddsData?.payload?.eventState && (
						<span className="live-game-hint__date">
							{oddsData?.payload?.eventState &&
								modifiedEventState(oddsData?.payload?.eventState, screenWidth)}
							<span className="live-game-hint__date_red">
								{oddsData?.payload?.elapsedMinutes && `${oddsData?.payload?.elapsedMinutes}'`}
							</span>
						</span>
					)}
				</div>
			),
			key: '',
			style: {flex: 3}
		},
		{
			title: <img src={angle} />,
			key: ''
		},
		{
			title: <img src={yellow_card} />,
			key: ''
		},
		{
			title: <img src={red_card} />,
			key: ''
		},
		{
			title: '2',
			key: ''
		},
		{
			title: '1',
			key: ''
		}
	];

	return (
		<div className="live-statistics-table-wrapper">
			<div className="time-table-wrapper" style={{justifyContent: 'space-between'}}>
				{oddsData?.payload.sportName == 'Soccer' && isLiveOverview ? (
					<>
						<div style={{width: '100%'}}>
							<Row className="time-tabel-header" gutter={[16, 16]}>
								{header.map((i, index) => (
									<Col {...(i.style ? i.style : {span: 1})} key={index}>
										{i?.title}
									</Col>
								))}
							</Row>
							<Divider type="horizontal" />
							<div style={{display: 'flex', flexDirection: 'row'}}>
								<div className="live-game-data">
									<Row gutter={[16, 16]} style={{width: '100%'}}>
										<Col flex={3}>{oddsData?.payload?.home}</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
									</Row>
									<Row gutter={[16, 16]} style={{width: '100%'}}>
										<Col flex={3}>{oddsData?.payload?.away}</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
										<Col span={1}>0</Col>
									</Row>
								</div>
								<div style={{width: '5%', display: 'flex', flexDirection: 'row'}}>
									<Divider
										type="vertical"
										style={{height: 40, width: 1, marginRight: 15}}
									/>
									<div className="final-score">
										<Row gutter={[8, 8]}>{finalResult.club1}</Row>
										<Row gutter={[8, 8]}>{finalResult.club2}</Row>
									</div>
								</div>
							</div>
						</div>
						{/* <div>
							<Divider type="vertical" style={{height: 40, width: 1}} />
						</div> */}
					</>
				) : (
					<>
						<div className="participants-wrapper">
							<div className="participants-name-container">
								<span className="participants-wrapper__name">
									{oddsData?.payload?.home.replace('(Sets)', '').toUpperCase()}
									{!!redCards?.club1 && (
										<span className="single-bet-sport-info__card">{redCards?.club1}</span>
									)}
								</span>
							</div>
							<div className="participants-name-container">
								<span className="participants-wrapper__name">
									{oddsData?.payload?.away.replace('(Sets)', '').toUpperCase()}
									{!!redCards?.club2 && (
										<span className="single-bet-sport-info__card">{redCards?.club2}</span>
									)}
								</span>
							</div>
						</div>
						{!isLiveOverview ? (
							<div className="event-date-wrapper">
								<span className="event-date-time">
									{convertDate(oddsData?.payload?.starts, '1')}
								</span>
								<span className="event-date-time">
									{convertDate(oddsData?.payload?.starts, '2')}
								</span>
							</div>
						) : (
							<div className="live-match-wrapper">
								<div className="live-match-counter-wrapper">
									<MatchCount finalResult={finalResult} />
									<div className="live-game-hint">
										<LiveIcon />
										{oddsData?.payload?.eventState && (
											<span className="live-game-hint__date">
												{oddsData?.payload?.eventState &&
													modifiedEventState(
														oddsData?.payload?.eventState,
														screenWidth
													)}
												-
												<span className="live-game-hint__date_red">
													{oddsData?.payload?.elapsedMinutes &&
														`${oddsData?.payload?.elapsedMinutes}'`}
												</span>
											</span>
										)}
									</div>
								</div>
							</div>
						)}
					</>
				)}
			</div>
			<Divider type="horizontal" />
			<div className="time-table-menu">
				<span className="time-table-menu__video">
					<span className="time-table-menu__video_i">i &nbsp;</span> Video review
				</span>
				<div className="time-table-menu__buttons">
					<div
						onClick={() => setActive(prev => ({...prev, gameStatistics: !prev.gameStatistics}))}
						className={`time-table-menu__btn ${
							active.gameStatistics && 'time-table-menu__active'
						}`}>
						<img src={game_info} />
					</div>
					<div
						onClick={() => setActive(prev => ({...prev, gameAction: !prev.gameAction}))}
						className={`time-table-menu__btn ${active.gameAction && 'time-table-menu__active'}`}>
						<img src={live_statistics} />
					</div>
					<div className="time-table-menu__btn">
						<img src={live_game_stats} />
					</div>
				</div>
			</div>
			{active.gameStatistics && (
				<>
					{' '}
					<Divider type="horizontal" />
					<div className="game-statistics">
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
						<div className="game-statistics__card">
							<span>21</span>
							<span>xG</span>
							<span>32</span>
						</div>
					</div>
				</>
			)}
			{active.gameAction && (
				<>
					<Divider type="horizontal" />
					<div className="game-action">
						<div className="game-action__card">
							<div className="game-action__card__team flex-end">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
							<div className="game-action__card__action-time">42</div>
							<div className="game-action__card__team">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
						</div>
						<div className="game-action__card">
							<div className="game-action__card__team flex-end">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
							<div className="game-action__card__action-time">42</div>
							<div className="game-action__card__team">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
						</div>
						<div className="game-action__card">
							<div className="game-action__card__team flex-end">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
							<div className="game-action__card__action-time">42</div>
							<div className="game-action__card__team">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
						</div>
						<div className="game-action__card">
							<div className="game-action__card__team flex-end">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
							<div className="game-action__card__action-time">42</div>
							<div className="game-action__card__team">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
						</div>
						<div className="game-action__card">
							<div className="game-action__card__team flex-end">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
							<div className="game-action__card__action-time">42</div>
							<div className="game-action__card__team">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
						</div>
						<div className="game-action__card">
							<div className="game-action__card__team flex-end">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
							<div className="game-action__card__action-time">42</div>
							<div className="game-action__card__team">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
						</div>
						<div className="game-action__card">
							<div className="game-action__card__team flex-end">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
							<div className="game-action__card__action-time">42</div>
							<div className="game-action__card__team">
								<span>Player Name</span>
								{/* <img /> */}
							</div>
						</div>
					</div>
				</>
			)}
		</div>
	);
};

export default TimeTable;
