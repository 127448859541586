import {PropsWithChildren, useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {useLocation} from 'react-router-dom';
import {RootState} from 'store';
import {useGetAllSportsQuery} from 'store/services/sportsAPI';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {CONDITIONS_PAGE_PATHNAME, breakpoint} from 'utils/types';
import LiveOverview from 'views/LiveOverview/LiveOverview';
import NearbyGames from 'views/NearbyGames/NearbyGames';
import Betslip from './Betslip';
import {SingleBetBoxProps} from './Betslip/components/SingleBetBox';
import EventSwiper from './EventSwiper';
import Footer from './Footer';
import './Layout.less';
import SiderMenu from './Sider';
import {IoIosArrowDropupCircle} from 'react-icons/io';
import {openIndexes} from 'views/LiveOverview/components/config';

const Layout = ({children}: PropsWithChildren) => {
	const {data: sportNames} = useGetAllSportsQuery({});
	const location = useLocation();
	const screenWidth = useScreenWidth();
	const [collapseActiveKeys, setCollapseActiveKeys] = useState<string | string[]>(openIndexes);

	const isTermsAndConditionsPage = localStorage.getItem('termsAndConditions') === 'true';
	const bettingLocation =
		location.pathname.includes('betting') ||
		location.pathname === '/' ||
		location.pathname.includes('auth');

	const [mobileBetslipVisible, setMobileBetslipVisible] = useState(false);

	const [showButton, setShowButton] = useState(false);

	const isLiveOverview = location.pathname.includes('live-overview');

	const oddsData = useSelector((state: RootState) => state.createbetSlice) as {
		[K in string]: SingleBetBoxProps;
	};

	const [isVisible, setIsVisible] = useState(
		(bettingLocation && location.pathname.includes('nearby')) ||
			location.pathname.includes('auth') ||
			location.pathname === '/' ||
			isLiveOverview
	);

	const handleScrollToTop = () => {
		window.scrollTo({top: 0, behavior: 'smooth'});
	};

	const isInRulesPages = location.pathname.includes(CONDITIONS_PAGE_PATHNAME);

	useEffect(() => {
		window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
		setIsVisible(
			(bettingLocation && location.pathname.includes('nearby')) ||
				location.pathname === '/' ||
				location.pathname.includes('auth') ||
				isLiveOverview
		);
	}, [location.pathname]);

	// useEffect(() => {
	// 	Object.keys(oddsData).length === 0 && setMobileBetslipVisible(false);
	// }, [oddsData]);

	useEffect(() => {
		const body = document.body;

		if (mobileBetslipVisible) {
			body.classList.add('no-scroll');
		} else {
			body.classList.remove('no-scroll');
		}
	}, [mobileBetslipVisible]);

	useEffect(() => {
		function handleScroll() {
			const scrollY = window.scrollY;
			setShowButton(scrollY >= 1500);
		}

		let ticking = false;

		function scrollHandler() {
			if (!ticking) {
				requestAnimationFrame(() => {
					handleScroll();
					ticking = false;
				});
				ticking = true;
			}
		}

		window.addEventListener('scroll', scrollHandler);

		return () => {
			window.removeEventListener('scroll', scrollHandler);
		};
	}, []);

	return bettingLocation ? (
		<>
			{screenWidth < breakpoint.DESKTOP && (
				<div
					className={`mobile-betslip-switch ${
						!!Object.keys(oddsData).length && !isTermsAndConditionsPage
							? 'mobile-betslip-switch--active'
							: 'mobile-betslip-switch-condition'
					}`}>
					<span
						className="mobile-betslip-switch__bet-counter"
						onClick={() => Object.keys(oddsData).length && setMobileBetslipVisible(true)}>
						{Object.keys(oddsData).length}
					</span>
				</div>
			)}
			{screenWidth < breakpoint.DESKTOP && (
				<Betslip
					mobileBetslipVisible={mobileBetslipVisible}
					setMobileBetslipOpener={setMobileBetslipVisible}
				/>
			)}
			<div className="layout-wrapper">
				<div className="main-action-wrapper">
					{!(isLiveOverview && screenWidth > breakpoint.DESKTOP) && <SiderMenu />}
					<div
						className={`main-action-wrapper__betting ${
							isLiveOverview ? 'main-action-wrapper__betting_live-overview' : ''
						}`}>
						<div>
							<EventSwiper
								setCollapseActiveKeys={setCollapseActiveKeys}
								payload={sportNames?.payload}
								isVisible={isVisible}
							/>
							{location.pathname === '/' || location.pathname.includes('auth') ? (
								<NearbyGames defaultShow />
							) : location.pathname === '/bettings/live-overview' ? (
								<LiveOverview
									setCollapseActiveKeys={setCollapseActiveKeys}
									collapseActiveKeys={collapseActiveKeys}
								/>
							) : (
								children
							)}
						</div>
						<Footer />
					</div>
					<div style={{display: 'flex', flexDirection: 'column'}}>
						<Betslip />
					</div>
				</div>
			</div>
			{showButton && (
				<div className="scroll-up-btn-wrapper" onClick={handleScrollToTop}>
					<IoIosArrowDropupCircle color="#0079ff" size={35} />
				</div>
			)}
		</>
	) : (
		<div className={`layout-wrapper ${isInRulesPages ? 'empty-layout-wrapper__terms-page' : ''}`}>
			<div className="main-action-wrapper">
				{children}
				{screenWidth < breakpoint.DESKTOP && (
					<div
						className={`mobile-betslip-switch ${
							!!Object.keys(oddsData).length && !isTermsAndConditionsPage
								? 'mobile-betslip-switch--active'
								: 'mobile-betslip-switch-condition'
						}`}>
						<span
							className="mobile-betslip-switch__bet-counter"
							onClick={() => Object.keys(oddsData).length && setMobileBetslipVisible(true)}>
							{Object.keys(oddsData).length}
						</span>
						<div className="mobile-betslip-switch__colored"></div>
					</div>
				)}
				{screenWidth < breakpoint.DESKTOP && (
					<Betslip
						mobileBetslipVisible={mobileBetslipVisible}
						setMobileBetslipOpener={setMobileBetslipVisible}
					/>
				)}
			</div>
			{showButton && (
				<div className="scroll-up-btn-wrapper" onClick={handleScrollToTop}>
					<IoIosArrowDropupCircle color="#0079ff" size={35} />
				</div>
			)}
		</div>
	);
};

export default Layout;
