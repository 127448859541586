import {createSlice} from '@reduxjs/toolkit';

const showBalance = createSlice({
	name: 'showBalance',
	initialState: {showBalance: true},
	reducers: {
		setBalanceShow(state, action) {
			return {...state, showBalance: action.payload};
		}
	}
});

export const {setBalanceShow} = showBalance.actions;
export default showBalance.reducer;
