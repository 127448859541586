import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const withdrawApi = createApi({
	reducerPath: 'withdraw',
	baseQuery: baseQuery(`/api/v1`),
	endpoints: builder => ({
		requestWithdraw: builder.mutation({
			query: ({body}) => {
				return {
					url: `payments/withdraw/request`,
					method: 'POST',
					body
				};
			}
		})
	})
});

export const {useRequestWithdrawMutation} = withdrawApi;
