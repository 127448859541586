import {Drawer, Menu as HamburgerMenu} from 'antd';
import {useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useLocation, useNavigate, useSearchParams} from 'react-router-dom';
import {RootState} from 'store';
import {useGetAllSportsQuery, useLazyGetLeaguesQuery} from 'store/services/sportsAPI';
import {getAllLeagues} from 'store/slices/allLeaguesGetterSlice';
import {setCurrentActiveMenuType} from 'store/slices/menuSlice';
import SVGComponent from 'utils/helpers/SVGComponent';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {breakpoint, menuTypes} from 'utils/types';
import './../Sider.less';
import {MenuOutlined} from '@ant-design/icons';
import LiveMenu from './LiveMenu';
export interface League {
	allowRoundRobins: boolean;
	container: string;
	createdAt: string;
	eventCount: number;
	eventSpecialsCount: number;
	hasOfferings: boolean;
	homeTeamType: string;
	id: string;
	leagueSpecialsCount: number;
	name: string;
	refId: number;
	sport: object;
	updatedAt: string;
	isTop: boolean;
	listOrder: number;
}

export interface LeaguesBySport {
	[sportName: string]: LeagueProps[];
}

interface SiderMenuProps {
	isTop: boolean;
	handleMenuActivities: (isTop: boolean) => void;
	isActive: boolean;
}

export interface LeagueProps {
	id: string;
	name: string;
}

interface CustomLabelProps {
	name: string;
	className?: string;
	onClick?: (id: string, name: string, keyId: string) => void;
	id: string;
	activeMenuItem?: string;
	setActiveSportName: (id: string) => void;
	isActive: boolean;
	keyId: string;
	eventCount: number;
}

const CustomLabel = ({
	name,
	className,
	onClick = () => {},
	id,
	setActiveSportName,
	isActive,
	keyId,
	eventCount
}: CustomLabelProps) => {
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	return (
		<div
			style={{borderBottom: isMobile && !isActive ? '1px solid var(--live-menu-border-color)' : 'none'}}
			className={`custom-label custom-label__${className} ${isActive ? 'custom-label__active' : ''}`}
			onClick={() => {
				onClick(id, name, keyId);
				setActiveSportName(keyId);
			}}>
			<span className={`custom-label__sport-name`}>
				{name?.charAt(0).toUpperCase() + name?.slice(1)}
			</span>
			<div className={`custom-label__sport-count`}>{eventCount}</div>
		</div>
	);
};

const Menu = ({setIsOpen}: {setIsOpen?: any}) => {
	const {data: sportNames} = useGetAllSportsQuery({});
	const [getLeaguesById, {data, isLoading}] = useLazyGetLeaguesQuery();
	const location = useLocation();
	let [searchParams] = useSearchParams();

	const [activeMenuItem, setActiveMenuItem] = useState<string>('');
	const [menuTabs, setMenuTabs] = useState<LeaguesBySport>({});
	const [openKeys, setOpenKeys] = useState<string>();

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	const hashRef = useRef<{[K in string]: LeaguesBySport}>({});

	const {isOpen: openModalState} = useSelector((state: RootState) => state.openModalSlice);

	const topMenuData = sportNames?.payload
		.filter((value: League) => value.isTop === true)
		.sort(function (a: League, b: League) {
			return a.listOrder - b.listOrder; // ascending order
		});
	const currentOpenMenuType = useSelector((state: RootState) => state.menuSlice);

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const handleCurrentSportLeagues = async (id: string, name: string, keyId: string) => {
		let data: LeaguesBySport = {};
		setOpenKeys(keyId);
		dispatch(setCurrentActiveMenuType(menuTypes.SIDER_MENU));

		navigate(`bettings/leagues/${id}`);

		if (hashRef.current[name]) {
			data = hashRef.current[name];
			setMenuTabs((prev: any) => ({
				...prev,
				[name]: data
			}));
			return;
		} else {
			const {payload: leagues} = await getLeaguesById({id}).unwrap();
			const transformedArray = leagues.map((obj: League) => ({
				id: obj?.id,
				name: obj?.name,
				eventCount: obj?.eventCount
			}));
			hashRef.current[name] = transformedArray;

			dispatch(getAllLeagues({...hashRef.current}));
		}
	};

	useEffect(() => {
		if (
			currentOpenMenuType === menuTypes.SWIPER_MENU ||
			currentOpenMenuType === menuTypes.NON_SELECTED ||
			location.pathname === '/'
		) {
			setActiveMenuItem('');
		}
	}, [currentOpenMenuType, location]);

	return screenWidth > breakpoint.DESKTOP ? (
		searchParams.get('isLive') === 'true' ? (
			<LiveMenu />
		) : (
			<div className="all-menus-wrapper">
				<div className={`sports-title`}>TOP SPORTS</div>
				<div className="menu-wrapper top-menu-wrapper">
					{topMenuData?.map((value: any, index: number) => (
						<div
							className={`menu-item ${
								activeMenuItem === `menu-item-${value?.name}`
									? !isMobile && 'menu-item__active'
									: ''
							}`}
							key={'top-menu-item' + index}>
							<div className="icon-name-wrapper">
								<SVGComponent
									path={value?.image}
									width={isMobile ? 20 : 16}
									height={isMobile ? 20 : 16}
									key={value?.id + 'svg-component'}
								/>
								<CustomLabel
									id={value.id}
									onClick={
										openKeys === `sub${index}` ? () => {} : handleCurrentSportLeagues
									}
									className="main"
									name={value.name}
									key={value?.id + 'label'}
									isActive={activeMenuItem === `menu-item-${value?.name}`}
									keyId={`menu-item-${value?.name}`}
									setActiveSportName={setActiveMenuItem}
									eventCount={
										value?.eventCount !== 0
											? value?.eventCount
											: value?.leagueSpecialsCount
									}
								/>
							</div>
						</div>
					))}
				</div>
				<div className={`sports-title`}>ALL SPORTS</div>
				<div className=" menu-wrapper ordinary-menu-wrapper">
					{sportNames?.payload?.map((value: any, index: number) => (
						<div
							className={`menu-item ${
								activeMenuItem === `menu-item-${value?.name}`
									? !isMobile && 'menu-item__active'
									: ''
							}`}
							key={'ordinary-menu-item' + index}>
							<div className="icon-name-wrapper">
								<SVGComponent
									path={value?.image}
									width={isMobile ? 20 : 16}
									height={isMobile ? 20 : 16}
									key={value?.id + 'svg-component'}
								/>
								<CustomLabel
									id={value.id}
									onClick={
										openKeys === `sub${index}` ? () => {} : handleCurrentSportLeagues
									}
									className="main"
									name={value.name}
									key={value?.id + 'label'}
									isActive={activeMenuItem === `menu-item-${value?.name}`}
									keyId={`menu-item-${value?.name}`}
									setActiveSportName={setActiveMenuItem}
									eventCount={
										value?.eventCount !== 0
											? value?.eventCount
											: value?.leagueSpecialsCount
									}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		)
	) : (
		<div style={{height: '100vh'}}>
			{/* <Drawer
				open={isOpen}
				style={{backgroundColor: '#151515'}}
				onClose={() => setIsOpen(false)}
				placement="left"
				maskClassName="menu-drawer-mask"
				rootClassName="menu-drawer-root"
				className="hamburger-menu-wrapper">
				{searchParams.get('isLive') === 'true' ? (
					<LiveMenu />
				) : (
					
				)}
			</Drawer> */}
			<div className="all-menus-wrapper">
				<div className={`sports-title`}>TOP SPORTS</div>
				<div className="menu-wrapper top-menu-wrapper">
					{topMenuData?.map((value: any, index: number) => (
						<div
							className={`menu-item ${
								activeMenuItem === `menu-item-${value?.name}`
									? !isMobile && 'menu-item__active'
									: ''
							}`}
							key={'top-menu-item' + index}>
							<div
								onClick={
									openKeys === `sub${index}`
										? () => {}
										: () => {
												handleCurrentSportLeagues(
													value.id,
													value.name,
													`menu-item-${value?.name}`
												);
												setIsOpen(false);
										  }
								}
								className="icon-name-wrapper">
								<SVGComponent
									path={value?.image}
									width={isMobile ? 20 : 16}
									height={isMobile ? 20 : 16}
									key={value?.id + 'svg-component'}
								/>
								<CustomLabel
									id={value.id}
									className="main"
									name={value.name}
									key={value?.id + 'label'}
									isActive={activeMenuItem === `menu-item-${value?.name}`}
									keyId={`menu-item-${value?.name}`}
									setActiveSportName={setActiveMenuItem}
									eventCount={
										value?.eventCount !== 0
											? value?.eventCount
											: value?.leagueSpecialsCount
									}
								/>
							</div>
						</div>
					))}
				</div>
				<div className={`sports-title`}>ALL SPORTS</div>
				<div className=" menu-wrapper ordinary-menu-wrapper">
					{sportNames?.payload?.map((value: any, index: number) => (
						<div
							onClick={
								openKeys === `sub${index}`
									? () => {}
									: () => {
											handleCurrentSportLeagues(
												value.id,
												value.name,
												`menu-item-${index}`
											);
											setIsOpen(false);
									  }
							}
							className={`menu-item ${
								activeMenuItem === `menu-item-${value?.name}`
									? !isMobile && 'menu-item__active'
									: ''
							}`}
							key={'ordinary-menu-item' + index}>
							<div className="icon-name-wrapper">
								<SVGComponent
									path={value?.image}
									width={isMobile ? 20 : 16}
									height={isMobile ? 20 : 16}
									key={value?.id + 'svg-component'}
								/>
								<CustomLabel
									id={value.id}
									className="main"
									name={value.name}
									key={value?.id + 'label'}
									isActive={activeMenuItem === `menu-item-${value?.name}`}
									keyId={`menu-item-${value?.name}`}
									setActiveSportName={setActiveMenuItem}
									eventCount={
										value?.eventCount !== 0
											? value?.eventCount
											: value?.leagueSpecialsCount
									}
								/>
							</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default Menu;
