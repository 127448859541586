import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const depositApi = createApi({
	reducerPath: 'deposit',
	baseQuery: baseQuery(`/api/v1`),
	endpoints: builder => ({
		requestDeposit: builder.mutation({
			query: ({body}) => {
				return {
					url: `payments/deposit/request`,
					method: 'POST',
					body
				};
			}
		}),
		getQurrencyExchange: builder.query({
			query: body => ({
				url: `public/currency/exchange`,
				method: 'GET',
				params: {
					...body
				}
			})
		})
	})
});

export const {useRequestDepositMutation, useLazyGetQurrencyExchangeQuery} = depositApi;
