import {createApi} from '@reduxjs/toolkit/query/react';
import {LoginFormProps} from 'components/Popup/LoginForm';
import {SingInFormProps} from 'components/Popup/SignInForm';
import baseQuery from 'utils/helpers/baseQuery';

export const authApi = createApi({
	reducerPath: 'auth',
	baseQuery: baseQuery('/api/v1'),
	endpoints: builder => ({
		signUp: builder.mutation({
			query: (body: SingInFormProps) => ({
				url: 'auth/sign-up',
				method: 'POST',
				body
			})
		}),
		logIn: builder.mutation({
			query: (body: LoginFormProps) => ({
				url: 'auth/sign-in',
				method: 'POST',
				body
			})
		}),
		logOut: builder.mutation({
			query: () => ({
				url: 'auth/sign-out',
				method: 'POST'
			})
		}),
		getUserInfo: builder.query({
			query: () => ({
				url: 'user',
				method: 'GET'
			})
		}),
		updateUserDetails: builder.mutation({
			query: body => ({
				url: 'user',
				method: 'PATCH',
				body
			})
		}),
		resetPassword: builder.mutation({
			query: body => ({
				url: 'auth/password-recover',
				method: 'POST',
				body
			})
		}),
		resetPasswordPermission: builder.mutation({
			query: body => ({
				url: 'auth/password-reset',
				method: 'POST',
				body
			})
		}),
		changePassword: builder.mutation({
			query: body => ({
				url: 'user/password',
				method: 'PATCH',
				body
			})
		}),
		emailVerification: builder.mutation({
			query: body => ({
				url: 'auth/email-confirmation',
				method: 'POST',
				body
			})
		}),
		verification: builder.query({
			query: () => ({
				url: 'verification',
				method: 'GET'
			})
		}),
		inbox: builder.query({
			query: ({endPoint}) => ({
				url: `messages?${endPoint}`,
				method: 'GET'
			})
		}),
		setMessageRead: builder.mutation({
			query: id => ({
				url: `messages/${id}`,
				method: 'PATCH',
				body: {isRead: true}
			})
		}),
		getUnreadMessages: builder.query({
			query: () => ({
				url: `messages/unread`,
				method: 'GET'
			})
		}),
		getWebsiteData: builder.query({
			query: () => ({
				url: `public/website/settings`,
				method: 'GET'
			})
		})
	})
});

export const {
	useSignUpMutation,
	useLogInMutation,
	useLogOutMutation,
	useLazyGetUserInfoQuery,
	useUpdateUserDetailsMutation,
	useChangePasswordMutation,
	useResetPasswordMutation,
	useResetPasswordPermissionMutation,
	useSetMessageReadMutation,
	useEmailVerificationMutation,
	useVerificationQuery,
	useLazyInboxQuery,
	useLazyGetUnreadMessagesQuery,
	useLazyGetWebsiteDataQuery
} = authApi;
