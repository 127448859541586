import {createSlice} from '@reduxjs/toolkit';

const topSportSlice = createSlice({
	name: 'topSportSlice',
	initialState: {},
	reducers: {
		setCurrentTopSport(_, action) {
			return action.payload;
		}
	}
});

export const {setCurrentTopSport} = topSportSlice.actions;
export default topSportSlice.reducer;
