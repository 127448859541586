import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const fixturesApi = createApi({
	reducerPath: 'fixtures',
	baseQuery: baseQuery(`/api/v1`),
	endpoints: builder => ({
		getFixturesByID: builder.query({
			query: (data: {id: string}) => ({
				url: `fixtures/league/${data.id}`,
				method: 'GET'
			})
		}),
		getOddsByID: builder.query({
			query: (data: {id: string}) => ({
				url: `odds/league/${data.id}`,
				method: 'GET'
			})
		}),
		getOddsByFixtureID: builder.query({
			query: (data: {id: number; isLive?: boolean}) => ({
				url: `fixtures/${data.id}${data.isLive ? '?isLive=true' : ''}`,
				method: 'GET'
			})
		}),
		getNearbyGamesBySportId: builder.query({
			query: ({id, params}) => {
				return {
					url: `fixtures/sport/${id}`,
					method: 'GET',
					params
				};
			}
		}),
		getRelatedFixturesByEventId: builder.query({
			query: (data: {eventId: number; isLive?: boolean}) => ({
				url: `fixtures/${data.eventId}/related${data.isLive ? '?isLive=true' : ''}`,
				method: 'GET'
			})
		}),
		getSpecialFixturesByEventId: builder.query({
			query: (data: {eventId: number; isLive?: boolean; rotNum?: number}) => ({
				url: `fixtures/${data.eventId}/specials${data.isLive ? '?isLive=true' : ''}${
					data?.rotNum ? (data.isLive ? '&' : '?') + 'rotNum=' + data.rotNum : ''
				}`,
				method: 'GET'
			})
		}),
		getFixturesBySearch: builder.query({
			query: ({params}) => ({
				url: `fixtures/search`,
				method: 'GET',
				params
			})
		}),
		getFuturesByLeagueId: builder.query({
			query: (data: {leagueId: string}) => ({
				url: `fixtures/specials/league/${data.leagueId}`,
				method: 'GET'
			})
		}),
		getFixturesByEventId: builder.query({
			query: (eventIds: number[]) => {
				const queryStringArray = eventIds.map(number => `eventIds=${number}`);

				const queryString = queryStringArray.join('&');

				const params = `?${queryString}`;
				return {
					url: `fixtures/by-event-ids${params}`,
					method: 'GET'
				};
			}
		})
	})
});

export const {
	useLazyGetFixturesByIDQuery,
	useLazyGetOddsByIDQuery,
	useLazyGetOddsByFixtureIDQuery,
	useLazyGetNearbyGamesBySportIdQuery,
	useGetNearbyGamesBySportIdQuery,
	useLazyGetRelatedFixturesByEventIdQuery,
	useLazyGetSpecialFixturesByEventIdQuery,
	useLazyGetFixturesBySearchQuery,
	useLazyGetFuturesByLeagueIdQuery,
	useLazyGetFixturesByEventIdQuery,
	useGetOddsByFixtureIDQuery
} = fixturesApi;
