import {createSlice} from '@reduxjs/toolkit';

const openModalSlice = createSlice({
	name: 'openModalSlice',
	initialState: {isOpen: false},
	reducers: {
		setOpenModalState(state, action) {
			return {...state, isOpen: action.payload};
		}
	}
});

export const {setOpenModalState} = openModalSlice.actions;
export default openModalSlice.reducer;
