import React from 'react';
import LanguageSelector from 'utils/i18next/LanguageSelector';
import HeaderLogo from './HeaderLogo';

function MobileAuthHeader({closeModal}: any) {
	return (
		<div
			className="mobile-auth-header"
			style={{
				background: 'var(--login-popup-bg-color)',
				height: '50px',
				display: 'flex',
				justifyContent: 'space-between',
				alignItems: 'center',
				flexDirection: 'row'
			}}>
			<div>
				<HeaderLogo />
				<img
					style={{marginLeft: 20}}
					width={30}
					height={20}
					alt="United States"
					src={`http://purecatamphetamine.github.io/country-flag-icons/1x1/${
						localStorage.getItem('language') === 'ru' ? 'RU' : 'US'
					}.svg`}
				/>

				<LanguageSelector />
			</div>
			{closeModal}
		</div>
	);
}

export default MobileAuthHeader;
