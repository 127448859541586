import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const sportsApi = createApi({
	reducerPath: 'sports',
	baseQuery: baseQuery(`/api/v1`),
	endpoints: builder => ({
		getAllSports: builder.query({
			query: (params?: {hasLiveOfferings?: boolean}) => ({
				url: 'sports',
				method: 'get',
				params
			})
		}),
		getSportById: builder.query({
			query: (data: {id: string}) => ({
				url: `sports/${data.id}`,
				method: 'get'
			})
		}),
		getLeagues: builder.query({
			query: (data: {id: string}) => ({
				url: `leagues/sport/${data.id}`,
				method: 'get'
			})
		}),
		getSportImage: builder.query({
			query: (data: {path: string}) => ({
				url: data.path,
				method: 'get'
			})
		})
	})
});

export const {
	useGetAllSportsQuery,
	useLazyGetAllSportsQuery,
	useLazyGetSportByIdQuery,
	useLazyGetLeaguesQuery,
	useGetSportImageQuery
} = sportsApi;
