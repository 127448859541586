import {useDispatch, useSelector} from 'react-redux';
import './../MainPageBets.less';
import {LockOutlined} from '@ant-design/icons';
import {RootState} from 'store';
import {createBet, deleteSingleBet} from 'store/slices/createBetSlice';
import {betType, breakpoint} from 'utils/types';
import {useEffect, useState} from 'react';
import {oddConverter, oddConverterToShow} from 'utils/helpers/oddConverter';
import useScreenWidth from 'utils/hooks/useScreenWidth';

interface CoefficientBoxProps {
	coefficent: number | undefined;
	multiLine?: boolean;
	coefficentDiff?: number | null;
	betID: string;
	name?: string;
	club?: string;
	teams: {
		club1: string;
		club2: string;
	};
	type: typeof betType.HANDICAP | typeof betType.MONEYLINE | typeof betType.TOTAL;
	startsAt: string;
	leagueName: string;
	maxBet: number;
	indicator?: string | number;
	eventId: number;
	marketTitle: string;
	leagueId?: string;
	startsAtNotFormatted: string;
	predictedClubType?: string;
	side?: string;
	point?: number;
	prevCoefficent?: number | undefined;
	periodNumber?: number;
	homeTeamType?: string;
	isBetOpen?: boolean;
	isSuspended: boolean;
	isBetClosedByStatus: boolean;
	resize?: any;
}

const CoefficientBox = ({
	resize = true,
	coefficent,
	multiLine,
	coefficentDiff,
	betID,
	club,
	teams,
	type,
	startsAt,
	leagueName,
	maxBet,
	indicator,
	eventId,
	marketTitle,
	leagueId,
	startsAtNotFormatted,
	predictedClubType,
	side,
	point,
	prevCoefficent,
	periodNumber,
	homeTeamType,
	isBetOpen,
	isSuspended,
	isBetClosedByStatus
}: CoefficientBoxProps) => {
	const darkMode = useSelector((state: RootState) => state.darkMode);
	const dispatch = useDispatch();
	const activeBets = useSelector((state: RootState) => state.createbetSlice);
	const [active, setActive] = useState(
		!!Object.entries(activeBets).find(
			([key, value]) =>
				key.includes(`${eventId}`) && key.includes(type) && (value as any).indicator == indicator
		)
	);
	const oddType = useSelector((state: RootState) => state.oddsTypeSlice);

	const activeBetID = `${eventId} - ${type} - ${periodNumber} - ${marketTitle}`;
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;
	let status;

	useEffect(() => {
		setActive(
			!!Object.entries(activeBets).find(
				([key, value]) =>
					key.includes(`${eventId}`) &&
					(value as any).indicator == indicator &&
					(value as any).periodType == periodNumber
			)
		);
	}, [activeBets]);

	const modifiedCoefficentToShow = coefficent ? oddConverterToShow(coefficent, oddType) : null;

	if (prevCoefficent && coefficent && prevCoefficent < coefficent) {
		status = 'up';
	} else if (prevCoefficent && coefficent && prevCoefficent > coefficent) {
		status = 'down';
	}

	if ((isSuspended && isBetClosedByStatus) || isBetClosedByStatus || !coefficent) {
		return (
			<div
				className="coefficent-box-wrapper coefficent-box-wrapper__unavailable"
				onClick={e => {
					e.stopPropagation();
					e.preventDefault();
				}}>
				{/* <CgUnavailable color={darkMode ? '#818181' : '#5B5B5B'} /> */}
				<LockOutlined style={{color: darkMode ? '#72777F' : '#5B5B5B'}} />
			</div>
		);
	} else if (isSuspended) {
		return (
			<div
				className="coefficent-box-wrapper coefficent-box-wrapper__unavailable"
				style={{}}
				onClick={e => {
					e.stopPropagation();
					e.preventDefault();
				}}>
				<span className="coefficent-box-wrapper__suspended">SUSPENDED</span>
			</div>
		);
	}

	return (
		<div
			className={`coefficent-box-wrapper ${active ? 'coefficent-box-wrapper__active' : ''} ${
				status === 'up'
					? 'coefficent-box-wrapper__green'
					: status === 'down'
					? 'coefficent-box-wrapper__red'
					: ''
			} ${multiLine ? 'coefficent-box-wrapper__multiline' : ''}`}
			onClick={e => {
				active
					? dispatch(deleteSingleBet({id: `${activeBetID}`}))
					: dispatch(
							createBet({
								id: betID,
								betData: teams,
								predictedClub: club,
								coefficent,
								type,
								startsAt: startsAtNotFormatted,
								leagueName,
								maxBet,
								indicator,
								periodType: periodNumber,
								eventId,
								descriptionAddition: periodNumber,
								parentId: null,
								specialName: coefficentDiff ? coefficentDiff : club,
								marketTitle,
								leagueId,
								predictedClubType,
								side,
								point,
								initialPlacedRate: coefficent,
								initialPlacedMaxBet: maxBet,
								homeTeamType,
								isLiveOverview: location.pathname.includes('live-overview'),
								isBetOpen
							})
					  );
				setActive(prev => !prev);
				e.stopPropagation();
				e.preventDefault();
			}}>
			{multiLine && (
				<span className="coefficent-number coefficent-number__difference">
					{coefficentDiff && coefficentDiff > 0 && type === betType.HANDICAP ? '+' : ''}
					{coefficentDiff}
				</span>
			)}
			<span className="coefficent-number">{modifiedCoefficentToShow}</span>
		</div>
	);
};

export default CoefficientBox;
