import {Form, Input, Tooltip} from 'antd';
import {ChangeEvent, Dispatch, SetStateAction, useEffect, useState} from 'react';
import {BiSearchAlt} from 'react-icons/bi';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store';

import {useNavigate, useSearchParams} from 'react-router-dom';
import './../Header.less';
import {setCurrentActiveMenuType} from 'store/slices/menuSlice';
import {breakpoint, menuTypes} from 'utils/types';
import {useTranslation} from 'react-i18next';
import {set} from 'lodash';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {CgCloseR} from 'react-icons/cg';

interface FormProps {
	search: string;
}

interface SearchbarProps {
	setInputOpen?: Dispatch<SetStateAction<boolean>>;
	isOpen?: boolean;
}

const Searchbar = ({setInputOpen, isOpen}: SearchbarProps) => {
	const darkMode = useSelector((state: RootState) => state.darkMode);
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form] = Form.useForm();
	const {t} = useTranslation();

	const [seacrhParams] = useSearchParams();

	const search = seacrhParams.get('search');

	const [searchbarInput, setSearchbarInput] = useState<string>(search || '');
	const [status, setStatus] = useState<'' | 'error'>('');

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.MOBILE;

	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setSearchbarInput(e.target.value);
		setStatus('');
	};

	const onFinish = async (values: FormProps) => {
		const {search} = values;
		if (search && search.length < 3) {
			setStatus('error');
			return;
		}
		form.resetFields();
		setStatus('');
		navigate(`/bettings/filtered?search=${values.search}`);
		dispatch(setCurrentActiveMenuType(menuTypes.NON_SELECTED));
	};

	useEffect(() => {
		if (search) {
			setInputOpen?.(true);
			form.setFieldsValue({search});
		}
	}, [search]);

	return (
		<Tooltip
			open={status === 'error'}
			placement="bottom"
			title={'Must contain three or more characters.'}
			color="#0079ff">
			<div className={`searchbar ${isMobile && isOpen ? 'searchbar__mobile-opened' : ''}`}>
				<Form onFinish={onFinish} style={{height: 30}} form={form} initialValues={{search}}>
					<Form.Item name="search">
						<Input
							placeholder={t('searchSports') || 'Search'}
							prefix={
								<BiSearchAlt
									size={25}
									style={{cursor: 'pointer'}}
									color={darkMode ? '#fff' : '#000'}
									className="search-icon"
									onClick={() => {
										if (searchbarInput && searchbarInput.length <= 3) {
											setStatus('error');
											return;
										}
										if (isMobile && searchbarInput && searchbarInput.length >= 3) {
											onFinish({search: searchbarInput});
										}
									}}
								/>
							}
							className={`searchbar__input`}
							onChange={handleChange}
							value={searchbarInput}
							autoComplete="off"
							status={status}
							bordered={false}
							onFocus={() => {
								if (!isOpen) {
									setInputOpen && setInputOpen(true);
								}
							}}
							onBlur={() => {
								if (isOpen && !searchbarInput) {
									setInputOpen && setInputOpen(false);
								}
							}}
							suffix={
								isOpen && isMobile ? (
									<span
										className="searchbar__close-icon"
										onClick={() => {
											if (!searchbarInput) {
												setInputOpen?.(prev => {
													return !prev;
												});
												return;
											}
											form.setFieldValue('search', '');
											setSearchbarInput('');
										}}>
										<CgCloseR size={20} color="white" />
									</span>
								) : null
							}
						/>
					</Form.Item>
				</Form>
			</div>
		</Tooltip>
	);
};

export default Searchbar;
