import {Col, Divider, Row} from 'antd';
import {IoTimerOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import {initialSpan} from 'utils/helpers/initialSpan';
import {getHandicapValue, handleHandicapIdicator} from 'utils/helpers/oddConverter';
import {betType, betTypeKeys, breakpoint, homeTeamType as homeTeamTypes} from 'utils/types';
import './../MainPageBets.less';
import CoefficientBox from './CoefficientBox';
import MatchCount from 'views/LiveOverview/components/MatchCount';
import LiveIcon from 'views/LiveOverview/components/LiveIcon';
import {modifiedEventState} from 'views/SingleGameBet/components/TimeTable';
import useScreenWidth from 'utils/hooks/useScreenWidth';

export interface CoefficentProps {
	firstWinCF: number | undefined;
	secondWinCF: number | undefined;
	drawCF: number | undefined;
	handicap1CF: number | undefined;
	handicap2CF: number | undefined;
	overCF: number | undefined;
	underCF: number | undefined;
	handicapDiff?: number | undefined;
	overDiff?: number | null;
}

export interface GridTemplateBetProps {
	stats?: boolean;
	liveStatus: number;
	club1: string;
	club2: string;
	startsAt: string;
	coefficents: CoefficentProps;
	marketsCount: number;
	id: string;
	eventId: number;
	betID: string;
	defaultFields: {
		handicap: boolean;
		draw: boolean;
	};
	leagueName: string;
	maxBets: {
		maxMoneyline: number;
		maxTotal: number;
		maxSpread: number;
	};
	nearbyGames?: boolean;
	startsAtNotFormatted: string;
	leagueId: string;
	homeTeamType?: string;
	prevCoefficents: CoefficentProps;
	currentSelectedBetType?: string;
	periodNumber?: number;
	matchData?: {
		eachPeriodScore: {club1: number | null; club2: number | null; periodNumber: number}[];
		finalResult: {club1: number | null; club2: number | null};
		redCards?: {club1: number | null; club2: number | null};
	};
	sportName?: string;
	parentId?: number;
	isBetOpen?: boolean;
	isSuspended: boolean;
	isBetClosedByStatus: boolean;
	minutes?: number | null;
	eventState?: number | null;
}

export function diff_minutes(dt2: string) {
	var diff = (new Date(dt2).getTime() - new Date().getTime()) / 1000;
	diff /= 60;
	return Math.abs(Math.round(diff));
}

const GridTemplate = ({
	matchData,
	stats,
	club1,
	club2,
	startsAt,
	marketsCount,
	coefficents,
	id,
	eventId,
	defaultFields,
	betID,
	leagueName,
	maxBets,
	nearbyGames,
	startsAtNotFormatted,
	leagueId,
	homeTeamType,
	prevCoefficents,
	currentSelectedBetType,
	periodNumber,
	isBetOpen,
	isBetClosedByStatus,
	isSuspended,
	liveStatus,
	parentId,
	minutes,
	eventState,
	sportName
}: GridTemplateBetProps) => {
	const navigate = useNavigate();
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	const showCols = {
		moneyline: currentSelectedBetType === betType.MONEYLINE || !isMobile,
		handicap: currentSelectedBetType === betType.HANDICAP || !isMobile,
		total: currentSelectedBetType === betType.TOTAL || !isMobile
	};

	const firstColSize = showCols.moneyline
		? defaultFields.draw
			? !stats
				? 235
				: 265
			: 170
		: !stats
		? 170
		: sportName === 'Soccer'
		? 200
		: 170;

	return (
		<tbody
			onClick={e => {
				e.stopPropagation();
				liveStatus === 1
					? navigate(`/bettings/single/${eventId}/?isLive=true&parentId=${parentId}#period:-1`)
					: navigate(`/bettings/single/${eventId}/#period:-1`);
			}}
			className="ant-table-tbody grid-template-bet">
			<tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
				<td className="ant-table-cell">
					<div style={{paddingLeft: 10}} className="game-info-row-wrapper">
						<div
							className="single-bet-sport-info"
							style={{width: screenWidth < 450 ? `${screenWidth - firstColSize}px` : '100%'}}>
							<div className="single-bet-sport-info">
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<div className="single-bet-sport-info__team">
										<span>{club1.replace('(Sets)', '')}</span>
									</div>
									{!!matchData?.redCards?.club1 && (
										<span className="single-bet-sport-info__card">
											{matchData?.redCards?.club1}
										</span>
									)}
								</div>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<div className="single-bet-sport-info__team">
										<span>{club2.replace('(Sets)', '')}</span>
									</div>
									{!!matchData?.redCards?.club2 && (
										<span className="single-bet-sport-info__card">
											{matchData?.redCards?.club2}
										</span>
									)}
								</div>
								{!stats && !nearbyGames && (
									<div className="single-bet-sport-info__start-hint-wrapper">
										{/* {startsAtNotFormatted && diff_minutes(startsAtNotFormatted) < 60 && (
												<IoTimerOutline color="#0079ff" />
											)} */}
										{startsAtNotFormatted && (
											<span className="single-bet-sport-info__start-hint">
												{diff_minutes(startsAtNotFormatted) < 60
													? `Starts in ${diff_minutes(
															startsAtNotFormatted
													  )} minutes`
													: startsAt}
											</span>
										)}
										<div
											style={{
												display: 'flex',
												justifyContent: 'center',
												alignItems: 'center',
												flexDirection: 'row',
												gap: 5
											}}>
											<span className="markets-counter">+{marketsCount}</span>
											{liveStatus === 2 && <LiveIcon type="inactive" />}{' '}
										</div>
									</div>
								)}{' '}
							</div>

							{nearbyGames && (
								<div className="single-bet-sport-info__start-hint-wrapper">
									{/* {startsAtNotFormatted && diff_minutes(startsAtNotFormatted) < 60 && (
										<IoTimerOutline color="#0079ff" />
									)} */}
									{startsAtNotFormatted && (
										<span className="single-bet-sport-info__start-hint">
											{diff_minutes(startsAtNotFormatted) < 60
												? `Starts in ${diff_minutes(startsAtNotFormatted)} minutes`
												: startsAt}
										</span>
									)}
									<div
										style={{
											display: 'flex',
											justifyContent: 'center',
											alignItems: 'center',
											flexDirection: 'row',
											gap: 5
										}}>
										<span className="markets-counter">+{marketsCount}</span>
										{liveStatus === 2 && <LiveIcon type="inactive" />}{' '}
									</div>
								</div>
							)}

							{stats && (
								<div className="live-game-timer">
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: 5
										}}>
										<span className="live-game-timer__hint">
											{eventState && `${modifiedEventState(eventState, screenWidth)}`}
										</span>{' '}
										<span className="live-game-timer__hint live-game-timer__hint_minute">
											{minutes && `${minutes}'`}
										</span>
									</div>
									<div
										style={{
											display: 'flex',
											flexDirection: 'row',
											alignItems: 'center',
											gap: 5
										}}>
										<LiveIcon />
									</div>
								</div>
							)}
						</div>
						{stats && sportName === 'Soccer' && (
							<div style={{display: 'flex'}}>
								<MatchCount
									eachPeriodScore={matchData?.eachPeriodScore}
									finalResult={matchData?.finalResult}
								/>
								<Divider
									type="vertical"
									style={{
										width: 1,
										height: 40
									}}
									className="vertical-live-divider-live-template"
								/>
							</div>
						)}
					</div>
				</td>
				{showCols.moneyline && (
					<td className="ant-table-cell">
						<div className="grid-column grid-column__box ">
							<CoefficientBox
								resize={false}
								isBetOpen={isBetOpen}
								isSuspended={isSuspended}
								isBetClosedByStatus={isBetClosedByStatus}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match MONEY LINE'}
								eventId={eventId}
								indicator={club1}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.firstWinCF}
								prevCoefficent={prevCoefficents?.firstWinCF}
								betID={betID}
								club={club1}
								type={betType.MONEYLINE}
								startsAt={startsAt}
								maxBet={maxBets?.maxMoneyline}
								predictedClubType={homeTeamTypes.TEAM1}
								homeTeamType={homeTeamType}
							/>
							{defaultFields.draw && (
								<CoefficientBox
									resize={false}
									isBetOpen={isBetOpen}
									isSuspended={isSuspended}
									isBetClosedByStatus={isBetClosedByStatus}
									periodNumber={periodNumber}
									startsAtNotFormatted={startsAtNotFormatted}
									leagueId={leagueId}
									marketTitle={'Match MONEY LINE'}
									eventId={eventId}
									indicator={homeTeamTypes.DRAW}
									leagueName={leagueName}
									teams={{club1, club2}}
									coefficent={coefficents?.drawCF}
									prevCoefficent={prevCoefficents?.drawCF}
									betID={betID}
									type={betType.MONEYLINE}
									startsAt={startsAt}
									maxBet={maxBets?.maxMoneyline}
									club={homeTeamTypes.DRAW}
									predictedClubType={homeTeamTypes.DRAW}
									homeTeamType={homeTeamType}
								/>
							)}
							<CoefficientBox
								resize={false}
								isBetOpen={isBetOpen}
								isSuspended={isSuspended}
								isBetClosedByStatus={isBetClosedByStatus}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match MONEY LINE'}
								eventId={eventId}
								indicator={club2}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.secondWinCF}
								prevCoefficent={prevCoefficents?.secondWinCF}
								betID={betID}
								club={club2}
								type={betType.MONEYLINE}
								startsAt={startsAt}
								maxBet={maxBets?.maxMoneyline}
								predictedClubType={homeTeamTypes.TEAM2}
								homeTeamType={homeTeamType}
							/>
						</div>
					</td>
				)}
				{showCols.handicap && (
					<td className="ant-table-cell">
						<div className="grid-column grid-column__box">
							<CoefficientBox
								isBetOpen={isBetOpen}
								isSuspended={isSuspended}
								isBetClosedByStatus={isBetClosedByStatus}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match HANDICAP'}
								eventId={eventId}
								indicator={handleHandicapIdicator(
									coefficents?.handicapDiff,
									club1,
									homeTeamType,
									true
								)}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.handicap1CF ?? undefined}
								multiLine
								betID={betID}
								coefficentDiff={
									homeTeamType === homeTeamTypes.TEAM1
										? coefficents.handicapDiff
										: coefficents.handicapDiff && coefficents.handicapDiff * -1
								}
								prevCoefficent={prevCoefficents?.handicap1CF}
								club={club1}
								type={betType.HANDICAP}
								startsAt={startsAt}
								maxBet={maxBets?.maxSpread}
								predictedClubType={homeTeamTypes.TEAM1}
								point={getHandicapValue(coefficents?.handicapDiff, club1, homeTeamType, true)}
								homeTeamType={homeTeamType}
							/>
							<CoefficientBox
								isBetOpen={isBetOpen}
								isSuspended={isSuspended}
								isBetClosedByStatus={isBetClosedByStatus}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match HANDICAP'}
								eventId={eventId}
								indicator={handleHandicapIdicator(
									coefficents?.handicapDiff,
									club2,
									homeTeamType
								)}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.handicap2CF ?? undefined}
								prevCoefficent={prevCoefficents?.handicap2CF}
								multiLine
								betID={betID}
								coefficentDiff={
									homeTeamType === homeTeamTypes.TEAM1
										? coefficents?.handicapDiff && coefficents?.handicapDiff * -1
										: coefficents?.handicapDiff
								}
								club={club2}
								type={betType.HANDICAP}
								startsAt={startsAt}
								maxBet={maxBets?.maxSpread}
								predictedClubType={homeTeamTypes.TEAM2}
								point={getHandicapValue(coefficents?.handicapDiff, club2, homeTeamType)}
								homeTeamType={homeTeamType}
							/>
						</div>
					</td>
				)}
				{showCols.total && (
					<td className="ant-table-cell">
						<div className="grid-column grid-column__box">
							<CoefficientBox
								isBetOpen={isBetOpen}
								isSuspended={isSuspended}
								isBetClosedByStatus={isBetClosedByStatus}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match TOTAL'}
								eventId={eventId}
								indicator={`${betTypeKeys.OVER} ${coefficents.overDiff}`}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.overCF}
								prevCoefficent={prevCoefficents?.overCF}
								multiLine
								betID={betID}
								coefficentDiff={coefficents.overDiff}
								club={club1}
								type={betType.TOTAL}
								startsAt={startsAt}
								maxBet={maxBets?.maxTotal}
								side={betTypeKeys.OVER}
								point={coefficents.overDiff ?? 0}
								homeTeamType={homeTeamType}
							/>
							<CoefficientBox
								isBetOpen={isBetOpen}
								isSuspended={isSuspended}
								isBetClosedByStatus={isBetClosedByStatus}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match TOTAL'}
								eventId={eventId}
								indicator={`${betTypeKeys.UNDER} ${coefficents.overDiff}`}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.underCF}
								prevCoefficent={prevCoefficents?.underCF}
								multiLine
								betID={betID}
								coefficentDiff={coefficents.overDiff}
								club={club2}
								type={betType.TOTAL}
								startsAt={startsAt}
								maxBet={maxBets?.maxTotal}
								side={betTypeKeys.UNDER}
								point={coefficents.overDiff ?? 0}
								homeTeamType={homeTeamType}
							/>
						</div>
					</td>
				)}
			</tr>
		</tbody>
	);
};

export default GridTemplate;
