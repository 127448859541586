import {Button, Input, Select} from 'antd';
import CLOCK_LOADER_ICON from 'assets/icons/clock-loader.svg';
import NumberInput, {
	formatNumberWithCommas,
	numberWithCommas
} from 'components/CustomNumberInput/CustomNumberInput';
import CustomNumpad from 'components/CustomNumpad/CustomNumpad';
import 'components/Layout/Header/Header.less';
import {UserSettingsDropdownProps} from 'components/Layout/Header/components/UserBalance';
import Notification, {NotificationProps} from 'components/Notifications/Notification';
import LoginForm from 'components/Popup/LoginForm';
import Popup from 'components/Popup/Popup';
import ResetPasswordForm from 'components/Popup/ResetPasswordForm';
import SignInForm from 'components/Popup/SignInForm';
import _, {isEmpty} from 'lodash';
import {useCallback, useEffect, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {AiFillCaretDown} from 'react-icons/ai';
import {VscTrash} from 'react-icons/vsc';
import {useDispatch, useSelector} from 'react-redux';
import {toast} from 'react-toastify';
import {RootState} from 'store';
import {useLazyGetUserInfoQuery} from 'store/services/authAPI';
import {usePostMultipleBetsMutation, usePostSingleBetsMutation} from 'store/services/createBetApi';
import {useLazyGetFixturesByEventIdQuery} from 'store/services/fixturesAPI';
import {
	deleteAllBets,
	deleteSingleBet,
	updateChangedRatesAndCoefficents,
	updateSingleBetState
} from 'store/slices/createBetSlice';
import {currentBetStatus} from 'utils/helpers/findCurrentBet';
import {handleInputValidation} from 'utils/helpers/inputNumberConverter';
import {getSinglePayoutByOddType, oddConverter, oddConverterToShow} from 'utils/helpers/oddConverter';
import {removeExtraKeys} from 'utils/helpers/removeExtraKeys';
import {deleteLastChar, handleInputChangeWithCheck} from 'utils/helpers/stringModifications';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {OddsProps} from 'utils/interfaces';
import {
	COEFFICENT_LIMIT,
	MAX_MULTIPLE_WIN_AMOUNT,
	MIN_BET_AMOUNT,
	PlaceBetResponseStatusEnum,
	StatusEnum,
	actionTypes,
	authorizationTypes,
	betsConnectionTypes,
	breakpoint,
	modalTypes,
	numpadActionTypes,
	oddTypes,
	periodTypes
} from 'utils/types';
import {findBetslipActualRates} from '../utils/filterBetslipActualRates';
import './../Betslip.less';
import SingleBetBox, {SingleBetBoxProps} from './SingleBetBox';
import TypeHandlerButton from './TypeHandlerButton';
import {changeInputValues} from '../utils/changeInputValues';
import {addThousandSeperator} from 'utils/helpers/addThousandSeperator';
import {setUser, setUserCredentials} from 'store/slices/authSlice';

const UserSettingsCustomLabel = ({setting, bordered, prefixIcon, onClick}: UserSettingsDropdownProps) => {
	return (
		<div
			onClick={onClick ? onClick : () => {}}
			className={`custom-dropdown-user-settings ${
				bordered ? 'custom-dropdown-user-settings__bordered' : ''
			}`}>
			<span className="bet-selection-type-text">{setting}</span>
		</div>
	);
};

export interface OddsHandlePanelProps {
	oddsData: {
		[K in string]: SingleBetBoxProps;
	};
	setMobileBetslipOpener: any;
}

const OddsHandlePanel = ({oddsData, setMobileBetslipOpener}: OddsHandlePanelProps) => {
	const {t} = useTranslation();
	const darkMode = useSelector((state: RootState) => state.darkMode);
	const [tooltipContent, setTooltipContent] = useState<string>(t('canNotCombineBets') as string);
	const [isModalOpen, setIsModalOpen] = useState(false);
	const [activeBetType, setActiveBetType] = useState<string>(betsConnectionTypes.SINGLE);
	const [multiplePermission, setMultiplePermission] = useState<boolean>(true);
	const [isDropdownOpen, setIsDropdownOpen] = useState<boolean>(false);
	const [selectValue, setSelectValue] = useState(localStorage.getItem('oddFormat') || '3');
	const [modalType, setModalType] = useState<string>();
	const [notification, setNotification] = useState<NotificationProps>({
		notificationType: '',
		notificationLabel: ''
	});
	const [multiblePayoutAndWager, setMultiblePayoutAndWager] = useState<{
		totalWager: number | null;
		payout: number | null;
		totalOdds: number | null;
		maxBet: number | null;
	}>({
		totalWager: 0,
		payout: 0,
		totalOdds: 0,
		maxBet: 0
	});
	const [totalWagerAndPayout, setTotalWagerAndPayout] = useState<{
		totalWager: number | null;
		payout: number | null;
	}>({totalWager: 0, payout: 0});
	const [inputValues, setInputValues] = useState<{
		[id: string]: {
			amount: number | null | any;
			coefficent: number;
			gaining: number | null;
			maxBet: number;
		};
	}>({});

	const [multipleBetInput, setMultipleBetInput] = useState<any>(null);

	const [singleBetStatus, setSingleBetStatus] = useState<{
		successfulBets: string[];
		failedBets: {id: string; message: string; actualRate: number; amount: number}[];
	}>({
		successfulBets: [],
		failedBets: []
	});

	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);

	const [getUserInfo, {data}] = useLazyGetUserInfoQuery();
	const [getBetslipGamesData, {data: betslipGamesData}] = useLazyGetFixturesByEventIdQuery();
	const [postSingleBets, {data: singleBetsResponse}] = usePostSingleBetsMutation();
	const [singleBetLoading, setSingleBetLoading] = useState(false);
	const [activeInput, setActiveInput] = useState<
		| {
				id?: string;
				coefficent?: number;
				maxBet?: number;
		  }
		| undefined
	>();

	const [postMultipleBets, {data: multipleBetsResponse}] = usePostMultipleBetsMutation();
	const [multipleBetLoading, setMultipleBetLoading] = useState(false);
	const [multipleBetsStatus, setMultipleBetsStatus] = useState<
		{
			status: string;
			message: string;
			id?: string;
			actualRate?: number;
		}[]
	>();

	const timeoutId = useRef<any>(null);

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth <= breakpoint.MOBILE;

	const oddType = useSelector((state: RootState) => state.oddsTypeSlice);

	const dispatch = useDispatch();

	const fetchBetslipGamesData = useCallback(async () => {
		const eventIds = Object.entries(oddsData)?.map(
			([key, value]) => value?.eventId || value?.specialId || 0
		);
		if (!!eventIds.length) {
			const response = await getBetslipGamesData(eventIds).unwrap();
			const actualRates = findBetslipActualRates(oddsData, response);
			dispatch(updateChangedRatesAndCoefficents({actualRates}));
		}
	}, [oddsData]);

	useEffect(() => {
		const intervalId = setInterval(fetchBetslipGamesData, 5000);

		return () => {
			clearInterval(intervalId);
		};
	}, [oddsData]);

	const handleInputChange = (
		value: number | null | any,
		id: string,
		coefficent: number,
		maxBet: number
	) => {
		const convertedCoef =
			oddType === oddTypes.AMERICAN_ODD ? oddConverter(coefficent, oddType) : coefficent;
		setInputValues(prevState => ({
			...prevState,
			[id]: {
				amount: value,
				coefficent: convertedCoef,
				gaining: value ? +(Number(value) * coefficent).toFixed(2) : null, // we also have function to convert coefficent to decimal, but it dont work correctly
				maxBet: maxBet
			}
		}));
	};

	const handleMultipleBetAmout = (value: any) => {
		if (value) {
			setMultipleBetInput(value);
		} else {
			setMultipleBetInput(null);
		}
	};

	const handleBetType = (key: string) => {
		setActiveBetType(key);
	};

	const handleSingleInputDeletion = (id: string) => {
		const newState = {...inputValues};
		delete newState[id];
		setInputValues(newState);
	};

	const handleAllBetsDeletion = () => {
		dispatch(deleteAllBets());
		setInputValues({});
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleClose = () => {
		setIsModalOpen(false);
		setModalType(modalTypes.LOGIN);
	};

	const handleModalType = (type: string) => {
		setModalType(type);
	};

	const openNumpad = (id?: string, coefficent?: number, maxBet?: number) => {
		if (id) {
			setActiveInput({
				id,
				coefficent,
				maxBet
			});
		} else {
			setActiveInput(undefined);
		}
	};
	const closeNumpad = () => {
		setActiveInput(undefined);
	};

	const handleMobileInputChange = (type: string, key?: string | number) => {
		const inputName =
			activeBetType === betsConnectionTypes.MULTIPLE
				? 'multipleBetInputName'
				: `single-bet-input-${activeInput?.id}`;
		const inputElement = document.querySelector(`input[name="${inputName}"]`) as HTMLInputElement;
		if (key === '.' && inputElement?.value.includes('.')) {
			// if (inputElement) {
			// 	const symbol = '.';
			// 	inputElement.value += symbol;
			// }
			return; // no more than 1 dot
		}
		if (type === numpadActionTypes.ADD && key !== undefined) {
			if (inputElement.value.split('.')[1]?.length >= 2) {
				return; // no more than 2 digits after dot
			}
			if (activeBetType === betsConnectionTypes.MULTIPLE) {
				const newValue = inputElement.value + key;
				setMultipleBetInput(newValue);
			} else if (activeBetType === betsConnectionTypes.SINGLE && activeInput && activeInput.id) {
				const newValue = inputElement.value + key;
				const {id, coefficent, maxBet} = activeInput;
				if (id && coefficent && maxBet) {
					handleInputChange(newValue, id, coefficent, maxBet);
				}
			}
		} else if (type === numpadActionTypes.DELETE) {
			if (inputElement.value.slice(-1) === '.') {
				inputElement.value = inputElement.value.slice(0, -1);
				return;
			}
			if (activeBetType === betsConnectionTypes.MULTIPLE) {
				const newValue = deleteLastChar(multipleBetInput?.toString());
				setMultipleBetInput(newValue ? parseFloat(newValue) : null);
			} else if (activeBetType === betsConnectionTypes.SINGLE && activeInput && activeInput.id) {
				const newValue = deleteLastChar(inputValues[activeInput.id]?.amount?.toString());
				handleInputChangeWithCheck(newValue, activeInput, handleInputChange);
			}
		}
	};

	const handleOnMaxbetClick = (id: string, maxBet: number, coefficent: number) => {
		if (oddType === oddTypes.DECIMAL) {
			setInputValues(prevState => ({
				...prevState,
				[id]: {
					amount: maxBet,
					coefficent: coefficent,
					gaining: getSinglePayoutByOddType(coefficent, maxBet, oddType),
					maxBet: maxBet
				}
			}));
		} else if (oddType === oddTypes.AMERICAN_ODD) {
			setInputValues(prevState => ({
				...prevState,
				[id]: {
					amount: maxBet,
					coefficent: oddConverter(coefficent, oddType),
					gaining: getSinglePayoutByOddType(oddConverter(coefficent, oddType), maxBet, oddType),
					maxBet: maxBet
				}
			}));
		}
	};

	const handleNotifications = () => {
		let totalWager = Number(multipleBetInput);
		let payout = Number(multipleBetInput);
		let overallCoefficent = 1;
		let maxBet = Math.pow(10, 1000); // Max positive number;
		Object?.entries(oddsData)?.map(([key, value]) => {
			overallCoefficent *= value.coefficent;
			if (value.maxBet < maxBet) {
				maxBet = value.maxBet;
			}
		});
		if (Number(multipleBetInput) && Number(multipleBetInput) < MIN_BET_AMOUNT) {
			setNotification(actionTypes.MINIMUM_STAKE as NotificationProps);
			setMultiblePayoutAndWager({
				totalWager,
				payout,
				totalOdds: Math.round(overallCoefficent * 1000) / 1000,
				maxBet
			});
			return;
		}
		if (Number(multipleBetInput) && Number(multipleBetInput) > maxBet) {
			setNotification(actionTypes.ABOVE_MAX as NotificationProps);
			setMultiblePayoutAndWager({
				totalWager,
				payout,
				totalOdds: Math.round(overallCoefficent * 1000) / 1000,
				maxBet
			});
			return;
		}
		if (overallCoefficent > COEFFICENT_LIMIT) {
			setNotification(actionTypes.COEFFICENT_OVERFLOW as NotificationProps);
		} else {
			setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
		}
		totalWager = Number(multipleBetInput);
		payout = totalWager && +(+overallCoefficent.toFixed(3) * totalWager).toFixed(2);
		setMultiblePayoutAndWager({
			totalWager,
			payout,
			totalOdds: Math.round(overallCoefficent * 1000) / 1000,
			maxBet
		});
		if (payout && payout > MAX_MULTIPLE_WIN_AMOUNT) {
			setNotification(actionTypes.ABOVE_MAX as NotificationProps);
		}
	};

	const handleMultiplePermissions = useCallback(() => {
		const betsCount = Object.keys(oddsData).length;
		const permissionDenyForGamesToCombine =
			(betsCount > 1 &&
				Object.keys(oddsData)
					.map(s => s.split(' - ')[0])
					.some((value, index, self) => self.indexOf(value) !== index)) ||
			!!_.values(oddsData).some(obj =>
				_.values(oddsData).some(innerObj => innerObj.parentId === obj.eventId)
			) ||
			!!_.values(oddsData).some(obj =>
				_.values(oddsData).some(
					innerObj =>
						innerObj.parentId === obj.parentId &&
						obj.parentId !== null &&
						innerObj.parentId !== null &&
						obj.eventId !== innerObj.eventId
				)
			) ||
			!!_.values(oddsData).some(obj =>
				_.values(oddsData).some(
					innerObj => innerObj.leagueId === obj.futuresId && innerObj.eventId !== obj.eventId
				)
			);

		if (betsCount >= 10 || betsCount === 0 || betsCount === 1 || permissionDenyForGamesToCombine) {
			setActiveBetType(betsConnectionTypes.SINGLE);
			setMultiplePermission(false);
		} else if (betsCount > 1) {
			setActiveBetType(betsConnectionTypes.MULTIPLE);
			setMultiplePermission(true);
		}
		if (betsCount === 0) {
			setTooltipContent(t('singleGameCanNotBeMultiple') as string);
		} else if (betsCount >= 10) {
			setTooltipContent(t('gamesToCombine') as string);
		} else if (permissionDenyForGamesToCombine) {
			setTooltipContent(t('sameGame') as string);
		}
	}, [oddsData, singleBetStatus]);

	const handleSingleBetPayout = () => {
		//Hayk's changes
		let totalWager = 0;
		let payout = 0;
		Object?.entries(inputValues)?.map(([key, value]) => {
			if (Object.keys(oddsData).includes(key)) {
				let tempMaxBet = value.maxBet;
				if (value?.coefficent < 2) {
					tempMaxBet = value?.maxBet / (value?.coefficent - 1);
				}
				// its for case when we deselect from coefficentBox
				if (value?.amount && value?.gaining) {
					totalWager += Number(value.amount);
					payout += value.gaining;
					setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
				}
				if (value.amount !== null && value.gaining !== null && value.amount > tempMaxBet) {
					setNotification(actionTypes.ABOVE_MAX as NotificationProps);
				}
			}
		});
		setTotalWagerAndPayout({totalWager, payout});
	};

	// useEffect(() => {
	// 	// if game in betslip is older then current time, we need to delete it
	// 	const interval = setInterval(() => {
	// 		const currentTime = new Date().toISOString(); // Get the current time in ISO format

	// 		// Filter out the games whose startsAt is less than the current time
	// 		const gamesToDelete = Object.entries(oddsData).map(([key, value]) => {
	// 			if (value.startsAt < currentTime) {
	// 				return key;
	// 			}
	// 		});

	// 		gamesToDelete.forEach(gameKey => {
	// 			dispatch(deleteSingleBet({id: gameKey}));
	// 		});
	// 	}, 10000);

	// 	return () => clearInterval(interval);
	// }, [dispatch, oddsData]);

	useEffect(() => {
		changeInputValues(inputValues, oddsData, oddType, setInputValues);
	}, [oddsData]);

	useEffect(() => {
		const updatedInputValues = {...inputValues};
		singleBetStatus.failedBets.map(value => {
			if (updatedInputValues[value.id]) {
				updatedInputValues[value.id].coefficent = value.actualRate;
				updatedInputValues[value.id].gaining = value.amount * value.actualRate;
			}
		});
		setInputValues(updatedInputValues);
	}, [singleBetStatus]);

	useEffect(() => {
		handleSingleBetPayout();
	}, [inputValues, oddType, oddsData, singleBetStatus]);

	useEffect(() => {
		handleNotifications();
	}, [multipleBetInput, oddsData]);

	useEffect(() => {
		if (
			singleBetStatus.successfulBets.length === 0 &&
			singleBetStatus.failedBets.length === 0 &&
			!singleBetLoading
		) {
			handleMultiplePermissions();
		}
		if (_.isEmpty(oddsData)) {
			handleMultipleBetAmout(0); // when we delete all bets, we need to reset multipleBetInput
		}
		removeExtraKeys(inputValues, oddsData); // its for case when we deselect from coefficentBox
	}, [Object.keys(oddsData).length, singleBetStatus]);

	useEffect(() => {
		if (Object.keys(oddsData).length === 0) {
			setInputValues({});
			setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
			setSingleBetStatus(prev => ({successfulBets: [...prev.successfulBets], failedBets: []}));
			setMultipleBetsStatus([]);
		}
	}, [oddsData]);

	const handleBetPermission = (
		payout: number | null,
		wager: number | null,
		notificaton: NotificationProps,
		authentication: string
	) => {
		if (
			activeBetType === betsConnectionTypes.MULTIPLE &&
			notification.notificationType === '' &&
			wager &&
			wager > 0 &&
			authentication === authorizationTypes.AUTHORIZED
		) {
			return true;
		} else if (
			activeBetType === betsConnectionTypes.SINGLE &&
			notification.notificationType === '' &&
			payout &&
			payout > 0 &&
			authentication === authorizationTypes.AUTHORIZED
		) {
			return true;
		}
		return false;
	};

	const handleSingleBetting = useCallback(async () => {
		setSingleBetLoading(true);
		const singleBets = {
			bets: [] as any,
			acceptBetterRate: false,
			acceptAnyRate: false
		};
		let acceptBetterRate = false;
		let acceptAnyRate = false;
		if (selectValue === '1') {
			acceptAnyRate = true;
		} else if (selectValue === '2') {
			acceptBetterRate = true;
		}
		singleBets.acceptBetterRate = acceptBetterRate;
		singleBets.acceptAnyRate = acceptAnyRate;

		for (const [key, value] of Object.entries(oddsData)) {
			if (inputValues[key]?.amount) {
				const amountString = addThousandSeperator(String(inputValues[key].amount)).replaceAll(
					',',
					''
				);

				const betObject: any = {
					leagueId: value?.leagueId,
					betType: value?.type,
					team: value?.predictedClubType,
					eventId: value?.eventId,
					rate: value?.coefficent,
					amount: +amountString,
					point: value?.point,
					side: value?.side,
					specialId: value?.specialId,
					contestantId: value?.contestantId
				};
				if (value?.periodType !== periodTypes.SPECIAL_PERIOD) {
					// we dont need to add periodNumber if its special period
					betObject.periodNumber = value?.periodType;
				}
				singleBets.bets.push(betObject);
			}
		}
		try {
			await new Promise(resolve => setTimeout(resolve, 2000));

			const betsData = await postSingleBets(singleBets).unwrap();

			const successFullBets: string[] = [];
			betsData?.payload?.acceptedBets.map((value: any) => {
				let periodNumber =
					value?.periodNumber === 0 || value?.periodNumber
						? value?.periodNumber
						: periodTypes.SPECIAL_PERIOD;

				const {status, key} = currentBetStatus(
					oddsData,
					value?.eventId,
					value?.leagueId,
					value?.betType,
					periodNumber,
					value?.contestantId,
					value?.specialId
				);
				if (status && key) {
					successFullBets.push(key);
				}
			});
			setSingleBetStatus(prev => ({
				successfulBets: [...successFullBets],
				failedBets: [...prev.failedBets]
			}));

			if (timeoutId.current) {
				clearTimeout(timeoutId.current); // its for case when user gets error message and then fastly tries to make bet again, the prev timeout is still in progress so we need to clear the prev
			}

			timeoutId.current = setTimeout(() => {
				setSingleBetStatus(prev => {
					if (prev?.successfulBets.length && !prev.failedBets.length) {
						setMobileBetslipOpener(false);
					}
					return {
						successfulBets: [],
						failedBets: [...prev.failedBets]
					};
				});
			}, 2000);
			successFullBets.forEach(key => {
				dispatch(deleteSingleBet({id: key}));
			});
			const failedBets: {id: string; message: string; actualRate: number; amount: number}[] = [];
			betsData.payload.failedBets.map((value: any) => {
				const periodNumber =
					value?.periodNumber === 0 || value?.periodNumber
						? value?.periodNumber
						: periodTypes.SPECIAL_PERIOD;
				const {status, key} = currentBetStatus(
					oddsData,
					value?.eventId,
					value?.leagueId,
					value?.betType,
					periodNumber,
					value?.contestantId,
					value?.specialId
				);
				if (status && key) {
					const errorMessageObj = {
						id: key,
						message: value.message,
						actualRate: value.actualRate,
						amount: inputValues[key]?.amount ?? 0
					};
					if (value?.status === PlaceBetResponseStatusEnum.RATE_CHANGED) {
						errorMessageObj.message = `Odds changed from ${oddConverterToShow(
							value?.rate,
							oddType
						)} to ${oddConverterToShow(value?.actualRate, oddType)}.`;
					}
					failedBets.push(errorMessageObj);
				}
				dispatch(updateSingleBetState({id: key, coefficent: value.actualRate}));
			});
			setSingleBetStatus(prev => ({
				successfulBets: [...prev.successfulBets],
				failedBets: [...failedBets]
			}));
		} catch (error) {
			throw error;
		} finally {
			setSingleBetLoading(false);
		}
	}, [inputValues, selectValue]);

	const handleMultipleBetting = useCallback(async () => {
		setMultipleBetLoading(true);
		const multipleBets = {
			bets: [] as any,
			amount: 0,
			acceptBetterRate: false,
			acceptAnyRate: false
		};
		let acceptBetterRate = false;
		let acceptAnyRate = false;
		if (selectValue === '1') {
			acceptAnyRate = true;
		} else if (selectValue === '2') {
			acceptBetterRate = true;
		}
		multipleBets.acceptBetterRate = acceptBetterRate;
		multipleBets.acceptAnyRate = acceptAnyRate;

		for (const [key, value] of Object.entries(oddsData)) {
			if (value.isLiveOverview) {
			}
			const objectData: any = {
				leagueId: value?.leagueId,
				betType: value?.type,
				team: value?.predictedClubType,
				eventId: value?.eventId,
				rate: value?.coefficent,
				point: value?.point,
				side: value?.side,
				specialId: value?.specialId,
				contestantId: value?.contestantId
			};
			if (value?.type !== 'Special') {
				//if its not special bet, we dont need to add periodNumber
				objectData.periodNumber = value?.periodType;
			}
			multipleBets.bets.push(objectData);
		}

		multipleBets.amount = Number(multipleBetInput) ?? 0;

		try {
			await new Promise(resolve => setTimeout(resolve, 2000));
			const betsData = await postMultipleBets(multipleBets).unwrap();
			if (!betsData?.payload?.failedBets?.length) {
				setMultipleBetsStatus([
					{
						status: StatusEnum.SUCCESS,
						message: t('betAccepted')
					}
				]);
				setTimeout(() => {
					setMultipleBetsStatus([]);
					setMobileBetslipOpener(false);
				}, 2000);
			} else if (!betsData?.payload?.acceptedBets) {
				const failedBets: {id: string; message: string; actualRate: number; status: string}[] = [];
				betsData?.payload?.failedBets.map((value: any) => {
					const periodNumber =
						value?.periodNumber === 0 || value?.periodNumber
							? value?.periodNumber
							: periodTypes.SPECIAL_PERIOD;
					const {status, key} = currentBetStatus(
						oddsData,
						value?.eventId,
						value?.leagueId,
						value?.betType,
						periodNumber,
						value?.contestantId,
						value?.specialId
					);
					if (status && key) {
						const errorMessageObj = {
							id: key,
							message: value.message,
							actualRate: value.actualRate,
							status: StatusEnum.ERROR
						};

						if (value?.status === PlaceBetResponseStatusEnum.RATE_CHANGED) {
							errorMessageObj.message = `Odds changed from ${oddConverterToShow(
								value?.rate,
								oddType
							)} to ${oddConverterToShow(value?.actualRate, oddType)}.`;

							failedBets.push(errorMessageObj);
						}
					}
					dispatch(updateSingleBetState({id: key, coefficent: value.actualRate}));
					setMultipleBetsStatus(failedBets);
				});
			}
		} catch (error) {
			throw error;
		} finally {
			setMultipleBetLoading(false);
		}
	}, [multipleBetInput, selectValue, oddsData, multipleBetsStatus]);

	const handleMakingBet = async () => {
		if (activeBetType === betsConnectionTypes.SINGLE) {
			await handleSingleBetting();
		} else if (activeBetType === betsConnectionTypes.MULTIPLE) {
			await handleMultipleBetting();
		}
		const userCredentialsData = await getUserInfo({}).unwrap();
		const modifiedUserCredentials = setUserCredentials(userCredentialsData);
		dispatch(
			setUser({
				isAuthenticated: authorizationTypes.AUTHORIZED,
				userCredentials: modifiedUserCredentials
			})
		);
	};
	const [isMobiles, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
	}, []);

	const handleInput = (event: any) => {
		let inputValue = event.target.value;
		inputValue = inputValue.replace(/,/g, '.');
		inputValue = inputValue.replace(/[^\d.]/g, '');
		if ((inputValue.match(/\./g) || []).length > 1) {
			console.log('g');
			inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
		}
		event.target.value = inputValue;
	};

	const handleMobileInput = (event: any) => {
		let inputValue = event.target.value;
		if (event.nativeEvent.inputType === 'deleteContentBackward' || inputValue.includes('.')) {
			return;
		}
		inputValue = inputValue.replace(/,/g, ',');
		if ((inputValue.match(/\,/g) || []).length > 1) {
			inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
		}
		event.target.value = inputValue;
	};

	useEffect(() => {
		if (multipleBetsResponse?.success && !multipleBetsResponse.payload.failedBets?.length) {
			dispatch(deleteAllBets());
		}
	}, [multipleBetsResponse]);

	return (
		<div className="betslip-handler-wrapper">
			<div className={`scrollable-betslip ${activeInput ? 'scrollable-betslip__active-numpad' : ''}`}>
				<div className="odds-handle-panel">
					<div className="odds-actions">
						<Select
							defaultValue={localStorage.getItem('oddFormat')}
							bordered={false}
							popupClassName="visible-popup"
							value={selectValue}
							onChange={val => {
								setSelectValue(val);
								localStorage.setItem('oddFormat', val);
							}}
							suffixIcon={
								<AiFillCaretDown
									color={darkMode ? '#0079ff' : 'black'}
									cursor={'pointer'}
									onClick={() => setIsDropdownOpen(!isDropdownOpen)}
									id="odds-action-dropdown-icon"
								/>
							}
							options={[
								{
									value: '1',
									label: <UserSettingsCustomLabel setting={t('acceptAnyOdds')} bordered />
								},
								{
									value: '2',
									label: <UserSettingsCustomLabel setting={t('acceptOnlyHigherOdds')} />
								},
								{
									value: '3',
									label: <UserSettingsCustomLabel setting={t('noOddsChangesAccepted')} />
								}
							]}></Select>
					</div>
					{!isEmpty(oddsData) && (
						<div className="odds-actions__remove">
							<VscTrash className="odds-actions__remove-icon" />
							<span className="odds-actions__remove_text" onClick={handleAllBetsDeletion}>
								{t('removeAll')}
							</span>
						</div>
					)}
				</div>
				{!isEmpty(oddsData) && (
					<div className="bets-type-handler">
						<TypeHandlerButton
							keyProp={betsConnectionTypes.SINGLE}
							isActive={betsConnectionTypes.SINGLE === activeBetType}
							label={t('singles')}
							onClick={handleBetType}
							type={'betting'}
						/>
						<div className="vertical-line-divider"></div>

						<TypeHandlerButton
							keyProp={betsConnectionTypes.MULTIPLE}
							isActive={betsConnectionTypes.MULTIPLE === activeBetType}
							label={t('multiples')}
							onClick={handleBetType}
							tooltipOpen={!multiplePermission}
							tooltipTitle={tooltipContent}
							type={'betting'}
						/>
					</div>
				)}
				{multipleBetsStatus?.[0]?.status === StatusEnum.SUCCESS ||
				singleBetStatus.successfulBets?.length ? (
					<Notification
						notificationLabel={t('betAccepted')}
						notificationType={StatusEnum.SUCCESS}
					/>
				) : (
					<></>
				)}
				{!isEmpty(oddsData) ? (
					<div className="single-bets-wrapper">
						{(singleBetLoading || multipleBetLoading) && (
							<div className={`single-bets-loader`}>
								<img src={CLOCK_LOADER_ICON} />
							</div>
						)}
						{+userCredentials?.balance <
							Object.values(inputValues).reduce(
								(acc, curVal) => acc + (curVal?.amount ?? 0),
								0
							) || +userCredentials?.balance < (Number(multipleBetInput) ?? 0) ? (
							<Notification
								notificationLabel={'Innuicient Funds. Please Deposit.'}
								notificationType={StatusEnum.ERROR}
							/>
						) : null}
						{Object?.entries(oddsData)?.map(([key, value]) => {
							let successBet, errorMessage, actualRate;
							if (activeBetType === betsConnectionTypes.SINGLE) {
								successBet = singleBetStatus.successfulBets.includes(key);
								errorMessage =
									singleBetStatus.failedBets.find(obj => obj.id === key)?.message ?? null;
								actualRate =
									singleBetStatus.failedBets.find(obj => obj.id === key)?.actualRate ??
									null;
							} else if (activeBetType === betsConnectionTypes.MULTIPLE) {
								successBet = undefined;
								errorMessage = multipleBetsStatus?.find(obj => obj.id === key)?.message;
								actualRate = multipleBetsStatus?.find(obj => obj.id === key)?.actualRate;
							}

							return (
								<SingleBetBox
									openNumpad={openNumpad}
									errorMessage={errorMessage}
									successBet={successBet}
									onMaxBetClick={handleOnMaxbetClick}
									marketTitle={value?.marketTitle}
									id={key}
									clubs={value.clubs}
									coefficent={value.coefficent}
									leagueName={value.leagueName}
									predictedClub={value.predictedClub}
									startsAt={value.startsAt}
									type={value.type}
									key={value.predictedClub + value.type + key}
									onInputChange={handleInputChange}
									inputValue={inputValues[key]?.amount}
									handleSingleInputDeletion={handleSingleInputDeletion}
									indicator={value.indicator}
									multiple={activeBetType === betsConnectionTypes.MULTIPLE}
									eventId={value?.eventId}
									parentId={value?.parentId}
									maxBet={
										value?.coefficent < 2
											? value?.maxBet / (value?.coefficent - 1)
											: value?.maxBet
									}
									category={value?.category}
									modifiedCoefficent={oddConverter(value.coefficent, oddType)}
									coefficentToShow={oddConverterToShow(
										value?.actualRate || value?.coefficent,
										oddType
									)}
									leagueId={value?.leagueId}
									actualRate={value.actualRate}
									initialPlacedRate={value?.initialPlacedRate}
									initialPlacedMaxBet={value?.initialPlacedMaxBet}
									isLiveOverview={value?.isLiveOverview}
									isBetOpen={value?.isBetOpen}
								/>
							);
						})}
					</div>
				) : (
					<div className="betslip-handler-wrapper__empty-bets">
						<span className="betslip-handler-wrapper__empty-bets_hint">
							{t('betslipIsEmpty')}
						</span>
						<span className="betslip-handler-wrapper__empty-bets_hint">
							{t('ClickTheOddsToAddABet')}
						</span>
					</div>
				)}
			</div>
			<div>
				<div className="bets-total">
					{activeBetType === betsConnectionTypes.MULTIPLE && !_.every(notification, _.isEmpty) && (
						<Notification
							notificationLabel={notification.notificationLabel}
							notificationType={notification.notificationType}
						/>
					)}
					{activeBetType === betsConnectionTypes.MULTIPLE && (
						<div className="multiple-bets-gaining-counter">
							<Input
								id="numberInput"
								type="text"
								value={multipleBetInput}
								readOnly={isMobile}
								// @ts-ignore
								inputMode={isMobile ? 'decimal' : 'numeric'}
								onInput={isMobile ? handleMobileInput : handleInput}
								onClick={() => {
									openNumpad(betsConnectionTypes.MULTIPLE);
								}}
								onChange={e => {
									handleMultipleBetAmout(
										e.target.value
										// Math.abs(formatNumberWithCommas(e.target.value)) || 0
									);
								}}
								min={0}
								prefix="$"
								status={
									Number(multipleBetInput) && Number(multipleBetInput) > 5000
										? StatusEnum.ERROR
										: undefined
								}
								className="bets-input"
								onKeyDown={e => {
									const allowedKeys = [
										'.',
										',',
										'ArrowRight',
										'Delete',
										'Backspace',
										'Tab'
									];
									if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) {
										e.preventDefault();
									}
								}}
								name="multipleBetInputName"
							/>
							<div className="multiple-bets-text-info">
								<span className="multiple-bets-text-info__title">
									Multiple:{' '}
									<span className="multiple-bets-text-info__bold">{`1's x ${
										Object.keys(oddsData).length
									}`}</span>
								</span>
								{isAuthenticated === authorizationTypes.AUTHORIZED && (
									<span className="multiple-bets-text-info__title">
										{t('maxBet')}:{' '}
										<span
											className="multiple-bets-text-info__underlined"
											onClick={() =>
												handleMultipleBetAmout(multiblePayoutAndWager?.maxBet)
											}>
											${multiblePayoutAndWager?.maxBet?.toFixed(2)}
										</span>
									</span>
								)}
							</div>
						</div>
					)}
					{activeBetType === betsConnectionTypes.MULTIPLE ? (
						<div className="horizontal-line-divider" />
					) : null}
					<div className="bets-total__amount-counter">
						{activeBetType === betsConnectionTypes.MULTIPLE && (
							<div className="bets-total-amount">
								<span className="bets-total-amount__info">{t('totalOdds')}</span>
								<span className="bets-total-amount__sum">
									{multiblePayoutAndWager?.totalOdds &&
										oddConverterToShow(multiblePayoutAndWager?.totalOdds, oddType)}
								</span>
							</div>
						)}
						<div className="bets-total-amount">
							<span className="bets-total-amount__info">{t('totalWager')}</span>
							<span className="bets-total-amount__sum">
								$
								{activeBetType === betsConnectionTypes.MULTIPLE
									? addThousandSeperator(
											Number(multiblePayoutAndWager?.totalWager)?.toFixed(2) || '0.00'
									  )
									: addThousandSeperator(
											Number(totalWagerAndPayout?.totalWager)?.toFixed(2) || '0.00'
									  )}
							</span>
						</div>
						<div className="bets-total-amount">
							<span className="bets-total-amount__info">{t('estPayout')}</span>
							<span className="bets-total-amount__sum">
								$
								{activeBetType === betsConnectionTypes.MULTIPLE
									? addThousandSeperator(
											Number(multiblePayoutAndWager?.payout)?.toFixed(2) || '0.00'
									  )
									: addThousandSeperator(
											Number(totalWagerAndPayout?.payout)?.toFixed(2) || '0.00'
									  )}
							</span>
						</div>
					</div>

					<div className="horizontal-line-divider"></div>
					<Button
						className={`login-hint-button ${
							handleBetPermission(
								totalWagerAndPayout.payout,
								multiblePayoutAndWager.payout,
								notification,
								isAuthenticated
							)
								? 'login-hint-button__active'
								: ''
						}`}
						onClick={() => {
							isAuthenticated === authorizationTypes.AUTHORIZED &&
							handleBetPermission(
								totalWagerAndPayout.payout,
								multiblePayoutAndWager.payout,
								notification,
								isAuthenticated
							)
								? handleMakingBet()
								: isAuthenticated !== authorizationTypes.AUTHORIZED && showModal();
						}}
						disabled={
							singleBetLoading ||
							multipleBetLoading ||
							!!singleBetStatus.successfulBets.length ||
							multipleBetsStatus?.[0]?.status === StatusEnum.SUCCESS
						}>
						<div className="login-hint-button__messages">
							{!singleBetLoading && (
								<span className="login-hint-button__message__bold">
									{isAuthenticated === authorizationTypes.AUTHORIZED
										? `${t('placeBets')}
										  `
										: t('logInToPlaceBet')}
								</span>
							)}
							{singleBetLoading && (
								<span className="login-hint-button__message__bold">PLACING YOUR BET</span>
							)}
						</div>
					</Button>
				</div>
				{isMobiles && activeInput?.id && (
					<div
						className={`custom-numpad-bottom ${
							true ? 'custom-numpad-bottom__active' : 'custom-numpad-bottom__hidden'
						}`}>
						<CustomNumpad closeNumpad={closeNumpad} setInputValue={handleMobileInputChange} />
					</div>
				)}
			</div>
			<Popup
				open={isModalOpen}
				onClose={handleClose}
				headerText={
					modalType === modalTypes.RESET_PASSWORD
						? 'RESET PASSWORD'
						: modalType === modalTypes.SIGN_UP
						? t('join')
						: t('logIn')
				}>
				{modalType === modalTypes.RESET_PASSWORD ? (
					<ResetPasswordForm handleModalClose={handleClose} />
				) : modalType === modalTypes.SIGN_UP ? (
					<SignInForm
						onClick={() => setModalType(modalTypes.LOGIN)}
						handleModalClose={handleClose}
						handleModalType={handleModalType}
					/>
				) : (
					<LoginForm
						onClick={() => setModalType(modalTypes.SIGN_UP)}
						handleModalClose={handleClose}
						handleModalType={handleModalType}
					/>
				)}
			</Popup>
		</div>
	);
};

export default OddsHandlePanel;
