import {createSlice} from '@reduxjs/toolkit';
import {betCloseStatus, liveIdentificator, specialBetCloseStatus} from 'utils/types';

const createBetSlice = createSlice({
	name: 'betSlice',
	initialState: JSON.parse(localStorage.getItem('currentBetslip') || '{}'),
	reducers: {
		createBet(state, action) {
			const betslip = {
				...state,
				[`${action.payload.eventId} - ${action.payload.type} - ${action.payload.descriptionAddition} - ${action.payload.marketTitle}`]:
					{
						clubs: action.payload.betData,
						predictedClub: action.payload.predictedClub,
						coefficent: action.payload.coefficent,
						type: action.payload.type,
						startsAt: action.payload.startsAt,
						leagueName: action.payload.leagueName,
						maxBets: action.payload.maxBets,
						indicator: action.payload.indicator,
						periodType: action.payload.periodType,
						descriptionAddition: action.payload.descriptionAddition,
						eventId: action.payload.eventId,
						parentId: action.payload.parentId,
						maxBet: action.payload.maxBet,
						specialName: action.payload.specialName,
						marketTitle: action.payload.marketTitle,
						category: action.payload.category,
						leagueId: action.payload.leagueId,
						futuresId: action.payload.futuresId,
						point: action.payload.point,
						predictedClubType: action.payload.predictedClubType,
						side: action.payload.side,
						specialId: action.payload.specialId,
						contestantId: action.payload.contestantId,
						initialPlacedRate: action.payload.initialPlacedRate,
						initialPlacedMaxBet: action.payload.initialPlacedMaxBet,
						homeTeamType: action.payload.homeTeamType,
						isLiveOverview: action.payload.isLiveOverview,
						isBetOpen: action.payload.isBetOpen
					}
			};

			localStorage.setItem('currentBetslip', JSON.stringify(betslip));

			return betslip;
		},
		deleteAllBets() {
			localStorage.removeItem('currentBetslip');
			return {};
		},
		deleteSingleBet(state, action) {
			const newState = {...state};
			delete newState[action.payload.id as keyof typeof state];
			localStorage.setItem('currentBetslip', JSON.stringify(newState));
			return newState;
		},
		updateSingleBetState(state, action) {
			return {
				...state,
				[action.payload.id]: {
					...state[action.payload.id],
					coefficent: action.payload.coefficent,
					actualRate: action.payload.coefficent
				}
			};
		},
		updateChangedRatesAndCoefficents(state, action) {
			const updatedBetslipGames = [...action.payload.actualRates];
			const newState = state;
			updatedBetslipGames.forEach((game: any) => {
				const currentDate = new Date();
				const cutoffDate = new Date(game?.cutoff);
				const isBetOpen =
					currentDate < cutoffDate &&
					game?.status !== betCloseStatus &&
					game?.status !== specialBetCloseStatus &&
					!!newState[game.key].coefficent;
				newState[game.key].coefficent = game.actualRate;
				newState[game.key].maxBet = game.actualMaxBet;
				newState[game.key].actualRate = game.actualRate;
				newState[game.key].isBetOpen = isBetOpen;
				newState[game.key].isLiveOverview = game?.liveStatus === liveIdentificator;
			});
			return newState;
		}
	}
});

export const {
	createBet,
	deleteAllBets,
	deleteSingleBet,
	updateSingleBetState,
	updateChangedRatesAndCoefficents
} = createBetSlice.actions;
export default createBetSlice.reducer;
