import {useDispatch} from 'react-redux';
import {useLazyGetLeaguesQuery} from 'store/services/sportsAPI';
import SVGComponent from 'utils/helpers/SVGComponent';
import './../EventSwiper.less';

interface SliderItemProps {
	sportName: string;
	icon: string;
	id: string;
	isActive: boolean;
	onClick: (id: string) => void;
}

const SliderItem = ({sportName, icon, id, isActive, onClick}: SliderItemProps) => {
	const [getLeaguesById, {data}] = useLazyGetLeaguesQuery();

	const handleOnClick = () => {
		onClick(id);
	};

	return (
		<div
			className={`slider-item-wrapper ${isActive ? 'slider-item-wrapper__active' : ''}`}
			onClick={handleOnClick}>
			<SVGComponent path={icon} height={25} width={25} />
			<span className={`slider-item-wrapper__sportname`}>{sportName}</span>
		</div>
	);
};

export default SliderItem;
