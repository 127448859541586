import {createSlice} from '@reduxjs/toolkit';
import {oddTypes} from 'utils/types';

const oddsTypeSlice = createSlice({
	name: 'oddsTypeSlice',
	initialState: localStorage.getItem('oddType') ?? oddTypes.DECIMAL,
	reducers: {
		setCurrentOddType(_, action) {
			localStorage.setItem('oddType', action.payload);
			return action.payload;
		}
	}
});

export const {setCurrentOddType} = oddsTypeSlice.actions;
export default oddsTypeSlice.reducer;
