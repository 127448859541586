import {useSelector} from 'react-redux';
import {RootState} from 'store';
import {authorizationTypes, breakpoint} from 'utils/types';
import './Header.less';
import HeaderDown from './components/HeaderDown';
import HeaderLogo from './components/HeaderLogo';
import UserBalance from './components/UserBalance';
import UserLogin from './components/UserLogin';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {IoClose} from 'react-icons/io5';
import {AiOutlineLeft} from 'react-icons/ai';
import {useLocation, useNavigate, useParams} from 'react-router-dom';
import {useTranslation} from 'react-i18next';
import {Divider} from 'antd';

const Header = () => {
	const darkMode = useSelector((state: RootState) => state.darkMode);
	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);
	const navigate = useNavigate();
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.MOBILE;

	const {t} = useTranslation();

	const mobileHeaderTitle = {
		history: 'HISTORY',
		transactions: 'TRANSACTIONS',
		details: t('personalDetails'),
		security: 'SECURITY',
		verification: t('verification'),
		inbox: t('inbox')
	};

	const headerTextKey = Object.keys(mobileHeaderTitle).find(i => location.pathname.includes(i));

	const headerTitle = headerTextKey
		? mobileHeaderTitle[headerTextKey as keyof typeof mobileHeaderTitle]
		: t('myAccount');

	return !(isMobile && location.pathname.includes('/user-bets')) ? (
		<div className={`header`} style={{boxShadow: '0 1px 7px black !important'}}>
			<div className="header__up-part">
				<HeaderLogo />
				{isAuthenticated === authorizationTypes.AUTHORIZED ? (
					<UserBalance userBalance={+userCredentials?.balance} />
				) : isAuthenticated === authorizationTypes.UNAUTHORIZED ? (
					<UserLogin />
				) : null}
			</div>
			<Divider className="header-full-page-gradient-divider" />
			<HeaderDown />
		</div>
	) : (
		<div className="header header-mobile">
			<div className="mobile-header-wrapper">
				{!location.pathname.includes('mobile-initial') ? (
					<AiOutlineLeft color="#0079ff" onClick={() => navigate(-1)} />
				) : (
					<div></div>
				)}
				<span className="mobile-header-title">{headerTitle}</span>

				<IoClose color="#0079ff" className="mobile-header-close-btn" onClick={() => navigate(-1)} />
			</div>
		</div>
	);
};

export default Header;
