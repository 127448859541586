import {Button, Form, Input} from 'antd';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useSearchParams} from 'react-router-dom';
import {toast} from 'react-toastify';
import {useResetPasswordMutation} from 'store/services/authAPI';

interface ResetPasswordProps {
	handleModalClose: () => void;
}

export interface ResetPasswordFormProps {
	rePassword: string;
	password: string;
	id: string;
	q: string;
}

const ResetPassword = ({handleModalClose}: ResetPasswordProps) => {
	const [resetPassword, {isLoading, isSuccess, isError, error, data}] = useResetPasswordMutation();
	const {t} = useTranslation();

	const [searchParam] = useSearchParams();

	const userId = searchParam.get('id');
	const token = searchParam.get('q');

	const onFinish = async (values: ResetPasswordFormProps) => {
		try {
			const response = await resetPassword({...values, token, userId}).unwrap();
			handleModalClose();
		} catch (error) {
			(error as any)?.data?.message?.forEach((i: string) => toast.error(i));
		}
	};

	return (
		<div className="form-wrapper">
			<Form
				name="basic"
				style={{maxWidth: 600}}
				initialValues={{remember: true}}
				layout="vertical"
				onFinish={onFinish}>
				<div className="field-row">
					<Form.Item
						label={t('password')}
						name="password"
						rules={[{required: true, message: 'Required'}]}>
						<Input type="password" />
					</Form.Item>
				</div>

				<div className="user-password field-row">
					<Form.Item
						label={'Confirm Password'}
						name="rePassword"
						rules={[
							{
								required: true,
								message: 'Please confirm your password!'
							},
							({getFieldValue}) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error('The two passwords that you entered do not match!')
									);
								}
							})
						]}>
						<Input type="password" autoComplete="passwordd" />
					</Form.Item>
				</div>

				<Form.Item wrapperCol={{offset: 8, span: 16}} className="submit-button">
					<Button type="primary" htmlType="submit" style={{width: '100%'}}>
						<span className="submit-button__btn">{t('logIn')}</span>
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default ResetPassword;
