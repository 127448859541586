import AGE_WARNING from 'assets/logos/AGE_WARNING.svg';
import GAMBLE_WARNING from 'assets/logos/GAMBLE_WARNING.svg';
import GAMCARE from 'assets/logos/GAMCARE.svg';
import BITCOIN_LOGO_DARKMODE from 'assets/logos/bitcoin-dark.svg';
import BITCOIN_LOGO_LIGHTMODE from 'assets/logos/bitcoin-light.svg';
import TWITTER from 'assets/icons/twitter.svg';
import TELEGRAM from 'assets/icons/telegram.svg';

import {useSelector} from 'react-redux';
import {RootState} from 'store';
import './Footer.less';
import {CONDITIONS_PAGE_PATHNAME, breakpoint, themeTypes} from 'utils/types';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import DarkModeHandler from '../Header/components/DarkModeHandler';
import LanguageSelector from 'utils/i18next/LanguageSelector';
import OddsTypeSelector from '../Header/components/OddsTypeSelector';
import {Divider} from 'antd';
import {GrBitcoin} from 'react-icons/gr';
import BITCOIN_WITH_ICON from 'assets/logos/bitcoinWithIcon.svg';
import {useTranslation} from 'react-i18next';
import {Link} from 'react-router-dom';

const Footer = () => {
	const {t} = useTranslation();

	const darkMode = useSelector((state: RootState) => state.darkMode) as keyof typeof themeTypes;
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	return !isMobile ? (
		<div className={`footer`}>
			<div className="footer__head">
				<div>
					<span className={`footer__head_hint `}>{t('contactUs')}</span>
					<a
						href={CONDITIONS_PAGE_PATHNAME}
						target="_blank"
						rel="noopener noreferrer"
						className={`footer__head_hint `}>
						{t('sportRules')}
					</a>
					<a
						href={`${CONDITIONS_PAGE_PATHNAME}#terms`}
						target="_blank"
						rel="noopener noreferrer"
						className={`footer__head_hint `}>
						{t('termsAndConditions')}
					</a>
				</div>
				<div style={{display: 'flex', gap: 5}}>
					<a href="https://x.com/tangerbet">
						<img src={TWITTER} width={'20px'} height={'20px'} />
					</a>
					<a href="https://t.me/tangerbet">
						<img src={TELEGRAM} width={'20px'} height={'20px'} />
					</a>
				</div>
			</div>
			<div className="gaming-warning">
				<span className={`gaming-warning__hint`}>{t('gamblingCanBeAddictive')}</span>
				<span className={`gaming-warning__hint`}>{t('pleaseGambleResponsibly')}</span>
				<div className="footer-images-wrapper">
					<img src={GAMCARE} alt="img" />
					<img src={GAMBLE_WARNING} alt="img" />
					<img src={AGE_WARNING} alt="img" />
				</div>
			</div>
			<div className="privacy-policy">
				<img
					src={darkMode ? BITCOIN_LOGO_DARKMODE : BITCOIN_LOGO_LIGHTMODE}
					alt="img"
					className="bitcoin-logo"
				/>
				<div className="horizontal-divider-line"></div>
				<div className="privacy-policy__info">
					<span>© 2008-2024 Tangerbet. All Rights Reserved</span>
				</div>
			</div>
		</div>
	) : (
		<div className="footer mobile-footer">
			<Divider
				className="footer-horizontal-divider footer-horizontal-divider__small-gap"
				style={{marginTop: 15}}
			/>
			<div className="handler-links-wrapper">
				<div className="page-modes-handler">
					<div className="page-mode-handler-label-wrapper">
						{!location.pathname.includes('user-bets') && (
							<span className="footter-selector-label">{t('displayMode')}: </span>
						)}
						<span className="footter-selector-label">{t('language')}: </span>
						<span className="footter-selector-label">{t('oddsFormat')}: </span>
					</div>
					<div className="mode-handlers-wrapper">
						<DarkModeHandler />
						<LanguageSelector />
						<OddsTypeSelector darkMode={darkMode} />
					</div>
				</div>
				<Divider type="vertical" className="footer-vertical-divider" />
				<div className="footer-links-wrapper">
					<span className={`mobile-footer-links`}>{t('contactUs')}</span>
					<a
						href={CONDITIONS_PAGE_PATHNAME}
						// target="_blank"
						onClick={() => localStorage.setItem('termsAndConditions', 'true')}
						rel="noopener noreferrer"
						className={`mobile-footer-links`}>
						{t('sportRules')}{' '}
					</a>
					<a
						id="terms"
						href={`${CONDITIONS_PAGE_PATHNAME}#terms`}
						// target="_blank"
						onClick={() => localStorage.setItem('termsAndConditions', 'true')}
						rel="noopener noreferrer"
						className={`mobile-footer-links`}>
						{t('termsAndConditions')}{' '}
					</a>
				</div>
			</div>
			<div style={{display: 'flex', gap: 5, justifyContent: 'flex-end'}}>
				<a href="https://x.com/tangerbet">
					<img src={TWITTER} width={'20px'} height={'20px'} />
				</a>
				<a href="https://t.me/tangerbet">
					<img src={TELEGRAM} width={'20px'} height={'20px'} />
				</a>
			</div>
			<Divider className="footer-horizontal-divider footer-horizontal-divider__small-gap" />
			<div className="hint-logos-wrapper">
				<div className="footer-mobile-gambling-hints-wrapper">
					<span className="mobile-footer-gambling-hint">{t('gamblingCanBeAddictive')}</span>
					<span className="mobile-footer-gambling-hint">{t('pleaseGambleResponsibly')}</span>
				</div>
				<div className="mobile-footer-logos-wrapper">
					<img src={GAMCARE} alt="gamcare" />
					<img src={GAMBLE_WARNING} alt="gamble-warning" />
					<img src={AGE_WARNING} alt="age-warning" />
				</div>
			</div>
			<div className="mobile-footer-privacy-policy">
				<img src={BITCOIN_WITH_ICON} alt="bitcoin" />
				<Divider
					style={{width: '60%'}}
					className="footer-horizontal-divider footer-horizontal-divider__small-gap"
				/>
				<div className="mobile-footer-privacy-policy-text-wrapper">
					<span>© 2008-2024 Tangerbet. All Rights Reserved</span>
				</div>{' '}
				<div className="mobile-footer-privacy-policy-text-wrapper">
					<span>Version 2.5</span>
				</div>
			</div>
		</div>
	);
};

export default Footer;
