export function addThousandSeperator(input: string): string {
	if (!input) {
		return '0.00';
	}

	const parts = input?.split('.');
	const integerPart = parts[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, ',');

	if (parts.length === 1) {
		return `${integerPart}.00`;
	}

	const decimalPart = parts[1].substring(0, 2).padEnd(2, '0');

	return `${integerPart}.${decimalPart}`;
}
