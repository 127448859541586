import {createSlice} from '@reduxjs/toolkit';
import {themeTypes} from 'utils/types';

const darkModeSlice = createSlice({
	name: 'darkMode',
	initialState: localStorage.getItem('theme') || themeTypes.darkMode,
	reducers: {
		toggleDarkMode(state, action) {
			const newTheme = action.payload.theme;
			localStorage.setItem('theme', newTheme);
			return newTheme;
		}
	}
});

export const {toggleDarkMode} = darkModeSlice.actions;
export default darkModeSlice.reducer;
