import {Button, Form, Input} from 'antd';
import LoginForm from 'components/Popup/LoginForm';
import Popup from 'components/Popup/Popup';
import ResetPasswordForm from 'components/Popup/ResetPasswordForm';
import SignInForm from 'components/Popup/SignInForm';
import React, {ChangeEvent, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {RootState} from 'store';
import {useLazyGetUserInfoQuery, useLogInMutation} from 'store/services/authAPI';
import {setUser, setUserCredentials} from 'store/slices/authSlice';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {authorizationTypes, breakpoint, modalTypes} from 'utils/types';
import '../Header.less';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';

interface LoginResponse {
	data: {success: boolean};
}

const UserLogin: React.FC = () => {
	const [userLogin, setUserLogin] = useState({
		email: '',
		password: ''
	});
	const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
	const [modalType, setModalType] = useState<string>();
	const [logIn, {isError, error: loginError, isLoading: loginLoading}] = useLogInMutation();
	const dispatch = useDispatch();
	const [getUserInfo, {data}] = useLazyGetUserInfoQuery();
	const {isAuthenticated} = useSelector((state: RootState) => state.userAuth);

	const {t} = useTranslation();

	const darkMode = useSelector((state: RootState) => state.darkMode);
	const screenWidth = useScreenWidth();

	const handleOnChange = (e: ChangeEvent<HTMLInputElement>) => {
		setUserLogin(prev => ({
			...prev,
			[e.target.name]: e.target.value
		}));
	};

	const showModal = () => {
		setIsModalOpen(true);
	};

	const handleClose = () => {
		setIsModalOpen(false);
	};

	const handlePopupContent = (type: string) => {
		setModalType(type);
	};

	const handleModalType = () => {
		setModalType(modalTypes.RESET_PASSWORD);
	};
	const fetchData = async () => {
		try {
			const userCredentialsData = await getUserInfo({}).unwrap();
			const modifiedUserCredentials = setUserCredentials(userCredentialsData);
			dispatch(
				setUser({
					isAuthenticated: authorizationTypes.AUTHORIZED,
					userCredentials: modifiedUserCredentials
				})
			);
		} catch (e) {
			dispatch(setUser({isAuthenticated: authorizationTypes.UNAUTHORIZED, userCredentials: null}));
		}
	};

	const handleSubmit = async () => {
		try {
			const response = await logIn(userLogin).unwrap();
			const modifiedUserCredentials = setUserCredentials(response);
			if (response.success) {
				dispatch(
					setUser({
						isAuthenticated: authorizationTypes.AUTHORIZED,
						userCredentials: modifiedUserCredentials
					})
				);
				const intervalId = setInterval(() => {
					!location.pathname.includes('/user-bets/details') && fetchData();
				}, 10000);
				return () => {
					clearInterval(intervalId);
				};
			}
		} catch (error: any) {
			console.log(error);
		}
	};

	useEffect(() => {
		const authInDev = async () => {
			if (isAuthenticated !== authorizationTypes.AUTHORIZED && process.env.NODE_ENV === 'development') {
				const response = await logIn({email: 'user@test.com', password: '1234567X.x'}).unwrap();
				const modifiedUserCredentials = setUserCredentials(response);
				if (response.success) {
					dispatch(
						setUser({
							isAuthenticated: authorizationTypes.AUTHORIZED,
							userCredentials: modifiedUserCredentials
						})
					);
				}
			}
		};

		// authInDev();
	}, []);

	// useEffect(() => {
	// 	const keyDownHandler = (event: any) => {
	// 		if (event.key === 'Enter' && isAuthenticated === authorizationTypes.UNAUTHORIZED) {
	// 			event.preventDefault();
	// 			handleSubmit();
	// 		}
	// 	};

	// 	document.addEventListener('keydown', keyDownHandler);

	// 	return () => {
	// 		document.removeEventListener('keydown', keyDownHandler);
	// 	};
	// }, [userLogin, isAuthenticated]);

	useEffect(() => {
		if (!isModalOpen) {
			setModalType(undefined);
		}
	}, [isModalOpen]);

	return (
		<>
			<Form
				className="user-login-header-form"
				name="basic"
				style={{maxWidth: 600, lineHeight: 1}}
				initialValues={{remember: true}}
				onFinish={handleSubmit}
				layout="horizontal">
				{screenWidth > breakpoint.MOBILE && (
					<div className="login-inputs-hints-wrapper">
						<div className="login-inputs-wrapper">
							<div className="user-login-header">
								<Form.Item name="email" rules={[{required: true, message: 'Required'}]}>
									<Input
										name="email"
										type="email"
										placeholder={t('email') || 'Email'}
										status={isError ? 'error' : undefined}
										onChange={handleOnChange}
										className="user-login-input"
									/>
								</Form.Item>
							</div>

							<div className="user-password user-login-header">
								<Form.Item name="password" rules={[{required: true, message: 'Required'}]}>
									<Input
										name="password"
										type="password"
										autoComplete="passwordd"
										placeholder={t('password') || 'password'}
										status={isError ? 'error' : undefined}
										onChange={handleOnChange}
										className="user-login-input"
									/>
								</Form.Item>
							</div>
						</div>

						<div className="hints-wrapper">
							<span className="forgot-password-hint">{t('forgot')} </span>
							<span
								className="forgot-password-hint forgot-password-hint__underline"
								onClick={() => {
									showModal();
									setModalType(modalTypes.RESET_PASSWORD);
								}}>
								{t('password')}{' '}
							</span>
						</div>
					</div>
				)}

				<div className="buttons-wrapper">
					<Button
						htmlType={screenWidth > breakpoint.MOBILE ? 'submit' : undefined}
						className={darkMode ? 'ant-btn-default__darkmode' : 'ant-btn-default__lightmode'}
						onClick={() => {
							if (screenWidth <= breakpoint.MOBILE) {
								showModal();
								setModalType(modalTypes.LOGIN);
							}
						}}
						disabled={loginLoading}>
						{t('logIn')}
					</Button>
					<Button
						type="primary"
						onClick={() => {
							showModal();
							setModalType(modalTypes.REGISTRATION);
						}}
						className={darkMode ? 'ant-btn-primary__darkmode' : 'ant-btn-primary__lightmode'}>
						{t('join')}
					</Button>
				</div>
			</Form>

			<Popup
				type="fullscreen"
				open={isModalOpen}
				onClose={handleClose}
				headerText={
					modalType === modalTypes.RESET_PASSWORD
						? 'RESET PASSWORD'
						: modalType === modalTypes.REGISTRATION
						? t('join')
						: t('logIn')
				}>
				{modalType === modalTypes.RESET_PASSWORD ? (
					<ResetPasswordForm handleModalClose={handleClose} />
				) : modalType === modalTypes.REGISTRATION ? (
					<SignInForm
						onClick={() => handlePopupContent(modalTypes.LOGIN)}
						handleModalClose={handleClose}
						handleModalType={handleModalType}
					/>
				) : (
					<LoginForm
						onClick={() => handlePopupContent(modalTypes.REGISTRATION)}
						handleModalClose={handleClose}
						handleModalType={handleModalType}
					/>
				)}
			</Popup>
		</>
	);
};

export default UserLogin;
