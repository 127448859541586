import './../LiveOverview.less';

interface MatchCountProps {
	eachPeriodScore?: {club1: number | null; club2: number | null; periodNumber: number}[];
	finalResult?: {club1: number | null; club2: number | null};
}

const MatchCount = ({eachPeriodScore, finalResult}: MatchCountProps) => {
	return (
		<div className="match-count-wrapper">
			{/* {eachPeriodScore?.map((value, index) => {
				return (
					<div className="counter-row" key={`game-score-${index}-${value?.club1}-${value?.club2}`}>
						<span className="match-counter match-counter__ordinar">{value?.club1 || '-'}</span>
						<span className="match-counter match-counter__ordinar">{value?.club2 || '-'}</span>
					</div>
				);
			})} */}
			<div className="counter-row">
				{typeof finalResult?.club1 === 'number' && (
					<span className="match-counter match-counter__primary">{finalResult?.club1}</span>
				)}
				{typeof finalResult?.club2 === 'number' && (
					<span className="match-counter match-counter__primary">{finalResult?.club2}</span>
				)}
			</div>
		</div>
	);
};

export default MatchCount;
