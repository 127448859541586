/* eslint-disable react/no-string-refs */
import React, {useEffect} from 'react';
import {API_PREFIX} from 'store/services/api';
import {Link} from 'react-router-dom';
import {Divider} from 'antd';

const Banner = () => {
	const bannerImage = localStorage.getItem('bannerImage');
	const bannerLink = localStorage.getItem('bannerLink');
	const path = API_PREFIX + '/api/public/' + (bannerImage ? bannerImage : '');

	return bannerImage && bannerLink ? (
		<div
			style={
				{
					// maxHeight: 140
				}
			}>
			<Divider className="horizontal-line-divider" style={{margin: '10px 0px'}} />
			<Link to={bannerLink || ''} target="_blank">
				<img
					src={path}
					alt="banner"
					crossOrigin="anonymous"
					style={{
						maxHeight: 140,
						maxWidth: 300,
						borderRadius: 2
					}}
				/>
			</Link>
		</div>
	) : (
		<div></div>
	);
};

export default Banner;
