import {useEffect, useState} from 'react';

const useScreenWidth = (): number => {
	const [screenWidth, setScreenWidth] = useState(window.outerWidth);

	useEffect(() => {
		const handleResize = () => {
			setScreenWidth(window.outerWidth);
		};

		window.addEventListener('resize', handleResize);

		return () => {
			window.removeEventListener('resize', handleResize);
		};
	}, []);

	return screenWidth;
};

export default useScreenWidth;
