import {Badge, Dropdown, MenuProps} from 'antd';
import AMD from 'assets/icons/amd.svg';
import BTC from 'assets/icons/btc.svg';
import ETH from 'assets/icons/eth.svg';
import LTC from 'assets/icons/ltc.svg';
import RUB from 'assets/icons/rub.svg';
import USD from 'assets/icons/usd.svg';
import DepositContent from 'components/Popup/DepositContent';
import Popup from 'components/Popup/Popup';
import WithdrawContent from 'components/Popup/WithdrawContent';
import React, {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {CiLogin} from 'react-icons/ci';
import {HiEnvelope} from 'react-icons/hi2';
import {MdPerson} from 'react-icons/md';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {toast} from 'react-toastify';
import {RootState} from 'store';
import {useLazyGetUnreadMessagesQuery, useLazyInboxQuery, useLogOutMutation} from 'store/services/authAPI';
import {setUser} from 'store/slices/authSlice';
import {addThousandSeperator} from 'utils/helpers/addThousandSeperator';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {UserCredentialProps} from 'utils/interfaces';
import {CurrencyEnum, authorizationTypes, betModalTypes, breakpoint} from 'utils/types';
import './../Header.less';

interface UserBalanceProps {
	userBalance: number;
}

interface CustomLabelProps {
	amount: number;
	currency: string;
	currencyLogo: any;
}

export interface UserSettingsDropdownProps {
	setting: string;
	bordered?: boolean;
	prefixIcon?: boolean;
	onClick?: () => void;
}

const CustomLabel = ({amount, currency, currencyLogo}: CustomLabelProps) => {
	return (
		<div className="custom-dropdown-label">
			<span className="custom-dropdown-label__amount">{amount}</span>
			<div className="currency-wrapper">
				<img className="currency-wrapper__logo" src={currencyLogo} alt="currency" />
				<span className="currency-wrapper__currencyy">{currency}</span>
			</div>
		</div>
	);
};

const UserSettingsCustomLabel = ({setting, bordered, prefixIcon, onClick}: UserSettingsDropdownProps) => {
	return (
		<div
			onClick={onClick ? onClick : () => {}}
			className={`custom-dropdown-user-settings ${
				bordered ? 'custom-dropdown-user-settings__bordered' : ''
			}`}>
			<span>{setting}</span>
			{prefixIcon && <CiLogin fontSize={15} />}
		</div>
	);
};

const items: MenuProps['items'] = [
	{
		key: '1',
		label: <CustomLabel amount={4520} currency="USD" currencyLogo={BTC} />
	},
	{
		key: '2',
		label: <CustomLabel amount={4520} currency="USD" currencyLogo={RUB} />
	},
	{
		key: '3',
		label: <CustomLabel amount={4520} currency="USD" currencyLogo={ETH} />
	},
	{
		key: '4',
		label: <CustomLabel amount={4520} currency="USD" currencyLogo={LTC} />
	},
	{
		key: '5',
		label: <CustomLabel amount={4520} currency="USD" currencyLogo={AMD} />
	},
	{
		key: '6',
		label: <CustomLabel amount={4520} currency="USD" currencyLogo={USD} />
	}
	// {
	// 	key: '8',
	// 	label: <div className="dropdown-settings">Settings</div>
	// }
];

const UserBalance = ({userBalance}: UserBalanceProps) => {
	const darkMode = useSelector((state: RootState) => state.darkMode);
	const [logOut, {}] = useLogOutMutation();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [getInbox] = useLazyInboxQuery();
	const [getUnreadMessages] = useLazyGetUnreadMessagesQuery();
	const [messagesCount, setMessagesCount] = useState<number>(0);
	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);

	const showBalanceState = useSelector((state: RootState) => state.showBalanceSlice.showBalance);

	const {t} = useTranslation();

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth <= breakpoint.DESKTOP;
	const [showSuccess, setShowSuccess] = useState<boolean>(false);
	const [modalInfo, setModalInfo] = useState<{open: boolean; type: string; component?: React.ReactNode}>({
		open: false,
		type: betModalTypes.INITIAL_STATE
	});

	const handleLogout = async () => {
		dispatch(setUser({isAuthenticated: authorizationTypes.UNAUTHORIZED, userCredentials: null}));
		try {
			navigate('/');
			await logOut(undefined).unwrap();
			toast.success('Successfully logged  out');
		} catch (e) {
			console.error(e);
		}
	};

	const handleNavigation = (route: string) => {
		navigate(route);
	};

	const handleModalClose = () => {
		setModalInfo(() => ({
			type: betModalTypes.INITIAL_STATE,
			open: false
		}));
	};

	const handleModalOpen = (type: string, component: React.ReactNode) => {
		setModalInfo(() => ({
			open: true,
			type: type,
			component
		}));
	};

	const userCurrency = useMemo(() => {
		let image;
		// if (userCredentials.currency === CurrencyEnum.USD) {
		image = require('assets/icons/usd.svg').default;
		// } else if (userCredentials.currency === CurrencyEnum.RUB) {
		// 	image = require('assets/icons/rub.svg').default;
		// } else if (userCredentials.currency === CurrencyEnum.AMD) {
		// 	image = require('assets/icons/amd.svg').default;
		// } else if (userCredentials.currency === CurrencyEnum.ETH) {
		// 	image = require('assets/icons/eth.svg').default;
		// } else if (userCredentials.currency === CurrencyEnum.BTC) {
		// 	image = require('assets/icons/btc.svg').default;
		// } else if (userCredentials.currency === CurrencyEnum.LTC) {
		// 	image = require('assets/icons/ltc.svg').default;
		// }
		return image;
	}, []);

	const userSettingItems: MenuProps['items'] = [
		{
			key: '1',
			label: (
				<UserSettingsCustomLabel
					setting={(userCredentials as UserCredentialProps).clientId}
					bordered
				/>
			)
		},
		{
			key: '2',
			label: (
				<UserSettingsCustomLabel
					setting={t('myAccount')}
					onClick={() =>
						!isMobile
							? handleNavigation('/user-bets/details')
							: handleNavigation('/user-bets/mobile-initial')
					}
				/>
			)
		},
		{
			key: '3',
			label: (
				<UserSettingsCustomLabel
					setting={t('betsHistory')}
					onClick={() => handleNavigation('/user-bets/history')}
				/>
			)
		},
		{
			key: '4',
			label: (
				<UserSettingsCustomLabel
					setting={t('deposit')}
					onClick={() => handleNavigation('/user-bets/deposit')}
					// onClick={() =>
					// 	handleModalOpen(
					// 		betModalTypes.DEPOSIT,
					// 		<DepositContent setShowSuccess={setShowSuccess} />
					// 	)
					// }
				/>
			)
		},
		{
			key: '5',
			label: (
				<UserSettingsCustomLabel
					setting={t('withdraw')}
					onClick={() => handleNavigation('/user-bets/withdraw')}
					bordered
					// onClick={() =>
					// 	handleModalOpen(
					// 		betModalTypes.WITHDRAW,
					// 		<WithdrawContent setShowSuccess={setShowSuccess} />
					// 	)
					// }
				/>
			)
		},
		{
			key: '6',
			label: <UserSettingsCustomLabel setting={t('logOut')} prefixIcon onClick={handleLogout} />
		}
	];

	const getUnreadMessage = async () => {
		const messages = await getUnreadMessages({});
		setMessagesCount(messages.data.payload.length);
	};
	useEffect(() => {
		getUnreadMessage();
	}, []);

	useEffect(() => {
		const intervalId = setInterval(() => {
			getUnreadMessage();
		}, 5000);
		return () => {
			clearInterval(intervalId);
		};
	}, []);

	return (
		<div className="user-balance-wrapper">
			{/* <Dropdown
				menu={{items}}
				trigger={['click']}
				overlayClassName="user-balance-dropdown"
				placement="bottom"
				arrow> */}
			<div className="balance-amount">
				<span className="balance-amount__title">{t('balance')}</span>
				<div className="balance-amount__sheet">
					<img src={userCurrency} alt="currency" />
					{showBalanceState ? (
						<div className="balance-amount__number">
							{addThousandSeperator(userBalance.toString())}
						</div>
					) : (
						<span style={{paddingTop: 5, color: 'white'}}>*****</span>
					)}
				</div>
			</div>
			{/* </Dropdown> */}
			<div className="icons-wrapper">
				{!isMobile && (
					<>
						{' '}
						<div className="vertical-line-divider"></div>
						<div
							onClick={() => navigate('/user-bets/deposit')}
							className="balance-icon-wrapper balance-icon-wrapper__bordered"
							style={{
								background: '#0D8F0E',
								borderRadius: 2,
								filter: 'drop-shadow(0px 0px 1px rgba(0, 0, 0, 0.4))',
								fontFamily: 'Satoshi Variable',
								fontSize: 11,
								fontWeight: 700,
								color: 'white',
								padding: '3px 5px',
								cursor: 'pointer'
							}}>
							DEPOSIT
						</div>
					</>
				)}
				<div className="vertical-line-divider"></div>
				<div className="balance-icon-wrapper balance-icon-wrapper__bordered">
					<Badge
						count={messagesCount}
						overflowCount={99}
						size="small"
						offset={[10, -1]}
						color="#0079ff">
						<HiEnvelope
							className="balance-icon"
							color={darkMode ? '#fff' : '#000'}
							onClick={() => navigate('user-bets/inbox')}
						/>
					</Badge>
				</div>
				<div className="vertical-line-divider"></div>
				<div className="balance-icon-wrapper">
					{isMobile ? (
						<MdPerson
							className="balance-icon"
							color={darkMode ? '#fff' : '#000'}
							onClick={() => navigate('/user-bets/mobile-initial')}
						/>
					) : (
						<Dropdown
							menu={{items: userSettingItems}}
							trigger={['click']}
							placement="bottomRight"
							arrow
							// open={isOpen}
							overlayClassName="user-balance-dropdown user-balance-dropdown__settings">
							<MdPerson
								className="balance-icon"
								color={darkMode ? '#fff' : '#000'}
								onClick={() => {}}
							/>
						</Dropdown>
					)}
				</div>
			</div>
			<Popup
				open={modalInfo.open}
				onClose={handleModalClose}
				headerText={modalInfo.type}
				showSuccess={showSuccess}>
				{modalInfo.component}
			</Popup>
		</div>
	);
};

export default UserBalance;
