import {Collapse, Divider} from 'antd';
import LoaderSimple from 'components/Loading/LoaderSimple';
import {Fragment, useEffect, useRef, useState} from 'react';
import {FaAngleDown} from 'react-icons/fa';
import {useNavigate} from 'react-router-dom';
import {fixturesApi, useLazyGetNearbyGamesBySportIdQuery} from 'store/services/fixturesAPI';
import {swapTeams} from 'utils/helpers/homeTeamTypeValidator';
import {OddsProps} from 'utils/interfaces';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {useLazyGetAllSportsQuery} from 'store/services/sportsAPI';
import SVGComponent from 'utils/helpers/SVGComponent';
import MatchCount from 'views/LiveOverview/components/MatchCount';
import LiveIcon from 'views/LiveOverview/components/LiveIcon';
import {modifiedEventState} from 'views/SingleGameBet/components/TimeTable';
import {MdKeyboardArrowUp} from 'react-icons/md';

function groupAndSortByLeagueName(arr: OddsProps[]) {
	const groupedData = {} as {[key: string]: OddsProps[]};

	arr.forEach(item => {
		const {leagueName} = item;

		if (!groupedData[leagueName]) {
			groupedData[leagueName] = [];
		}

		groupedData[leagueName].push(item);
	});

	const sortedKeys = Object.keys(groupedData).sort();

	const sortedAndGroupedData = {} as {[key: string]: OddsProps[]};
	sortedKeys.forEach(key => {
		sortedAndGroupedData[key] = groupedData[key];
	});

	return sortedAndGroupedData;
}

interface CustomLabelProps {
	name: string;
	className?: string;
	onClick: (id: string, name: string, keyId: string) => void;
	id: string;
	activeMenuItem?: string;
	setActiveSportName: (id: string) => void;
	isActive: boolean;
	keyId: string;
	eventCount: number;
}

const {Panel} = Collapse;

const CustomLabel = ({
	name,
	className,
	onClick,
	id,
	setActiveSportName,
	isActive,
	keyId,
	eventCount
}: CustomLabelProps) => {
	return (
		<div
			className={`custom-label custom-label__${className} ${isActive ? 'custom-label__active' : ''}`}
			onClick={() => {
				onClick(id, name, keyId);
				setActiveSportName(keyId);
			}}
			style={{borderBottom: !isActive ? '1px solid var(--live-menu-border-color)' : 'none'}}>
			<span className="custom-label__sport-name">{name?.charAt(0).toUpperCase() + name?.slice(1)}</span>
			<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', gap: 20}}>
				<div className={`custom-label__sport-count`}>{eventCount}</div>

				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						transform: isActive ? 'none' : 'rotate(180deg)'
					}}>
					<MdKeyboardArrowUp size={21} fill={isActive ? 'white' : '#2b90ff'} />
				</div>
			</div>
		</div>
	);
};

const LiveMenu = ({setIsOpen = () => {}}: {setIsOpen?: any}) => {
	const [activeMenuItem, setActiveMenuItem] = useState<any>([]);
	const [collapseActiveKeys, setCollapseActiveKeys] = useState<string | string[]>();
	const [getNearbyGamesBySportId, {data: NearbyGamesData, isFetching, isLoading: NearbyGamesLoading}] =
		useLazyGetNearbyGamesBySportIdQuery();
	const [nearbyGamesState, setNearbyGamesState] = useState<any>({});
	const firstTimeRef = useRef(true);
	const refreshRef = useRef<any>();
	const screenWidth = useScreenWidth();
	const navigate = useNavigate();
	const useQueryStateResult = fixturesApi.endpoints.getOddsByFixtureID.useQueryState(
		{id: Number(window.location.pathname.split('/').at(-2)), isLive: true},
		{
			selectFromResult: res => res
		}
	);
	const [getAllSportsInfo, {data, isSuccess, isLoading}] = useLazyGetAllSportsQuery();

	const fetchData = async () => {
		try {
			await getAllSportsInfo({hasLiveOfferings: true}).unwrap();
		} catch (e) {
			console.log(e);
		}
	};

	const openMenuItem = (sport: any) => {
		if (!findActiveMenuItem(`${sport.name}-${sport.id}`)) {
			fetchNearbyFixtures(sport.id);
		}
	};
	const fetchNearbyFixtures = async (sportId: any) => {
		const response = await getNearbyGamesBySportId({
			id: sportId,
			params: {page: 1, pageSize: 1000, isLive: true}
		}).unwrap();

		const sortedAndGroupedData = groupAndSortByLeagueName(
			response?.payload?.data.filter((el: any) => !el.isLinkedLeagueTop)
		);
		const sortedAndGroupedDataTop = groupAndSortByLeagueName(
			response?.payload?.data.filter((el: any) => el.isLinkedLeagueTop)
		);
		setNearbyGamesState((prev: any) => ({
			...prev,
			[`${sportId}`]: {...sortedAndGroupedDataTop, ...sortedAndGroupedData}
		}));
	};

	useEffect(() => {
		fetchData();
	}, []);

	useEffect(() => {
		if (useQueryStateResult?.status === 'fulfilled' && firstTimeRef.current) {
			openMenuItem({
				name: useQueryStateResult.data.payload.sportName,
				id: useQueryStateResult.data.payload.sportId
			});
			setActiveMenuItem([
				`${useQueryStateResult.data.payload.sportName}-${useQueryStateResult.data.payload.sportId}`
			]);
		}
	}, [useQueryStateResult.status]);

	useEffect(() => {
		if (activeMenuItem.length) {
			refreshRef.current = setInterval(() => {
				for (let i = 0; i < activeMenuItem.length; i++) {
					fetchNearbyFixtures(activeMenuItem[i].split('-')[1]);
				}
			}, 5000);
		}
		return () => {
			clearInterval(refreshRef.current);
		};
	}, [activeMenuItem.length]);

	useEffect(() => {
		if (
			location.href.includes('isLive=true') &&
			Object.entries(nearbyGamesState).length &&
			firstTimeRef.current
		) {
			firstTimeRef.current = false;
			setCollapseActiveKeys(Object.keys(nearbyGamesState[useQueryStateResult.data.payload.sportId]));
		}
	}, [Object.entries(nearbyGamesState).length]);

	const findActiveMenuItem = (selectedItem: string) => activeMenuItem.find((k: any) => k == selectedItem);

	const handleCollapseChange = (activeKeys: string | string[]) => {
		if (Array.isArray(activeKeys)) {
			setCollapseActiveKeys(activeKeys);
		} else {
			setCollapseActiveKeys([activeKeys]);
		}
	};

	const setActiveMenuItemValue = (value: string) => {
		let isActive = activeMenuItem.find((i: any) => i === value);
		if (!isActive) {
			setActiveMenuItem((prev: any) => [...prev, value]);
		} else {
			clearInterval(refreshRef.current);
			setActiveMenuItem((prev: any) => prev.filter((i: string) => i != value));
		}
	};

	return (
		<div className="all-menus-wrapper live-menu">
			<div
				className="in-play-hint"
				style={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					marginRight: 18
				}}>
				<div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
					<div className="in-play-hint__red-dot"></div>
					<div className="in-play-hint__text-hint">IN-PLAY</div>
				</div>
				<div
					onClick={() => setActiveMenuItem([])}
					style={{
						cursor: 'pointer',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
						transform: activeMenuItem.length ? 'none' : 'rotate(180deg)'
					}}>
					<svg
						width="10"
						height="10"
						viewBox="0 0 10 10"
						fill="none"
						xmlns="http://www.w3.org/2000/svg">
						<path
							d="M1 4.2L5 1L9 4.2M1 9L5 5.8L9 9"
							stroke={'#0079FF'}
							strokeWidth="2"
							strokeLinecap="round"
							strokeLinejoin="round"
						/>
					</svg>
				</div>
			</div>{' '}
			<div className="menu-wrapper">
				{data?.payload?.map((i: any, index: number) => {
					return (
						<Fragment key={'top-menu-item' + `${i.name}-${i.id}`}>
							<div
								className={`menu-item ${
									findActiveMenuItem(`${i.name}-${i.id}`) ? 'menu-item__active' : ''
								}`}
								key={'top-menu-item' + index}>
								<div className="icon-name-wrapper">
									<SVGComponent
										path={i?.image}
										width={20}
										height={20}
										key={i?.id + 'svg-component'}
									/>
									<CustomLabel
										id={i.id}
										onClick={() => openMenuItem(i)}
										className="main"
										name={i.name}
										key={i?.id + 'label'}
										isActive={!!findActiveMenuItem(`${i.name}-${i.id}`)}
										keyId={i?.name}
										setActiveSportName={() => setActiveMenuItemValue(`${i.name}-${i.id}`)}
										eventCount={i?.liveEventsCount !== 0 && i?.liveEventsCount}
									/>
								</div>
							</div>
							{!!findActiveMenuItem(`${i.name}-${i.id}`) &&
								nearbyGamesState[i.id] &&
								Object?.entries(nearbyGamesState[i.id])?.map(([key, data], index) => {
									const title = key.split('-');
									return (
										<Collapse
											key={key}
											bordered={false}
											onChange={handleCollapseChange}
											expandIconPosition="end"
											className="ant-collapse-live-overview"
											defaultActiveKey={collapseActiveKeys}
											activeKey={collapseActiveKeys}
											expandIcon={({isActive}) => (
												<FaAngleDown
													size={17}
													style={{
														transform: `rotate(${isActive ? 180 : 0}deg)`,
														transition: '0.2s'
													}}
													rotate={isActive ? 180 : 0}
												/>
											)}>
											<Panel
												header={
													title.length ? (
														<div className="live-overview-panel-header">
															<span className="live-overview-panel-header__title1">
																{title[0]} <br />
															</span>
															<span className="live-overview-panel-header__title2">
																{title[1]}
															</span>
														</div>
													) : (
														<span className="live-overview-panel-header__title1">
															{key}
														</span>
													)
												}
												key={key}
												className="inbox-panel-header">
												{
													// @ts-ignore
													data?.map((value: any) => {
														const validatedByHomeTeamType = swapTeams(value);
														const marketsCount =
															validatedByHomeTeamType?.marketsCount +
															validatedByHomeTeamType?.specialMarketsCount;
														const matchData: any = {
															eachPeriodScore:
																validatedByHomeTeamType?.odd?.periods?.map(
																	value => {
																		if (value?.number !== 0) {
																			return {
																				club1: value?.homeScore,
																				club2: value?.awayScore,
																				periodNumber: value?.number
																			};
																		}
																	}
																),
															finalResult: {
																club1: validatedByHomeTeamType?.odd
																	?.periods[0]?.homeScore,
																club2: validatedByHomeTeamType?.odd
																	?.periods[0]?.awayScore
															},
															redCards: {
																club1: validatedByHomeTeamType?.odd
																	?.periods[0].homeRedCards,
																club2: validatedByHomeTeamType?.odd
																	?.periods[0].awayRedCards
															}
														} as {
															eachPeriodScore: {
																club1: number | null;
																club2: number | null;
																periodNumber: number;
															}[];
															finalResult: {
																club1: number | null;
																club2: number | null;
															};
														};

														const currentDate = new Date();
														const cutoffDate = new Date(
															validatedByHomeTeamType?.odd?.periods?.[0]?.cutoff
														);

														return (
															<div
																key={value.eventId}
																className="game-info-row-wrapper"
																style={{
																	paddingLeft: 10,
																	marginTop: 10,
																	marginBottom: 10,
																	cursor: 'pointer',
																	boxShadow:
																		value.eventId ===
																		Number(
																			window.location.pathname
																				.split('/')
																				.at(-2)
																		)
																			? 'inset 3px 0 0 0 var(--blue-color-multimode)'
																			: 'none'
																}}>
																<>
																	<div
																		onClick={e => {
																			e.stopPropagation();
																			value.liveStatus === 1
																				? navigate(
																						`/bettings/single/${value.eventId}/?isLive=true&parentId=${value.parentId}#period:-1`
																				  )
																				: navigate(
																						`/bettings/single/${value.eventId}/#period:-1`
																				  ),
																				setIsOpen(false);
																		}}
																		className="game-info">
																		<div className="single-bet-sport-info">
																			<div
																				style={{
																					display: 'flex',
																					flexDirection: 'row',
																					alignItems: 'center'
																				}}>
																				<div className="single-bet-sport-info__team">
																					<span>
																						{validatedByHomeTeamType?.home.replace(
																							'(Sets)',
																							''
																						)}
																					</span>
																				</div>
																				{!!matchData?.redCards
																					?.club1 && (
																					<span className="single-bet-sport-info__card">
																						{
																							matchData
																								?.redCards
																								?.club1
																						}
																					</span>
																				)}
																			</div>
																			<div
																				style={{
																					display: 'flex',
																					flexDirection: 'row',
																					alignItems: 'center'
																				}}>
																				<div className="single-bet-sport-info__team">
																					<span>
																						{validatedByHomeTeamType?.away.replace(
																							'(Sets)',
																							''
																						)}
																					</span>
																				</div>
																				{!!matchData?.redCards
																					?.club2 && (
																					<span className="single-bet-sport-info__card">
																						{
																							matchData
																								?.redCards
																								?.club2
																						}
																					</span>
																				)}
																			</div>
																		</div>
																		<div
																			style={{
																				display: 'flex',
																				flexDirection: 'row',
																				gap: 5,
																				justifyContent:
																					'space-between',
																				alignItems: 'center'
																			}}>
																			<div className="live-game-timer">
																				<span className="live-game-timer__hint">
																					{value?.eventState &&
																						`${modifiedEventState(
																							value?.eventState,
																							screenWidth
																						)}`}
																				</span>
																				<span className="live-game-timer__hint live-game-timer__hint_minute">
																					{value?.elapsedMinutes &&
																						`${value?.elapsedMinutes}'`}
																				</span>
																			</div>
																			<div
																				style={{
																					display: 'flex',
																					flexDirection: 'row',
																					gap: 5,
																					alignItems: 'center',
																					marginRight: 5
																				}}>
																				<span
																					className="markets-counter"
																					style={{
																						marginTop: 4
																					}}>
																					+{marketsCount}
																				</span>
																				<LiveIcon />
																			</div>
																		</div>
																	</div>
																	{value.sportName === 'Soccer' && (
																		<div style={{display: 'flex'}}>
																			<MatchCount
																				eachPeriodScore={
																					matchData?.eachPeriodScore
																				}
																				finalResult={
																					matchData?.finalResult
																				}
																			/>
																			<Divider
																				type="vertical"
																				style={{
																					width: 1,
																					height: 40
																				}}
																				className="vertical-live-divider-live-template"
																			/>
																		</div>
																	)}
																	{/* <Divider
																		type="horizontal"
																		// className="vertical-live-divider-live-template"
																	/> */}
																</>
															</div>
														);
													})
												}
											</Panel>
										</Collapse>
									);
								})}
						</Fragment>
					);
				})}
			</div>
		</div>
	);
};

export default LiveMenu;
