import 'antd/dist/reset.css';
import React from 'react';
import ReactDOM from 'react-dom/client';
import {I18nextProvider} from 'react-i18next';
import {Provider} from 'react-redux';
import {BrowserRouter} from 'react-router-dom';
import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {store} from 'store';
import i18n from 'utils/i18next';
import App from './App';
import './index.less';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<I18nextProvider i18n={i18n}>
			<BrowserRouter>
				<Provider store={store}>
					<App />
					<ToastContainer
						position="top-center"
						autoClose={2500}
						hideProgressBar={false}
						newestOnTop={false}
						closeOnClick
						rtl={false}
						pauseOnFocusLoss
						draggable
						pauseOnHover
						theme="dark"
					/>
				</Provider>
			</BrowserRouter>
		</I18nextProvider>
	</React.StrictMode>
);
