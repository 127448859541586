import i18n from 'i18next';
import {initReactI18next} from 'react-i18next';
import enTranslation from './locales/en/translation.json';
import ruTranslation from './locales/ru/translation.json';
import hyTranslation from './locales/hy/translation.json';

i18n.use(initReactI18next).init({
	lng: 'en',
	fallbackLng: 'en',
	resources: {
		en: {translation: enTranslation},
		ru: {translation: ruTranslation},
		hy: {translation: hyTranslation}
	}
});

export default i18n;
