import React from 'react';
import {LoadingOutlined} from '@ant-design/icons';
import {Spin} from 'antd';

const antIcon = <LoadingOutlined style={{fontSize: 24}} spin />;

const LoaderSimple: React.FC = () => (
	<Spin
		indicator={antIcon}
		style={{
			width: '100%',
			height: '50vh',
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center'
		}}
	/>
);

export default LoaderSimple;
