import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const transactionsApi = createApi({
	reducerPath: 'transactions',
	baseQuery: baseQuery(`/api/v1`),
	endpoints: builder => ({
		getTransactions: builder.query({
			query: ({endPoint}) => {
				return {
					url: `transactions?${endPoint}`,
					method: 'GET'
				};
			}
		})
	})
});

export const {useLazyGetTransactionsQuery} = transactionsApi;
