import {SingleBetBoxProps} from 'components/Layout/Betslip/components/SingleBetBox';
import _ from 'lodash';
import {useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from 'store';
import {useLogInMutation, useSignUpMutation} from 'store/services/authAPI';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import LanguageSelector from 'utils/i18next/LanguageSelector';
import {authorizationTypes, breakpoint, themeTypes} from 'utils/types';
import './../Header.less';
import DarkModeHandler from './DarkModeHandler';
import OddsTypeSelector from './OddsTypeSelector';
import Searchbar from './Searchbar';
import Tab from './Tab';
import Timer from './Timer';
import WALLETICON from 'assets/icons/wallet.svg';

const tabs = {
	ALL_SPORTS: 1,
	LIVE: 2
};

interface ActiveTabProps {
	ALL_SPORTS: boolean;
	LIVE: boolean;
}

const HeaderDown = () => {
	const darkMode = useSelector((state: RootState) => state.darkMode) as keyof typeof themeTypes;
	const oddsData = useSelector((state: RootState) => state.createbetSlice) as {
		[K in string]: SingleBetBoxProps;
	};
	const [signUp, {isLoading, isSuccess, isError, error, data}] = useSignUpMutation();
	const [logIn] = useLogInMutation();
	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);

	const [isInputOpen, setIsInputOpen] = useState(false);

	const navigate = useNavigate();

	const isLiveOverview =
		location.pathname.includes('live-overview') || location.search.includes('?isLive=true');

	const {t} = useTranslation();
	const screenWidth = useScreenWidth();

	const [activeTabs, setActiveTabs] = useState({
		1: true,
		2: false
	});

	const handleActiveTabs = (id: number) => {
		let newTabs = {...activeTabs};

		if (id === 1 || id === 2) {
			_.assign(newTabs, {1: false, 2: false});
			newTabs[id] = true;
		}
		setActiveTabs(newTabs);
		navigate(id === 1 ? '/' : `/bettings/live-overview`);
	};

	const activeTabPermission = () => {
		if (
			location.pathname === '/' ||
			location.pathname.includes('auth') ||
			location.pathname.includes('bettings')
		) {
			return true;
		}
		return false;
	};

	useEffect(() => {
		if (isLiveOverview) {
			setActiveTabs({1: false, 2: true});
		} else {
			setActiveTabs({1: true, 2: false});
		}
	}, [location.pathname]);

	return (
		<>
			<div className="header-down">
				<div className="header-down__search-routes-wrapper">
					<Searchbar isOpen={isInputOpen} setInputOpen={setIsInputOpen} />
					{!(isInputOpen && screenWidth < breakpoint.MOBILE) ? (
						<div className="header-down__routes">
							<Tab
								darkMode={darkMode}
								id={1}
								text={t('sportsBetting')}
								isActive={activeTabs[1] && activeTabPermission()}
								onClick={handleActiveTabs}
							/>
							<Tab
								darkMode={darkMode}
								id={2}
								text={t('liveOverview')}
								isActive={activeTabs[2] && activeTabPermission()}
								onClick={handleActiveTabs}
							/>
						</div>
					) : null}
				</div>

				<div className="header-down__modes">
					{screenWidth < breakpoint.DESKTOP && isAuthenticated === authorizationTypes.AUTHORIZED ? (
						<img
							src={WALLETICON}
							onClick={() => navigate('/user-bets/deposit')}
							style={{marginRight: 7}}
						/>
					) : (
						<></>
					)}
					{screenWidth > breakpoint.DESKTOP && <DarkModeHandler />}
					{screenWidth > breakpoint.DESKTOP && <LanguageSelector />}
					{screenWidth > breakpoint.DESKTOP && <OddsTypeSelector darkMode={darkMode} />}
					{screenWidth >= breakpoint.DESKTOP && <Timer darkMode={darkMode} />}
				</div>
			</div>
		</>
	);
};

export default HeaderDown;
