import './../LiveOverview.less';

const LiveIcon = ({type = 'active'}: {type?: string}) => {
	return (
		<div className={`live-icon-wrapper live-icon-wrapper__${type}`}>
			{type === 'inactive' ? 'L' : 'LIVE'}
		</div>
	);
};

export default LiveIcon;
