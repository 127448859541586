import {useSelector} from 'react-redux';
import {RootState} from 'store';
import BetslipTab from './BetslipTab';
import {ActiveTabsState} from '../Betslip';
import {BiChevronsDown} from 'react-icons/bi';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {authorizationTypes, breakpoint} from 'utils/types';
import {useTranslation} from 'react-i18next';
import {addThousandSeperator} from 'utils/helpers/addThousandSeperator';
import './../../Header/Header.less';
interface BetslipHeaderProps {
	handleActiveTabs: (id: number) => void;
	activeTabs: ActiveTabsState;
	handleCloseBetslip?: () => void;
}

const BetslipHeader = ({handleActiveTabs, activeTabs, handleCloseBetslip}: BetslipHeaderProps) => {
	const screenWidth = useScreenWidth();
	const isMobileOrTablet = screenWidth < breakpoint.DESKTOP;
	const isMobile = screenWidth < breakpoint.MOBILE;
	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);

	const bets = useSelector((state: RootState) => state.createbetSlice);
	const showBalanceState = useSelector((state: RootState) => state.showBalanceSlice.showBalance);

	const betsCount = Object.keys(bets).length;

	const {t} = useTranslation();

	return (
		<div className="betslip-header">
			<div className="betslip-header__tabs betslip-header__main-tabs">
				<BetslipTab
					tab={t('betslip')}
					betsCount={`${betsCount}`}
					id={1}
					onClick={handleActiveTabs}
					isActive={activeTabs[1]}
				/>
				{isAuthenticated === authorizationTypes.AUTHORIZED && (
					<BetslipTab
						tab={t('myBets')}
						id={2}
						onClick={handleActiveTabs}
						isActive={activeTabs[2]}
					/>
				)}
				{isMobile && (
					<div className="balance-amount" style={{position: 'absolute', right: 50}}>
						<span className="balance-amount__title">{t('balance')}</span>
						<div className="balance-amount__sheet">
							<img src={require('assets/icons/usd.svg').default} alt="currency" />
							{showBalanceState ? (
								<div className="balance-amount__number">
									{addThousandSeperator(userCredentials?.balance.toString())}
								</div>
							) : (
								<span style={{paddingTop: 5, color: 'white'}}>*****</span>
							)}
						</div>
					</div>
				)}
				{isMobileOrTablet && (
					<BiChevronsDown
						color="#0079ff"
						onClick={handleCloseBetslip}
						className="betslip-closer-btn"
						size={25}
					/>
				)}
			</div>
		</div>
	);
};

export default BetslipHeader;
