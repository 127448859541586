import {OddsProps} from 'utils/interfaces';

export function swapTeams(data: OddsProps) {
	if (data?.homeTeamType === 'Team1') {
		return data;
	} else {
		const swappedData = {
			eventId: data?.eventId,
			parentId: data?.parentId,
			home: data?.away,
			away: data?.home,
			leagueId: data?.leagueId,
			sportId: data?.sportId,
			leagueName: data?.leagueName,
			starts: data?.starts,
			id: data?.id,
			sportName: data?.sportName,
			specialMarketsCount: data?.specialMarketsCount,
			resultingUnit: data?.resultingUnit,
			liveStatus: data?.liveStatus,
			marketsCount: data?.marketsCount,
			odd: {
				...data?.odd,
				periods: data?.odd?.periods?.map(period => ({
					...period,
					cutoff: period?.cutoff,
					status: period?.status,
					moneyline:
						period?.moneyline?.away || period?.moneyline?.home || period?.moneyline?.draw
							? {
									home: period?.moneyline?.away,
									away: period?.moneyline?.home,
									draw: period?.moneyline?.draw
							  }
							: undefined,
					spreads: period?.spreads?.map(spread =>
						spread.away || spread.home
							? {
									...spread,
									home: spread?.away,
									away: spread?.home
							  }
							: undefined
					),
					teamTotal: {
						home: {
							...period?.teamTotal?.away
						},
						away: {
							...period?.teamTotal?.home
						}
					}
				}))
			},
			homeTeamType: 'Team2'
		};
		return swappedData;
	}
}
