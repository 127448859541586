import {Button, Checkbox, Form, Input, InputNumber, Select} from 'antd';
import {useState} from 'react';
import {IoIosArrowDown} from 'react-icons/io';
import {toast} from 'react-toastify';
import {useSignUpMutation} from 'store/services/authAPI';
import './Popup.less';
import {useTranslation} from 'react-i18next';
import Popup from './Popup';
import EmailVerification from './EmailVerification';
import {modalTypes} from 'utils/types';
import {countries} from 'dummyData/Countries';

interface SignInPopupProps {
	onClick?: () => void;
	handleModalClose: () => void;
	handleModalType: (type: string) => void | any;
}

export interface SingInFormProps {
	email: string;
	password: string;
	rePassword: string;
	firstName: string;
	lastName: string;
	day?: string;
	country: string;
	year?: string;
	month?: string;
	checkbox?: boolean;
	dateOfBirth: string;
}

const onFinishFailed = (errorInfo: any) => {};

const SignInForm = ({onClick, handleModalClose, handleModalType}: SignInPopupProps) => {
	const [signUp, {isLoading, isSuccess, isError, error, data}] = useSignUpMutation();
	const [checkboxActive, setCheckboxActive] = useState(false);
	const [verificationModalOpen, setVerificationModalOpen] = useState(false);

	const {t} = useTranslation();

	const onFinish = async (values: SingInFormProps) => {
		values.dateOfBirth = `${values.year}-${values.month}-${
			values.day && +values.day < 10 ? '0' + values.day : values.day
		}`;
		delete values.day, delete values.month, delete values.year;
		try {
			await signUp(values).unwrap();
			setVerificationModalOpen(true);
		} catch (e) {
			(e as any)?.data?.message.forEach((value: string) => toast.error(value));
		}
	};

	return !verificationModalOpen ? (
		<div className="form-wrapper">
			<Form
				name="basic"
				style={{width: '100%'}}
				initialValues={{remember: true}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				layout="vertical"
				autoComplete="off">
				<div className="user-names field-row">
					<Form.Item
						label={t('firstName')}
						name="firstName"
						rules={[{required: true, message: 'Required'}]}>
						<Input />
					</Form.Item>

					<Form.Item
						label={t('lastName')}
						name="lastName"
						rules={[{required: true, message: 'Required'}]}>
						<Input />
					</Form.Item>
				</div>

				<div className="user-birth field-row">
					<Form.Item name="day" rules={[{required: true, message: 'Required'}]}>
						<InputNumber placeholder={t('day') || ''} controls={false} min={1} max={31} />
					</Form.Item>

					<Form.Item
						name="month"
						className="custom-select"
						rules={[{required: true, message: 'Required'}]}>
						<Select
							placeholder="Month"
							suffixIcon={<IoIosArrowDown color="white" />}
							options={[
								{value: '01', label: 'January'},
								{value: '02', label: 'February'},
								{value: '03', label: 'March'},
								{value: '04', label: 'April'},
								{value: '05', label: 'May'},
								{value: '06', label: 'June'},
								{value: '07', label: 'July'},
								{value: '08', label: 'August'},
								{value: '09', label: 'September'},
								{value: '10', label: 'October'},
								{value: '11', label: 'November'},
								{value: '12', label: 'December'}
							]}
						/>
					</Form.Item>

					<Form.Item name="year" rules={[{required: true, message: 'Required'}]}>
						<InputNumber
							placeholder="Year"
							type="number"
							controls={false}
							min={1950}
							max={2003}
						/>
					</Form.Item>
				</div>

				<div className="field-row">
					<Form.Item
						label={t('country')}
						name="country"
						className="custom-select"
						rules={[{required: true, message: 'Required'}]}>
						<Select
							suffixIcon={<IoIosArrowDown color="white" />}
							placeholder={t('chooseCountry')}
							style={{width: '100%'}}
							options={countries}
						/>
					</Form.Item>
				</div>

				<div className="field-row">
					<Form.Item
						label={t('email')}
						name="email"
						rules={[{required: true, message: 'Required'}]}>
						<Input type="email" />
					</Form.Item>
				</div>

				<div className="user-password field-row">
					<Form.Item
						label={t('password')}
						name="password"
						rules={[{required: true, message: 'Required'}]}>
						<Input type="password" autoComplete="new password" />
					</Form.Item>

					<Form.Item
						name="rePassword"
						label={t('confirmPassword')}
						dependencies={['password']}
						rules={[
							{
								required: true,
								message: 'Please confirm your password!'
							},
							({getFieldValue}) => ({
								validator(_, value) {
									if (!value || getFieldValue('password') === value) {
										return Promise.resolve();
									}
									return Promise.reject(
										new Error('The two passwords that you entered do not match!')
									);
								}
							})
						]}>
						<Input type="password" autoComplete="new password" />
					</Form.Item>
				</div>

				<div className="field-row password-requirements">
					<span>{t('passwordRequirements')}</span>
					<ul className="requirements-hints">
						<li>{t('letters')}</li>
						<li>{t('character')} (0-9)</li>
						<li>{t('specialCharacter')}</li>
					</ul>
				</div>

				{/* <div className="field-row">
					<Form.Item
						label={t('currency')}
						name="currency"
						className="custom-select"
						rules={[{required: true, message: 'Required'}]}>
						<Select
							suffixIcon={<IoIosArrowDown color="white" />}
							placeholder={t('chooseCurrency')}
							style={{width: '100%'}}
							options={[
								{value: 'BTC', label: 'BTC'},
								{value: 'LTC', label: 'LTC'},
								{value: 'ETH', label: 'ETH'},
								{value: 'AMD', label: 'AMD'},
								{value: 'USD', label: 'USD'},
								{value: 'RUB', label: 'RUB'}
							]}
						/>
					</Form.Item>
				</div> */}

				<div className="field-row agreement-checkbox">
					<div>
						<Checkbox
							id="terms-and-conditions-checkbox"
							onChange={e => setCheckboxActive(e.target.checked)}
							style={{color: 'red'}}
						/>
						<label htmlFor="terms-and-conditions-checkbox" className="checkbox-label">
							{t('agreeToTc')}
						</label>
					</div>
				</div>

				<Form.Item wrapperCol={{offset: 8, span: 16}} className="submit-button">
					<Button
						type="primary"
						htmlType="submit"
						style={{
							width: '100%',
							border: 'none',
							background: '#2B90FF',
							boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)',
							borderRadius: '2px'
						}}
						disabled={!checkboxActive}>
						<span className="submit-button__btn">{t('createMyAccount')}</span>
					</Button>
				</Form.Item>
				<div className="horizontal-line-divider"></div>
				<div className="login-join-hint">
					<div className="text-line">
						<span className="login-join-hint__regular">{t('haveAnAccount')}</span>
						<span className="login-join-hint__bold" onClick={onClick}>
							{t('logIn').toLowerCase()}
						</span>
					</div>
					<div className="text-line">
						{/* <span className="login-join-hint__regular">Forgot</span>
					<span className="login-join-hint__bold">Email</span> */}
						<span className="login-join-hint__regular">{t('forgot')}</span>
						<span
							className="login-join-hint__bold"
							onClick={() => handleModalType(modalTypes.RESET_PASSWORD)}>
							{t('password')}
						</span>
					</div>
				</div>
			</Form>
		</div>
	) : (
		<Popup
			onClose={() => {
				setVerificationModalOpen(false);
				handleModalClose();
			}}
			headerText="Success!"
			open={verificationModalOpen}>
			<EmailVerification />
		</Popup>
	);
};

export default SignInForm;
