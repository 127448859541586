import React, {useState} from 'react';
import {Select, Space} from 'antd';
import {useTranslation} from 'react-i18next';
import './../Header.less';
import {ReactComponent as ArrowIcon} from 'assets/icons/arrow_down.svg';
import {useDispatch, useSelector} from 'react-redux';
import {setCurrentOddType} from 'store/slices/oddsTypeSlice';
import {RootState} from 'store';
import {breakpoint, oddTypes, themeTypes} from 'utils/types';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {GoTriangleDown} from 'react-icons/go';

const OddsTypeSelector = ({darkMode}: {darkMode: keyof typeof themeTypes}) => {
	const {t} = useTranslation();
	const dispatch = useDispatch();
	const oddType = useSelector((state: RootState) => state.oddsTypeSlice);

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	const handleChange = (value: string) => {
		dispatch(setCurrentOddType(value));
	};

	return !isMobile ? (
		<div className="odds-type-selector">
			<span
				className={`odds-type-selector__title ${
					darkMode === themeTypes.darkMode ? 'odds-type-selector__title-darkmode' : ''
				} header-down-common-text`}>
				{t('odds')}:{' '}
			</span>
			<Space wrap>
				<Select
					className={`ant-select-selection-item ${
						darkMode === themeTypes.darkMode && 'ant-select-selection-item__darkmode'
					} header-down-common-text`}
					defaultValue={oddType}
					style={{width: 300}}
					bordered={false}
					onChange={handleChange}
					options={[
						{value: oddTypes.DECIMAL, label: t('decimal')},
						{value: oddTypes.AMERICAN_ODD, label: t('american')}
					]}
					suffixIcon={<ArrowIcon fill="#000" />}
				/>
			</Space>
		</div>
	) : (
		<Select
			className={'footer-selector'}
			defaultValue={oddType}
			bordered={false}
			onChange={handleChange}
			options={[
				{value: oddTypes.DECIMAL, label: t('decimal')},
				{value: oddTypes.AMERICAN_ODD, label: t('american')}
			]}
			suffixIcon={<GoTriangleDown color="#868686" />}
		/>
	);
};

export default OddsTypeSelector;
