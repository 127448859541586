import TANGERBET_LOGO from 'assets/logos/tangerbet-logo-blue.svg';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from 'store';
import './../Header.less';
import {setCurrentActiveMenuType} from 'store/slices/menuSlice';
import {breakpoint, menuTypes} from 'utils/types';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {API_PREFIX} from 'store/services/api';

const HeaderLogo = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const logo = localStorage.getItem('logo');

	const screenWidth = useScreenWidth();

	const isLiveOverview = location.pathname.includes('live-overview');

	const handleClick = () => {
		window.scrollTo({left: 0, top: 0, behavior: 'smooth'});
		navigate('/');
		dispatch(setCurrentActiveMenuType(menuTypes.SWIPER_MENU));
	};

	return (
		<img
			crossOrigin="anonymous"
			onClick={handleClick}
			src={API_PREFIX + '/api/public/' + logo}
			className={`header-logo`}
			alt="TANGERBET"
			style={{
				marginLeft:
					screenWidth < breakpoint.DESKTOP && !location.pathname.includes('/user-bets')
						? 37
						: 'unset'
			}}
		/>
	);
};

export default HeaderLogo;
