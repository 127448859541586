import {Col, Divider, Row} from 'antd';
import {IoTimerOutline} from 'react-icons/io5';
import {useNavigate} from 'react-router-dom';
import {initialSpan} from 'utils/helpers/initialSpan';
import {betType, betTypeKeys, breakpoint} from 'utils/types';
import CoefficientBox from 'views/MainPageBets/components/CoefficientBox';
import {GridTemplateBetProps, diff_minutes} from 'views/MainPageBets/components/GridTemplate';
import {homeTeamType as homeTeamTypes} from 'utils/types';
import {getHandicapValue, handleHandicapIdicator} from 'utils/helpers/oddConverter';
import './../LiveOverview.less';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import MatchCount from './MatchCount';
import LiveIcon from './LiveIcon';
import {modifiedEventState} from 'views/SingleGameBet/components/TimeTable';

const LiveBetGridTemplate = ({
	liveStatus,
	club1,
	club2,
	startsAt,
	marketsCount,
	coefficents,
	id,
	eventId,
	defaultFields,
	betID,
	leagueName,
	maxBets,
	nearbyGames,
	startsAtNotFormatted,
	leagueId,
	homeTeamType,
	prevCoefficents,
	currentSelectedBetType,
	periodNumber,
	matchData,
	sportName,
	parentId,
	isBetOpen,
	isBetClosedByStatus,
	isSuspended,
	minutes,
	eventState
}: GridTemplateBetProps) => {
	const navigate = useNavigate();

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;
	const showCols = {
		moneyline: currentSelectedBetType === betType.MONEYLINE || !isMobile,
		handicap: currentSelectedBetType === betType.HANDICAP || !isMobile,
		total: currentSelectedBetType === betType.TOTAL || !isMobile
	};
	const firstColSize = showCols.moneyline
		? defaultFields.draw
			? !liveStatus
				? 235
				: 265
			: 170
		: !liveStatus
		? 170
		: sportName === 'Soccer'
		? 200
		: 170;

	return (
		<tbody
			onClick={e => {
				e.stopPropagation();
				liveStatus === 1
					? navigate(`/bettings/single/${eventId}/?isLive=true&parentId=${parentId}#period:-1`)
					: navigate(`/bettings/single/${eventId}/#period:-1`);
			}}
			className="ant-table-tbody grid-template-bet">
			<tr data-row-key="1" className="ant-table-row ant-table-row-level-0">
				<td className="ant-table-cell">
					<div className="game-info-row-wrapper" style={{paddingLeft: 10}}>
						<div
							className="single-bet-sport-info"
							style={{width: screenWidth < 450 ? `${screenWidth - firstColSize}px` : '100%'}}>
							<div className="single-bet-sport-info">
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<div className="single-bet-sport-info__team">
										<span>{club1.replace('(Sets)', '')}</span>
									</div>
									{!!matchData?.redCards?.club1 && (
										<span className="single-bet-sport-info__card">
											{matchData?.redCards?.club1}
										</span>
									)}
								</div>
								<div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
									<div className="single-bet-sport-info__team">
										<span>{club2.replace('(Sets)', '')}</span>
									</div>
									{!!matchData?.redCards?.club2 && (
										<span className="single-bet-sport-info__card">
											{matchData?.redCards?.club2}
										</span>
									)}
								</div>
								{!nearbyGames && (
									<span className="single-bet-sport-info__date">{startsAt}</span>
								)}
							</div>
							<div className="live-game-timer">
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: 5
									}}>
									<span className="live-game-timer__hint">
										{eventState && `${modifiedEventState(eventState, screenWidth)}`}
									</span>
									<span className="live-game-timer__hint live-game-timer__hint_minute">
										{minutes && `${minutes}'`}
									</span>
								</div>
								<div
									style={{
										display: 'flex',
										flexDirection: 'row',
										alignItems: 'center',
										gap: 5
									}}>
									<span className="markets-counter" style={{marginTop: 4}}>
										+{marketsCount}
									</span>
									<LiveIcon />
								</div>
							</div>
						</div>
						{sportName === 'Soccer' && (
							<div style={{display: 'flex'}}>
								<MatchCount
									eachPeriodScore={matchData?.eachPeriodScore}
									finalResult={matchData?.finalResult}
								/>
								<Divider
									type="vertical"
									style={{
										width: 1,
										height: 40
									}}
									className="vertical-live-divider-live-template"
								/>
							</div>
						)}
					</div>
				</td>
				{showCols.moneyline && (
					<td className="ant-table-cell">
						<div className="grid-column ">
							<CoefficientBox
								isBetOpen={isBetOpen}
								isBetClosedByStatus={isBetClosedByStatus}
								isSuspended={isSuspended}
								homeTeamType={homeTeamType}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match MONEY LINE'}
								eventId={eventId}
								indicator={club1}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.firstWinCF}
								prevCoefficent={prevCoefficents?.firstWinCF}
								betID={betID}
								club={club1}
								type={betType.MONEYLINE}
								startsAt={startsAt}
								maxBet={maxBets?.maxMoneyline}
								predictedClubType={homeTeamTypes.TEAM1}
							/>
							{defaultFields.draw && (
								<CoefficientBox
									isBetOpen={isBetOpen}
									isBetClosedByStatus={isBetClosedByStatus}
									isSuspended={isSuspended}
									homeTeamType={homeTeamType}
									periodNumber={periodNumber}
									startsAtNotFormatted={startsAtNotFormatted}
									leagueId={leagueId}
									marketTitle={'Match MONEY LINE'}
									eventId={eventId}
									indicator={homeTeamTypes.DRAW}
									leagueName={leagueName}
									teams={{club1, club2}}
									coefficent={coefficents?.drawCF}
									prevCoefficent={prevCoefficents?.drawCF}
									betID={betID}
									type={betType.MONEYLINE}
									startsAt={startsAt}
									maxBet={maxBets?.maxMoneyline}
									club={homeTeamTypes.DRAW}
									predictedClubType={homeTeamTypes.DRAW}
								/>
							)}
							<CoefficientBox
								isBetOpen={isBetOpen}
								isBetClosedByStatus={isBetClosedByStatus}
								isSuspended={isSuspended}
								homeTeamType={homeTeamType}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match MONEY LINE'}
								eventId={eventId}
								indicator={club2}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.secondWinCF}
								prevCoefficent={prevCoefficents?.secondWinCF}
								betID={betID}
								club={club2}
								type={betType.MONEYLINE}
								startsAt={startsAt}
								maxBet={maxBets?.maxMoneyline}
								predictedClubType={homeTeamTypes.TEAM2}
							/>
						</div>
					</td>
				)}
				{showCols.handicap && (
					<td className="ant-table-cell">
						<div className="grid-column ">
							<CoefficientBox
								isBetOpen={isBetOpen}
								isBetClosedByStatus={isBetClosedByStatus}
								isSuspended={isSuspended}
								homeTeamType={homeTeamType}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match HANDICAP'}
								eventId={eventId}
								indicator={handleHandicapIdicator(
									coefficents?.handicapDiff,
									club1,
									homeTeamType,
									true
								)}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.handicap1CF ?? undefined}
								multiLine
								betID={betID}
								coefficentDiff={
									homeTeamType === homeTeamTypes.TEAM1
										? coefficents.handicapDiff
										: coefficents.handicapDiff && coefficents.handicapDiff * -1
								}
								prevCoefficent={prevCoefficents?.handicap1CF}
								club={club1}
								type={betType.HANDICAP}
								startsAt={startsAt}
								maxBet={maxBets?.maxSpread}
								predictedClubType={homeTeamTypes.TEAM1}
								point={getHandicapValue(coefficents?.handicapDiff, club1, homeTeamType, true)}
							/>
							<CoefficientBox
								isBetOpen={isBetOpen}
								isBetClosedByStatus={isBetClosedByStatus}
								isSuspended={isSuspended}
								homeTeamType={homeTeamType}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match HANDICAP'}
								eventId={eventId}
								indicator={handleHandicapIdicator(
									coefficents?.handicapDiff,
									club2,
									homeTeamType
								)}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.handicap2CF ?? undefined}
								prevCoefficent={prevCoefficents?.handicap2CF}
								multiLine
								betID={betID}
								coefficentDiff={
									homeTeamType === homeTeamTypes.TEAM1
										? coefficents?.handicapDiff && coefficents?.handicapDiff * -1
										: coefficents?.handicapDiff
								}
								club={club2}
								type={betType.HANDICAP}
								startsAt={startsAt}
								maxBet={maxBets?.maxSpread}
								predictedClubType={homeTeamTypes.TEAM2}
								point={getHandicapValue(coefficents?.handicapDiff, club2, homeTeamType)}
							/>
						</div>
					</td>
				)}
				{showCols.total && (
					<td className="ant-table-cell">
						<div className="grid-column">
							<CoefficientBox
								isBetOpen={isBetOpen}
								isBetClosedByStatus={isBetClosedByStatus}
								isSuspended={isSuspended}
								homeTeamType={homeTeamType}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match TOTAL'}
								eventId={eventId}
								indicator={`${betTypeKeys.OVER} ${coefficents.overDiff}`}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.overCF}
								prevCoefficent={prevCoefficents?.overCF}
								multiLine
								betID={betID}
								coefficentDiff={coefficents.overDiff}
								club={club1}
								type={betType.TOTAL}
								startsAt={startsAt}
								maxBet={maxBets?.maxTotal}
								side={betTypeKeys.OVER}
								point={coefficents.overDiff ?? 0}
							/>
							<CoefficientBox
								isBetOpen={isBetOpen}
								isBetClosedByStatus={isBetClosedByStatus}
								isSuspended={isSuspended}
								homeTeamType={homeTeamType}
								periodNumber={periodNumber}
								startsAtNotFormatted={startsAtNotFormatted}
								leagueId={leagueId}
								marketTitle={'Match TOTAL'}
								eventId={eventId}
								indicator={`${betTypeKeys.UNDER} ${coefficents.overDiff}`}
								leagueName={leagueName}
								teams={{club1, club2}}
								coefficent={coefficents?.underCF}
								prevCoefficent={prevCoefficents?.underCF}
								multiLine
								betID={betID}
								coefficentDiff={coefficents.overDiff}
								club={club2}
								type={betType.TOTAL}
								startsAt={startsAt}
								maxBet={maxBets?.maxTotal}
								side={betTypeKeys.UNDER}
								point={coefficents.overDiff ?? 0}
							/>
						</div>
					</td>
				)}
			</tr>
		</tbody>
	);
};
export default LiveBetGridTemplate;
