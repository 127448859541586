import {createSlice} from '@reduxjs/toolkit';

const currentSelectedSportSlice = createSlice({
	name: 'currentSelectedSportSlice',
	initialState: {
		sportType: '',
		country: '',
		league: ''
	},
	reducers: {
		setCurrentSelectedSport(state, action) {
			if (action.payload.optionType === 'sportType') {
				return {...state, sportType: action.payload.sportType};
			} else if (action.payload.optionType === 'country') {
				return {...state, country: action.payload.country};
			} else if (action.payload.optionType === 'league') {
				return {...state, league: action.payload.league};
			}
		}
	}
});

export const {setCurrentSelectedSport} = currentSelectedSportSlice.actions;
export default currentSelectedSportSlice.reducer;
