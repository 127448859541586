import React from 'react';
import {CONDITIONS_PAGE_PATHNAME} from 'utils/types';

export const routes = [
	{
		path: '/',
		element: React.lazy(() => import('views/MainPageBets')),
		type: 'betting'
	},
	{
		path: '/bettings/:sportId',
		element: React.lazy(() => import('views/MainPageBets')),
		type: 'betting'
	},
	{
		path: '/bettings/single/:eventId',
		element: React.lazy(() => import('views/SingleGameBet')),
		type: 'betting'
	},
	{
		path: '/bettings/nearby/:sportId',
		element: React.lazy(() => import('views/NearbyGames')),
		type: 'betting'
	},
	{
		path: '/bettings/leagues/:id',
		element: React.lazy(() => import('views/AllLeaguesMenu')),
		type: 'betting'
	},
	{
		path: '/bettings/filtered',
		element: React.lazy(() => import('views/FilteredElements')),
		type: 'betting'
	},
	{
		path: '/user-bets',
		element: React.lazy(() => import('views/UserBets')),
		privateRoute: true,
		subMenu: [
			{
				path: '/user-bets/:path',
				element: React.lazy(() => import('views/UserBets')),
				privateRoute: true
			}
		]
	},
	{
		path: `/${CONDITIONS_PAGE_PATHNAME}`,
		element: React.lazy(() => import('views/TermsAndConditions'))
	},
	{
		path: '/bettings/live-overview',
		element: React.lazy(() => import('views/LiveOverview'))
	},
	{
		path: '/auth/password-reset',
		element: React.lazy(() => import('views/MainPageBets')),
		type: 'betting'
	},
	{
		path: '/auth/email-confirmation',
		element: React.lazy(() => import('views/TermsAndConditions'))
	}
];
