import {useSelector} from 'react-redux';
import {RootState} from 'store';
import ARROW from 'assets/icons/arrow.svg';
import './../EventSwiper.less';
import SVGComponent from 'utils/helpers/SVGComponent';
import {VscTriangleRight} from 'react-icons/vsc';
import arrowIcon from 'assets/icons/arrowIcon.png';

interface SliderButtonProps {
	direction?: string;
	onClick?: () => void;
	isVisible?: boolean;
}

const SliderButton = ({direction, onClick, isVisible}: SliderButtonProps) => {
	const darkMode = useSelector((state: RootState) => state.darkMode);

	return (
		<div
			className={`slider-button ${
				darkMode ? 'slider-button__darkmode' : ''
			} slider-button__${direction} slider-button__${isVisible ? 'visible' : 'hidden'}`}
			onClick={onClick}>
			{/* <img src={ARROW} alt="arrow" className={`slider-button__arrow-${direction}`} /> */}
			{/* <VscTriangleRight
				size={100}
				className={`slider-button__arrow-${direction}`}
				color={darkMode ? 'red' : '#000'}
			/> */}

			<img
				src={arrowIcon}
				alt=""
				className={`slider-button__arrow-${direction}`}
				style={{color: darkMode ? 'red' : '#000'}}
			/>
		</div>
	);
};

export default SliderButton;
