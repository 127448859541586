import {createApi} from '@reduxjs/toolkit/query/react';
import baseQuery from 'utils/helpers/baseQuery';

export const createBetApi = createApi({
	reducerPath: 'createBet',
	baseQuery: baseQuery(`/api/v1`),
	endpoints: builder => ({
		postSingleBets: builder.mutation({
			query: body => ({
				url: 'bets/singles',
				method: 'POST',
				body
			})
		}),
		postMultipleBets: builder.mutation({
			query: body => ({
				url: 'bets/multiples',
				method: 'POST',
				body
			})
		}),
		getBets: builder.query({
			query: ({params, endpoint}) => ({
				url: `bets?${endpoint}`,
				method: 'GET'
			})
		})
	})
});

export const {usePostSingleBetsMutation, usePostMultipleBetsMutation, useGetBetsQuery, useLazyGetBetsQuery} =
	createBetApi;
