import {memo, useState} from 'react';
import './Sider.less';
import Menu from './components/Menu';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {breakpoint} from 'utils/types';
import LiveMenu from './components/LiveMenu';
import {Tabs, TabsProps} from 'antd';
import {Sheet} from 'react-modal-sheet';
import {useSelector} from 'react-redux';
import {RootState} from 'store';
import {MenuOutlined} from '@ant-design/icons';

const Sider = () => {
	const screenWidth = useScreenWidth();
	const [isOpen, setIsOpen] = useState(false);
	const {isOpen: openModalState} = useSelector((state: RootState) => state.openModalSlice);
	const isMobile = screenWidth < breakpoint.MOBILE;
	const [activeTab, setActiveTab] = useState('1');
	const items: TabsProps['items'] = [
		{
			key: '1',
			label: 'Sport',
			children: (
				<Sheet.Scroller
					style={{height: 'calc(100vh - 195px)', touchAction: 'manipulation'}}
					draggableAt="top">
					<Menu setIsOpen={setIsOpen} />{' '}
				</Sheet.Scroller>
			)
		},
		{
			key: '2',
			label: 'Live',
			children: (
				<Sheet.Scroller
					style={{height: 'calc(100vh - 195px)', touchAction: 'manipulation'}}
					draggableAt="top">
					<LiveMenu setIsOpen={setIsOpen} />
				</Sheet.Scroller>
			)
		}
	];

	const onChange = (key: string) => {
		setActiveTab(key);
	};
	if (screenWidth < breakpoint.DESKTOP) {
		return (
			<>
				{!openModalState && (
					<MenuOutlined
						onClick={() => setIsOpen(true)}
						style={{
							fontSize: isMobile ? 26 : 24,
							color: 'white',
							position: 'fixed',
							left: 7,
							top: isMobile ? 13 : 7
						}}
						className="hamburger-menu-icon"
						onPointerEnterCapture={undefined}
						onPointerLeaveCapture={undefined}
					/>
				)}
				<Sheet
					disableDrag={isOpen}
					className="menu-modal"
					isOpen={isOpen}
					onClose={() => setIsOpen(false)}>
					<Sheet.Container>
						<Sheet.Header onTapStart={() => setIsOpen(false)}></Sheet.Header>
						<Sheet.Content>
							<Tabs
								defaultActiveKey="1"
								activeKey={activeTab}
								items={items}
								onChange={onChange}
							/>
						</Sheet.Content>
					</Sheet.Container>
					<Sheet.Backdrop />
				</Sheet>
			</>
		);
	}
	return (
		<div className={`${screenWidth > breakpoint.DESKTOP ? 'sider-menu' : ''}`}>
			<Menu setIsOpen={setIsOpen} />
		</div>
	);
};

export default memo(Sider, () => true);
