import {Select, Switch} from 'antd';
import {useEffect} from 'react';
import {useTranslation} from 'react-i18next';
import {GoTriangleDown} from 'react-icons/go';
import {useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from 'store';
import {toggleDarkMode} from 'store/slices/darkModeSlice';
import {capitalizeWords} from 'utils/helpers/capitalize';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {breakpoint, themeTypes} from 'utils/types';

const DarkModeHandler = () => {
	const themeModeState = useSelector((state: RootState) => state.darkMode);
	const dispatch = useDispatch<AppDispatch>();
	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);

	const {t} = useTranslation();

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	const selectValues = [
		{
			value: themeTypes.darkMode,
			label: t('darkMode')
		},
		{
			value: themeTypes.lightMode,
			label: t('lightMode')
		}
	];

	const handleToggleDarkMode = () => {
		if (location.pathname.includes('user-bets') || location.pathname.includes('conditions')) {
			dispatch(toggleDarkMode({theme: themeTypes.darkMode}));
			document.querySelector('body')?.setAttribute('data-theme', 'dark');
			return;
		}
		if (themeModeState === themeTypes.lightMode) {
			dispatch(toggleDarkMode({theme: themeTypes.darkMode}));
			document.querySelector('body')?.setAttribute('data-theme', 'dark');
		} else if (themeModeState === themeTypes.darkMode) {
			dispatch(toggleDarkMode({theme: themeTypes.lightMode}));
			document.querySelector('body')?.setAttribute('data-theme', 'light');
		}
	};

	const onSelectChange = (key: string) => {
		if (key === themeTypes.darkMode) {
			dispatch(toggleDarkMode({theme: themeTypes.darkMode}));
			document.querySelector('body')?.setAttribute('data-theme', 'dark');
		} else if (key === themeTypes.lightMode) {
			dispatch(toggleDarkMode({theme: themeTypes.lightMode}));
			document.querySelector('body')?.setAttribute('data-theme', 'light');
		}
	};

	useEffect(() => {
		let themeMode = localStorage.getItem('theme');
		if (!themeMode) {
			dispatch(toggleDarkMode({theme: themeTypes.darkMode}));
			localStorage.setItem('theme', 'darkMode');
			return;
		}
		const theme = themeMode === 'darkMode' ? 'dark' : 'light';
		document.querySelector('body')?.setAttribute('data-theme', theme);
	}, []);

	useEffect(() => {
		if (location.pathname.includes('user-bets') || location.pathname.includes('conditions')) {
			document.querySelector('body')?.setAttribute('data-theme', 'dark');
			return;
		}
	}, [location]);

	if (location.pathname.includes('user-bets')) return null;

	return !isMobile ? (
		<div className="dark-mode-handler">
			<div
				className={`dark-mode-handler__context ${
					themeModeState === themeTypes.darkMode ? 'dark-mode-handler__context_darkmode' : ''
				} header-down-common-text`}>
				{t('darkMode')}
			</div>
			<Switch
				defaultChecked={themeModeState === themeTypes.darkMode}
				onChange={handleToggleDarkMode}
				style={{borderRadius: 2}}
			/>
		</div>
	) : (
		<Select
			options={selectValues}
			onChange={onSelectChange}
			// disabled={isAuthenticated !== authorizationTypes.AUTHORIZED}
			defaultValue={
				localStorage.getItem('theme') === themeTypes.darkMode
					? capitalizeWords(t('darkMode'))
					: capitalizeWords(t('lightMode'))
			}
			bordered={false}
			suffixIcon={<GoTriangleDown color="#868686" />}
		/>
	);
};

export default DarkModeHandler;
