export const currentBetStatus = (
	oddsData: any,
	eventId: number,
	leagueId: number,
	betType: string,
	periodNumber: number,
	contestantId: number | undefined,
	specialId: number | undefined
) => {
	let status = false;
	let key;

	const keys = Object.keys(oddsData);
	for (let i = 0; i < keys.length; i++) {
		key = keys[i];
		const value = oddsData[key];
		if (
			value?.eventId === eventId &&
			value?.type === betType &&
			value?.periodType === periodNumber &&
			value?.contestantId === contestantId &&
			value?.specialId === specialId
		) {
			status = true;
			return {status, key};
		}
	}
	return {status, key};
};
