import {createSlice} from '@reduxjs/toolkit';

const fixturesReducer = createSlice({
	name: 'fixturesReducer',
	initialState: {},
	reducers: {
		setCurrentLeagueFixtures(_, action) {
			return action.payload;
		}
	}
});

export const {setCurrentLeagueFixtures} = fixturesReducer.actions;
export default fixturesReducer.reducer;
