export const deleteLastChar = (value: string | null | undefined) => {
	if (value) {
		const str = value;
		return str.length > 1 ? str.slice(0, -1) : null;
	}
	return null;
};

export const appendKeyToValue = (value: number | string | null | undefined, key: string | number) => {
	const valueStr = value?.toString() ?? '';
	if (key === '.') {
		return valueStr + key.toString();
	} else {
		return valueStr + key.toString();
	}
};

export const handleInputChangeWithCheck = (
	value: string | null,
	activeInput: any,
	handleInputChange: any
) => {
	const {id, coefficent, maxBet} = activeInput ?? {};
	const numericValue = value ? parseFloat(value) : null;
	id && coefficent && maxBet && handleInputChange(numericValue, id, coefficent, maxBet);
};
