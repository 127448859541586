import {OddsProps} from 'utils/interfaces';
import {SingleBetBoxProps} from '../components/SingleBetBox';
import {betType, betTypeKeys, homeTeamType} from 'utils/types';
import {swapTeams} from 'utils/helpers/homeTeamTypeValidator';
import {getHandicapValue} from 'utils/helpers/oddConverter';

// This function is used in src/components/Layout/Betslip/Betslip.tsx
// how this function is used:
// the oddsData is from redux state where we store the bets that the user has placed in betslip and localStorage
// we need to dynamically update the oddsData with the actual rates from the API
// so for every case we need to find the actual rate from the API and update the oddsData
// also we need to find the actual max bet from the API and update the oddsData
// the response is from the API response
// singleGameProps is the current bet that we are updating
// the key is the unique identifier of the bet which is eventId + type + descriptionAddition + marketTitle and it also used in locasl storage and redux state

export const findBetslipActualRates = (
	oddsData: {[K in string]: SingleBetBoxProps},
	response: {payload: OddsProps[]}
) => {
	const actualRates = [] as {
		key: string;
		actualRate: number;
		actualMaxBet: number;
		cutoff?: string;
		status?: string | number;
		liveStatus?: number;
	}[];

	for (let [key, singleGameProps] of Object.entries(oddsData)) {
		const currentGame = swapTeams(
			response?.payload?.find(
				(obj: OddsProps) => obj?.eventId === singleGameProps?.eventId
			) as OddsProps
		);

		const currentGameHomeTeamType = currentGame?.homeTeamType;

		const currentPeriodGameOdds = currentGame?.odd?.periods?.find(
			(period: any) => period?.number === singleGameProps?.periodType
		);

		const key = `${singleGameProps?.eventId} - ${singleGameProps?.type} - ${singleGameProps?.descriptionAddition} - ${singleGameProps?.marketTitle}`;
		if (singleGameProps?.type === betType.MONEYLINE) {
			const predictedClub =
				singleGameProps?.predictedClubType === homeTeamType.TEAM1
					? 'home'
					: singleGameProps?.predictedClubType === homeTeamType.TEAM2
					? 'away'
					: 'draw';
			const actualMaxBet = currentPeriodGameOdds?.maxMoneyline || 0;
			actualRates.push({
				key,
				actualRate: currentPeriodGameOdds?.moneyline?.[predictedClub] || 0,
				actualMaxBet,
				cutoff: currentPeriodGameOdds?.cutoff,
				status: currentPeriodGameOdds?.status,
				liveStatus: currentGame?.liveStatus
			});
		} else if (singleGameProps.type === betType.HANDICAP) {
			const predictedClub = singleGameProps?.predictedClubType === homeTeamType.TEAM1 ? 'home' : 'away';
			actualRates.push({
				key,
				actualRate:
					currentPeriodGameOdds?.spreads?.find(spread =>
						currentGameHomeTeamType === homeTeamType.TEAM1
							? getHandicapValue(
									spread?.hdp,
									'',
									currentGameHomeTeamType,
									predictedClub === 'home'
							  ) === singleGameProps?.point
							: getHandicapValue(
									spread?.hdp,
									'',
									currentGameHomeTeamType,
									predictedClub === 'home'
							  ) === (singleGameProps?.point || 0)
					)?.[predictedClub] || 0,
				actualMaxBet:
					currentPeriodGameOdds?.spreads?.find(spread => spread?.hdp === singleGameProps?.point)
						?.max ||
					currentPeriodGameOdds?.maxSpread ||
					0,
				cutoff: currentPeriodGameOdds?.cutoff,
				status: currentPeriodGameOdds?.status,
				liveStatus: currentGame?.liveStatus
			});
		} else if (singleGameProps.type === betType.TOTAL) {
			const predictedClub = singleGameProps?.side === betTypeKeys.OVER ? 'over' : 'under';
			actualRates.push({
				key,
				actualRate:
					currentPeriodGameOdds?.totals?.find(total => total?.points === singleGameProps?.point)?.[
						predictedClub
					] || 0,
				actualMaxBet:
					currentPeriodGameOdds?.totals?.find(total => total?.points === singleGameProps?.point)
						?.max ||
					currentPeriodGameOdds?.maxTotal ||
					0,
				cutoff: currentPeriodGameOdds?.cutoff,
				status: currentPeriodGameOdds?.status,
				liveStatus: currentGame?.liveStatus
			});
		} else if (singleGameProps.type === betType.TEAM_TOTAL) {
			const predictedClub = singleGameProps?.predictedClubType === homeTeamType.TEAM1 ? 'home' : 'away';
			const predictedSide = singleGameProps?.side === betTypeKeys.OVER ? 'over' : 'under';
			actualRates.push({
				key,
				actualRate: currentPeriodGameOdds?.teamTotal?.[predictedClub]?.[predictedSide] || 0,
				actualMaxBet: currentPeriodGameOdds?.maxTeamTotal || 0,
				cutoff: currentPeriodGameOdds?.cutoff,
				status: currentPeriodGameOdds?.status,
				liveStatus: currentGame?.liveStatus
			});
		}
	}
	return actualRates;
};
