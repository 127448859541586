import useScreenWidth from 'utils/hooks/useScreenWidth';
import './../LiveOverview.less';
import {betType, breakpoint} from 'utils/types';

const LiveBetGridHeader = ({
	defaultFields,
	currentSelectedBetType
}: {
	defaultFields: {handicap: boolean; draw: boolean};
	currentSelectedBetType?: string;
}) => {
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	const showCols = {
		moneyline: currentSelectedBetType === betType.MONEYLINE || !isMobile,
		handicap: currentSelectedBetType === betType.HANDICAP || !isMobile,
		total: currentSelectedBetType === betType.TOTAL || !isMobile
	};

	return (
		<thead className="ant-table-thead nearby-games-grid-header">
			<tr>
				<th
					style={{width: '100%', paddingLeft: 10}}
					className="ant-table-cell first-table-col-header"
					scope="col">
					<div className="league-container-name-icon-wrapper"></div>
				</th>
				{showCols.moneyline && (
					<th className="ant-table-cell" scope="col">
						{defaultFields.draw ? (
							<div className="grid-column grid-column__hint">
								<div className="bet-type-hint">1</div>
								<div className="bet-type-hint">X</div>
								<div className="bet-type-hint">2</div>
							</div>
						) : (
							<div className="grid-column grid-column__hint">
								<span className="bet-type-hint">MONEYLINE</span>
							</div>
						)}
					</th>
				)}

				{showCols.handicap && (
					<th className="ant-table-cell" scope="col">
						<div className="grid-column grid-column__hint">
							<span className="bet-type-hint bet-type-hint-hendicap">HANDICAP</span>
						</div>
					</th>
				)}
				{showCols.total && (
					<th className="ant-table-cell" scope="col">
						<div className="grid-column grid-column__hint">
							<div className="bet-type-hint">OVER</div>
							<div className="bet-type-hint">UNDER</div>
						</div>
					</th>
				)}
			</tr>
		</thead>
	);
};

export default LiveBetGridHeader;

/* screenWidth >= 576 ? (
		<div className="live-bet-grid-header">
			<div className="live-bet-grid-header__empty"></div>
			<div className="header-texts-wrapper">
				{defaultFields.draw ? (
					<div className="header-text-title header-text-title__3-lines">
						<div className="header-text-title__title">1</div>
						<div className="header-text-title__title">X</div>
						<div className="header-text-title__title">2</div>
					</div>
				) : (
					<div className="header-text-title header-text-title__2-lines">
						<div className="header-text-title__title">
							<span>MONEYLINE</span>
						</div>
					</div>
				)}
				{defaultFields.handicap && screenWidth > 630 && (
					<div className="header-text-title header-text-title__2-lines">
						<div className="header-text-title__title">
							<span>HANDICAP</span>
						</div>
					</div>
				)}
				{((screenWidth > 870 && screenWidth < 950) || screenWidth > 1100) && (
					<div className="header-text-title header-text-title__2-lines">
						<div className="header-text-title__title">OVER</div>
						<div className="header-text-title__title">UNDER</div>
					</div>
				)}
				<div className="market-count-div__empty" />
			</div>
		</div>
	) : ( */
