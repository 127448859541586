const dateFormat = (date: string) => {
	return new Date(date).toLocaleString('en-US', {
		month: 'long',
		day: '2-digit',
		hour: 'numeric',
		minute: 'numeric',
		hour12: true
	});
};

export default dateFormat;

export const hoursFromDate = (date: string) => {
	const parsedDate = new Date(date);
	const hours = parsedDate.getHours();
	const minutes = parsedDate.getMinutes();
	const amOrPm = hours >= 12 ? 'PM' : 'AM';
	const formattedHours = hours % 12 === 0 ? 12 : hours % 12;
	const formattedMinutes = minutes < 10 ? `0${minutes}` : minutes;
	return `${formattedHours}:${formattedMinutes} ${amOrPm}`;
};

export const dateFormatOnlyNumbers = (date: string) => {
	const parsedDate = new Date(date);
	const year = parsedDate.getFullYear();
	const month = (parsedDate.getMonth() + 1).toString().padStart(2, '0');
	const day = parsedDate.getDate().toString().padStart(2, '0');
	const hours = parsedDate.getHours().toString().padStart(2, '0');
	const minutes = parsedDate.getMinutes().toString().padStart(2, '0');

	return `${month}.${day}.${year}, ${hours}:${minutes}`;
};
