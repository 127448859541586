import {useEffect, useState} from 'react';
import {themeTypes} from 'utils/types';

export interface DateFormatOptions {
	year?: 'numeric' | '2-digit';
	month?: 'numeric' | '2-digit';
	day?: 'numeric' | '2-digit';
	hour?: 'numeric' | '2-digit';
	minute?: 'numeric' | '2-digit';
	second?: 'numeric' | '2-digit';
}

const Timer = ({darkMode}: {darkMode: keyof typeof themeTypes}) => {
	const [time, setTime] = useState<Date>(new Date());

	const options: DateFormatOptions = {
		year: '2-digit',
		month: '2-digit',
		day: '2-digit',
		hour: '2-digit',
		minute: '2-digit',
		second: '2-digit'
	};

	useEffect(() => {
		const interval = setInterval(() => {
			setTime(new Date());
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	const formattedDate = new Intl.DateTimeFormat('en-US', options).format(time);
	const formattedTimeZone = ` (GMT + 04:00)`;
	const formattedResult = `${formattedDate.replace(',', '  ').replace('PM', '')}${formattedTimeZone}`;

	return (
		<div
			className={`timer-wrapper timer-wrapper__${
				darkMode === themeTypes.darkMode ? 'darkmode' : 'lightmode'
			} header-down-common-text`}>
			{formattedResult}
			{/* <span className="timer-active">{time.toLocaleTimeString()}</span>
			<span>(GMT +04:00)</span> */}
		</div>
	);
};

export default Timer;
