import {Input} from 'antd';
import NumberInput, {numberWithCommas} from 'components/CustomNumberInput/CustomNumberInput';
import Notification, {NotificationProps} from 'components/Notifications/Notification';
import _ from 'lodash';
import {useCallback, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {GrFormClose} from 'react-icons/gr';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {RootState} from 'store';
import {deleteSingleBet} from 'store/slices/createBetSlice';
import {dateFormatOnlyNumbers} from 'utils/helpers/dateFormat';
import {formatValue, handleInputValidation} from 'utils/helpers/inputNumberConverter';
import {oddConverterToShow} from 'utils/helpers/oddConverter';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {MIN_BET_AMOUNT, StatusEnum, actionTypes, authorizationTypes, betType, breakpoint} from 'utils/types';
import './../Betslip.less';
import {addThousandSeperator} from 'utils/helpers/addThousandSeperator';
import LiveIcon from 'views/LiveOverview/components/LiveIcon';

export interface SingleBetBoxProps {
	specialId?: number;
	contestantId?: number;
	clubs: {
		club1: string;
		club2: string;
	};
	startsAt: string;
	type: string;
	predictedClub: string;
	coefficent: number;
	leagueName: string;
	id: string;
	onInputChange: (
		event: number | null,
		key: string,
		coefficent: number,
		maxBet: number
	) => void | undefined;
	inputValue: number | null;
	handleSingleInputDeletion: (id: string) => void;
	indicator: number | string;
	multiple?: boolean;
	eventId: number;
	parentId: number;
	maxBet: number;
	marketTitle: string;
	category?: string;
	modifiedCoefficent: number;
	coefficentToShow: string;
	onMaxBetClick: (id: string, maxBet: number, coefficent: number) => void;
	futuresId?: string;
	leagueId: string;
	periodType?: number | string;
	side?: string;
	predictedClubType?: 'Team1' | 'Team2' | 'Draw';
	point?: number;
	successBet?: boolean;
	errorMessage?: string | null;
	actualRate?: number | null;
	actualMaxBet?: number | null;
	openNumpad: (id: string, coefficent: number, maxBet: number) => void;
	descriptionAddition?: any;
	initialPlacedRate: number;
	initialPlacedMaxBet: number;
	isLiveOverview: boolean;
	isBetOpen: boolean;
}

const SingleBetBox = ({
	clubs,
	startsAt,
	type,
	predictedClub,
	coefficent,
	leagueName,
	id,
	onInputChange,
	inputValue,
	handleSingleInputDeletion,
	indicator,
	multiple,
	maxBet,
	marketTitle,
	category,
	modifiedCoefficent,
	coefficentToShow,
	eventId,
	successBet,
	errorMessage,
	onMaxBetClick,
	actualRate,
	openNumpad,
	initialPlacedRate,
	isLiveOverview,
	isBetOpen
}: SingleBetBoxProps) => {
	const dispatch = useDispatch();
	const [notification, setNotification] = useState<NotificationProps>({
		notificationType: '',
		notificationLabel: ''
	});

	const oddsData = useSelector((state: RootState) => state.createbetSlice) as {
		[K in string]: SingleBetBoxProps;
	};
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth <= breakpoint.MOBILE;

	const validatedCoefficient =
		actualRate && errorMessage ? actualRate : coefficent ? coefficent : initialPlacedRate;

	const pickedIndicator = type === betType.HANDICAP || betType.TOTAL ? indicator : predictedClub;

	const {isAuthenticated} = useSelector((state: RootState) => state.userAuth);

	const {t} = useTranslation();

	const navigate = useNavigate();

	const handleInputs = (value: number | null | any) => {
		if (value !== undefined && value !== null) {
			// if (value == '' || isNaN(value)) return 0;
			onInputChange(value, id, validatedCoefficient, maxBet);
		}
	};

	const oddType = useSelector((state: RootState) => state.oddsTypeSlice);

	const handleSingleBetDeletion = (id: string) => {
		dispatch(deleteSingleBet({id}));
		handleSingleInputDeletion(id);
	};

	const handleNotifications = useCallback(() => {
		if (!isBetOpen) {
			setNotification(actionTypes.CLOSED_BET as NotificationProps);
			return;
		}
		if (!inputValue) {
			setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
			return;
		}
		if (inputValue && inputValue > maxBet) {
			setNotification(actionTypes.ABOVE_MAX as NotificationProps);
			return;
		} else if (inputValue && inputValue < maxBet) {
			setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
			return;
		}
		if (inputValue && inputValue < MIN_BET_AMOUNT) {
			setNotification(actionTypes.MINIMUM_STAKE as NotificationProps);
			return;
		} else if (inputValue && inputValue > MIN_BET_AMOUNT) {
			setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
			return;
		}
		setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
	}, [isBetOpen, oddsData, inputValue]);

	useEffect(() => {
		handleNotifications();
	}, [inputValue, isBetOpen, oddsData]);

	useEffect(() => {
		return () => {
			setNotification(actionTypes.CLEAR_ACTION as NotificationProps);
		};
	}, [oddsData]);

	const notificationType = successBet
		? StatusEnum.SUCCESS
		: errorMessage
		? StatusEnum.WARNING
		: notification.notificationType;

	const notificationLabel = successBet
		? t('betAccepted')
		: errorMessage
		? errorMessage
		: notification.notificationLabel;

	const isRateChanged = coefficent !== initialPlacedRate;
	const [isMobiles, setIsMobile] = useState(false);

	useEffect(() => {
		setIsMobile(/iPhone|iPad|iPod|Android/i.test(navigator.userAgent));
	}, []);

	const handleInput = (event: any) => {
		let inputValue = event.target.value;

		inputValue = inputValue.replace(/[^\d.]/g, '');
		if ((inputValue.match(/\./g) || []).length > 1) {
			const lastIndex = inputValue.lastIndexOf('.');
			inputValue = inputValue.slice(0, lastIndex) + inputValue.slice(lastIndex + 1);
		}

		event.target.value = inputValue;
	};

	const handleMobileInput = (event: any) => {
		let inputValue = event.target.value;
		if (event.nativeEvent.inputType === 'deleteContentBackward' || inputValue.includes('.')) {
			return;
		}
		inputValue = inputValue.replace(/,/g, ',');
		if ((inputValue.match(/\,/g) || []).length > 1) {
			inputValue = inputValue.slice(0, inputValue.lastIndexOf('.'));
		}
		event.target.value = inputValue;
	};

	return (
		<div className="single-bet-box-wrapper">
			<div className="single-bet-box-header">
				<div
					className="club-names-wrapper"
					onClick={() =>
						navigate(
							`/bettings/single/${eventId}${isLiveOverview ? '?isLive=true' : ''}/#period:-1`
						)
					}>
					{category ? (
						<>
							{isLiveOverview && <LiveIcon />}
							<span>{category}</span>
						</>
					) : (
						<>
							{isLiveOverview && <LiveIcon />}
							<span>
								{clubs.club1?.toString().replace('(Sets)', '')} -vs-{' '}
								{clubs.club2?.toString().replace('(Sets)', '')}
							</span>
						</>
					)}
				</div>
				<GrFormClose
					className="single-bet-box-close-btn"
					onClick={() => handleSingleBetDeletion(id)}
				/>
			</div>
			{(!_.every(notification, _.isEmpty) || errorMessage) && (
				<Notification notificationLabel={notificationLabel} notificationType={notificationType} />
			)}
			<div className="sport-bet-info-box-wrapper">
				<div className="prediction-info">
					<span className="date-coefficent-text-info">
						<span className="date-coefficent-text-info__date">
							{dateFormatOnlyNumbers(startsAt)}
						</span>
					</span>
					<span className="prediction-info__text prediction-info__league-name ">{leagueName}</span>
					<span className="prediction-info__text prediction-info__indicator">
						{marketTitle?.toUpperCase()}
					</span>
					<span className="prediction-info__text">
						{/* <span className="prediction-info__pick-hint">PICK: </span>{' '} */}
					</span>
				</div>
				<div className="date-coefficent-wrapper">
					<span className="prediction-info__picked-item">
						{pickedIndicator.toString().replace('(Sets)', '')}
					</span>
					<div className="coefficents-wrapper">
						<div
							className={`coefficent-wrapper-box ${
								coefficent && coefficent != 0 && isRateChanged
									? 'coefficent-wrapper-box__rate-changed'
									: ''
							}`}>
							<span className="date-coefficent-text-info">
								<span className="date-coefficent-text-info__rate">
									{oddConverterToShow(initialPlacedRate, oddType)}
								</span>
							</span>
						</div>
						{coefficent && coefficent != 0 && isRateChanged ? (
							<div className="coefficent-wrapper-box">
								<span className="date-coefficent-text-info">
									<span className="date-coefficent-text-info__rate">
										{oddConverterToShow(coefficent, oddType)}
									</span>
								</span>
							</div>
						) : null}
					</div>
				</div>
			</div>
			{!multiple && (
				<div className="gaining-counter-wrapper">
					<Input
						id="numberInput"
						type="text"
						readOnly={isMobile}
						// @ts-ignore
						// onClick={isMobile ? () => : () => {}}
						value={inputValue}
						onFocus={() => {
							openNumpad(id, coefficent, maxBet);
						}}
						inputMode={isMobile ? 'decimal' : 'numeric'}
						onInput={isMobile ? handleMobileInput : handleInput}
						onChange={e => {
							handleInputs(e.target.value || 0);
						}}
						onKeyDown={e => {
							const allowedKeys = ['.', ',', 'ArrowRight', 'Delete', 'Backspace', 'Tab'];
							if (!allowedKeys.includes(e.key) && !/^\d$/.test(e.key)) {
								e.preventDefault();
							}
						}}
						status={inputValue && inputValue > maxBet ? StatusEnum.ERROR : undefined}
						prefix={'$'}
						min={0}
						className="bets-input"
						name={`single-bet-input-${id}`}
					/>

					<div className="gaining-counter-hints-wrapper">
						<span className="gaining-counter-hint">
							{t('return')}:{' '}
							<span className="gaining-counter-hint">
								$
								{inputValue
									? addThousandSeperator?.((inputValue * validatedCoefficient)?.toFixed(2))
									: '0.00'}
							</span>
						</span>
						{isAuthenticated === authorizationTypes.AUTHORIZED && (
							<span className="gaining-counter-hint">
								Max. Bet:{' '}
								<span
									className="gaining-counter-hint__underlined"
									onClick={() =>
										!isMobile
											? onMaxBetClick(id, maxBet, validatedCoefficient)
											: undefined
									}>
									${addThousandSeperator?.(maxBet?.toFixed(2))}
								</span>
							</span>
						)}
					</div>
				</div>
			)}
		</div>
	);
};

export default SingleBetBox;
