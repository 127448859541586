import {notification} from 'antd';
import './index.less';

notification.config({
	maxCount: 1
});

const toast = ({text, type}: {text: string; type: 'success' | 'error'}) => {
	notification.info({
		message: text,
		// description: text,
		placement: 'top' as any
		// icon:
		// 	type === 'success' ? (
		// 		<CheckCircle color="#002494" size={30} weight="fill" />
		// 	) : (
		// 		<XCircle color="#CE1126" size={30} weight="fill" />
		// 	)
	});
};

export default {
	success: (text: string) => toast({type: 'success', text}),
	error: (text: string) => toast({type: 'error', text})
};
