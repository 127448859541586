export const MIN_BET_AMOUNT = 1;
export const MAX_MULTIPLE_WIN_AMOUNT = 100000;
export const MULTIPLE_MAX_BET = 1000;
export const COEFFICENT_LIMIT = 1000;

export const betType = {
	HANDICAP: 'Spread',
	TOTAL: 'Total',
	MONEYLINE: 'Moneyline',
	TEAM_TOTAL: 'TeamTotal',
	SPECIAL_FIXTURE: 'Special',
	LEAGUE_SPECIAL: 'Special'
};

export const betTypeKeys = {
	OVER: 'OVER',
	UNDER: 'UNDER',
	DRAW: 'DRAW'
};

export const themeTypes = {
	darkMode: 'darkMode',
	lightMode: 'lightMode'
};

export const betsConnectionTypes = {
	MULTIPLE: 'MULTIPLE',
	SINGLE: 'SINGLE'
};

export const actionTypes = {
	ABOVE_MAX: {
		notificationType: 'warning',
		notificationLabel: 'Above maximum stake'
	},
	MINIMUM_STAKE: {
		notificationType: 'warning',
		notificationLabel: 'Minimum stake amount is $1'
	},
	UNAVAILABLE_ODD: {
		notificationType: 'error',
		notificationLabel: 'Odds unavailable'
	},
	ACCEPTED_BET: {
		notificationType: 'success',
		notificationLabel: 'Bet accepted'
	},
	CLEAR_ACTION: {
		notificationType: '',
		notificationLabel: ''
	},
	COEFFICENT_OVERFLOW: {
		notificationType: 'warning',
		notificationLabel: 'Coeffcent overflow'
	},
	CLOSED_BET: {
		notificationType: 'warning',
		notificationLabel: 'Bet is not open'
	}
};

export const menuTypes = {
	SIDER_MENU: 'sider-menu',
	SWIPER_MENU: 'swiper-menu',
	NON_SELECTED: 'non-selected'
};

export const authorizationTypes = {
	AUTHORIZED: 'authorized',
	UNAUTHORIZED: 'unauthorized',
	UNDEFINED: 'undefined'
};

export const periodTypes = {
	MATCH: '0',
	FIRST_HALF: '1',
	SECOND_HALF: '2',
	LEAGUE_SPECIAL_PERIOD: 'leagueSpecialPeriod',
	SPECIAL_PERIOD: 'specialPeriod'
};

export const windowLocationHref = {
	PERIOD: 'period',
	LEAGUE_TYPE: 'leagueType',
	SPECIAL_TYPE: 'specialType'
};

export const filterType = {
	GAME: 'game',
	LEAGUE: 'league'
};

export const collapseShowType = {
	INITIAL: 'initial',
	SHOW_ALL: 'show_all',
	HIDE_ALL: 'hide_all'
};

export const modalTypes = {
	SIGN_UP: 'signUp',
	REGISTRATION: 'registration',
	RESET_PASSWORD: 'resetPassword',
	LOGIN: 'login'
};

export const betModalTypes = {
	DEPOSIT: 'DEPOSIT',
	WITHDRAW: 'WITHDRAW',
	INITIAL_STATE: 'INITIAL_STATE'
};

export const collapseContentType = {
	ORDINAR_FIXTURES: 'ordinar',
	SPECIAL_FIXTURES: 'special',
	SPECIAL_LEAGUE_FIXTURES: 'sprecialLeagueFixtures'
};

export const historyTypes = {
	OPEN_BETS: 'open_bets',
	ALL_BETS: 'all_bets'
};

export const accomplishedBetTypes = {
	UNSETTLED: 'UNSETTLED',
	WIN: 'WIN',
	LOSE: 'LOST'
};

export const homeTeamType = {
	TEAM1: 'Team1',
	TEAM2: 'Team2',
	DRAW: 'Draw'
};

export const oddTypes = {
	DECIMAL: 'decimal',
	AMERICAN_ODD: 'americanOdd'
};

export const placedStatusType = {
	STARTED: 'STARTED',
	ENDED: 'ENDED',
	NOT_STARTED: 'NOT STARTED'
};

export const breakpoint = {
	MIDDLE_SCREEN: 950,
	PERSONAL_DETAILS_BREAKPOINT: 900,
	TABLET: 768,
	MOBILE: 600,
	DESKTOP: 1200
};

export const numpadActionTypes = {
	DELETE: 'delete',
	ADD: 'add'
};

export const CONDITIONS_PAGE_PATHNAME = '/conditions';

export enum PlaceBetResponseStatusEnum {
	ACCEPTED = 'ACCEPTED',
	NOT_FOUND = 'NOT_FOUND',
	RATE_CHANGED = 'RATE_CHANGED',
	CANCELED = 'CANCELED'
}

export enum StatusEnum {
	SUCCESS = 'success',
	ERROR = 'error',
	WARNING = 'warning'
}

export enum BetStatusEnum {
	ACCEPTED = 'ACCEPTED',
	NOT_ACCEPTED = 'NOT_ACCEPTED',
	NOT_FOUND = 'NOT_FOUND',
	RATE_CHANGE = 'RATE_CHANGED',
	CANCELED = 'CANCELED',
	ABOVE_MAX_BET = 'ABOVE_MAX_BET',
	LOST = 'LOST',
	REFUNDED = 'REFUNDED',
	WON = 'WON'
}

export enum TransactionStatusEnum {
	PENDING = 'Pending',
	COMPLETED = 'Completed',
	FAILED = 'Failed',
	ABORTED = 'Aborted'
}

export enum CurrencyEnum {
	BTC = 'BTC',
	LTC = 'LTC',
	ETH = 'ETH',
	AMD = 'AMD',
	USD = 'USD',
	RUB = 'RUB'
}

export enum VerificationStatusEnum {
	PENDING = 'pending',
	VERIFIED = 'verified',
	DECLINED = 'declined'
}

export enum VerificationTypeEnum {
	IDENTITY = 'identity',
	ADDRESS = 'address',
	BANK_CARD = 'bankCard'
}

export enum accomplishedBetStatusEnum {
	ACCEPTED = 'ACCEPTED',
	NOT_ACCEPTED = 'NOT_ACCEPTED',
	NOT_FOUND = 'NOT_FOUND',
	RATE_CHANGE = 'RATE_CHANGED',
	CANCELED = 'CANCELED',
	ABOVE_MAX_BET = 'ABOVE_MAX_BET',
	LOST = 'LOST',
	REFUNDED = 'REFUNDED',
	WON = 'WON',
	UNSETTLED = 'UNSETTLED',
	PARTIAL_LOST = 'PARTIAL_LOST',
	PARTIAL_WON = 'PARTIAL_WON'
}

export type AcceptedCurrencies = 'BTC' | 'LTC' | 'ETH' | 'AMD' | 'USD' | 'RUB';

export const betCloseStatus = 2;
export const specialBetCloseStatus = 'H';

export const liveIdentificator = 1;
export const prematchIdentificator = 2;
