import {Col, Row} from 'antd';
import {AiOutlineRight} from 'react-icons/ai';
import {IoIosArrowForward} from 'react-icons/io';
import {RiArrowDropRightLine} from 'react-icons/ri';
import {useNavigate} from 'react-router-dom';
import {initialSpan} from 'utils/helpers/initialSpan';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {betType, breakpoint} from 'utils/types';

interface NestedGridHeaderProps {
	container: string;
	defaultFields: {
		handicap: boolean;
		draw: boolean;
	};
	eventId: number;
	leagueId: string;
	currentSelectedBetType?: string;
}

const NestedGridHeader = ({
	container,
	defaultFields,
	eventId,
	leagueId,
	currentSelectedBetType
}: NestedGridHeaderProps) => {
	const navigate = useNavigate();
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	const showCols = {
		moneyline: currentSelectedBetType === betType.MONEYLINE || !isMobile,
		handicap: currentSelectedBetType === betType.HANDICAP || !isMobile,
		total: currentSelectedBetType === betType.TOTAL || !isMobile
	};

	return (
		<>
			<thead className="ant-table-thead nearby-games-grid-header">
				<tr>
					<th
						style={{width: '100%', paddingLeft: 10}}
						className="ant-table-cell first-table-col-header"
						scope="col">
						<div
							className="league-container-name-icon-wrapper"
							style={
								showCols.moneyline
									? {width: screenWidth < 450 ? `${screenWidth - 270}px` : 'auto'}
									: {width: screenWidth < 450 ? `${screenWidth - 190}px` : 'auto'}
							}
							onClick={e => {
								e.stopPropagation();
								navigate(`/bettings/${leagueId}#fixturesType:events`);
							}}>
							<span className="league-container-name">{container}</span>
							<IoIosArrowForward color="#0079ff" fontSize={10} className="navigator-arrow" />
						</div>
					</th>
					{showCols.moneyline && (
						<th className="ant-table-cell" scope="col">
							{defaultFields.draw ? (
								<div className="grid-column grid-column__hint">
									<span className="bet-type-hint">1</span>
									<span className="bet-type-hint">X</span>
									<span className="bet-type-hint">2</span>
								</div>
							) : (
								<div className="grid-column grid-column__hint">
									<span className="bet-type-hint">MONEYLINE</span>
								</div>
							)}
						</th>
					)}

					{showCols.handicap && (
						<th className="ant-table-cell" scope="col">
							<div className="grid-column grid-column__hint">
								<span className="bet-type-hint bet-type-hint-hendicap">HANDICAP</span>
							</div>
						</th>
					)}
					{showCols.total && (
						<th className="ant-table-cell" scope="col">
							<div className="grid-column grid-column__hint">
								<span className="bet-type-hint">OVER</span>
								<span className="bet-type-hint">UNDER</span>
							</div>
						</th>
					)}
				</tr>
			</thead>

			{/* <Row gutter={16} className="nearby-games-grid-header">
				<Col
					span={initialSpan(defaultFields)}
					xl={initialSpan(defaultFields)}
					lg={initialSpan(defaultFields)}
				/>
				<Col
					span={(defaultFields.draw ? 7 : 5) + (defaultFields.handicap ? 5 : 0) + 5}
					xl={(defaultFields.draw ? 7 : 5) + (defaultFields.handicap ? 5 : 0)}
					lg={(defaultFields.draw ? 10 : 8) + (defaultFields.handicap ? 6 : 0)}
					md={defaultFields.draw ? 10 : 8}
					sm={(defaultFields.draw ? 10 : 8) + (defaultFields.handicap ? 6 : 0)}>
					<div className="divider" />
				</Col>
				<Col span={1} xl={1} />
			</Row> */}
		</>
	);
};

export default NestedGridHeader;
