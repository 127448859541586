import {Divider} from 'antd';
import './Popup.less';

const EmailVerification = () => {
	return (
		<div className="email-verification-modal-wrapper">
			<div className="email-verification-hints-wrapper">
				<span className="email-verification-hints-wrapper__info email-verification-hints-wrapper__info_bold">
					One more step
				</span>
				<div className="email-verification-regular-hints">
					<span className="email-verification-hints-wrapper__info">
						We have sent a verification letter to your email address. Please go to your inbox to
						finish creating your account
					</span>
					<span className="email-verification-hints-wrapper__info ">
						{`Please note that you can't access your account until your email address is verified.`}
					</span>
				</div>
			</div>
			<div className="helper-wrapper">
				<Divider style={{width: '100%', backgroundColor: '#262626', margin: 5}} />
				<span className="helper-wrapper__text">Need help? Contact Customer Service</span>
				<span className="helper-wrapper__text helper-wrapper__text_bold">help@tangerbet.com</span>
			</div>
		</div>
	);
};

export default EmailVerification;
