import {themeTypes} from 'utils/types';
import './../Header.less';

interface TabProps {
	darkMode: keyof typeof themeTypes;
	id: number;
	text: string;
	onClick: (id: number) => void;
	isActive: boolean;
}

const Tab = ({darkMode, id, text, onClick, isActive}: TabProps) => {
	return (
		<div
			onClick={() => onClick(id)}
			className={`header-down__route  ${isActive ? 'header-down__route_active' : ''} ${
				darkMode === themeTypes.darkMode ? 'header-down__route_darkmode' : ''
			}`}>
			{text}
		</div>
	);
};

export default Tab;
