import {Tooltip} from 'antd';
import './../Betslip.less';
import {betsConnectionTypes} from 'utils/types';

interface TypeHandlerButtonProps {
	isActive: boolean;
	keyProp: string;
	onClick: (key: string) => void;
	label: string;
	tooltipOpen?: boolean;
	tooltipTitle?: string;
	multiplePermission?: boolean;
	type: 'betting' | 'history';
}

const TypeHandlerButton = ({
	isActive,
	keyProp,
	onClick,
	label,
	tooltipOpen,
	tooltipTitle,
	type
}: TypeHandlerButtonProps) => {
	const handleClick = () => {
		if (type === 'betting') {
			((!tooltipOpen && keyProp === betsConnectionTypes.MULTIPLE) ||
				keyProp === betsConnectionTypes.SINGLE) &&
				onClick(keyProp);
		} else if (type === 'history') {
			onClick(keyProp);
		}
	};

	return (
		<Tooltip
			trigger={'click'}
			title={tooltipTitle}
			open={!tooltipOpen ? false : undefined}
			color="#0079ff">
			<div
				className={`bet-type-handler-button ${isActive ? 'bet-type-handler-button__active' : ''}`}
				onClick={handleClick}>
				<span className="bet-type-handler-button__label">{label}</span>
			</div>
		</Tooltip>
	);
};

export default TypeHandlerButton;
