import {oddTypes} from 'utils/types';
import {SingleBetBoxProps} from '../components/SingleBetBox';
import {oddConverter} from 'utils/helpers/oddConverter';

export const changeInputValues = (
	inputValues: {
		[id: string]: {amount: number | null; coefficent: number; gaining: number | null; maxBet: number};
	},
	oddsData: {
		[K in string]: SingleBetBoxProps;
	},
	oddType: string,
	setInputValues: (value: any) => void
) => {
	const newInputValues = {...inputValues} as {
		[id: string]: {amount: number | null; coefficent: number; gaining: number | null; maxBet: number};
	};
	for (let [key, value] of Object.entries(oddsData)) {
		const convertedCoef =
			oddType === oddTypes.AMERICAN_ODD ? oddConverter(value?.coefficent, oddType) : value?.coefficent;
		const amount = inputValues[key]?.amount;
		if (inputValues[key]) {
			newInputValues[key].coefficent = convertedCoef;
			newInputValues[key].maxBet = value?.maxBet;
			newInputValues[key].gaining = amount !== null ? +(amount * value?.coefficent).toFixed(2) : null;
		}
	}
	setInputValues(newInputValues);
};
