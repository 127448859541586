import {useSelector} from 'react-redux';
import {RootState} from 'store';
import './../Betslip.less';

interface BetsTabProps {
	tab: string;
	betsCount?: number | string;
	id: number;
	onClick: (id: number) => void;
	isActive: boolean;
}

const BetslipTab = ({tab, betsCount, id, onClick, isActive}: BetsTabProps) => {
	const darkMode = useSelector((state: RootState) => state.darkMode);

	const handleOnClick = () => {
		onClick(id);
	};

	return (
		<div className={`betslip-tab ${isActive ? 'betslip-tab__active' : ''} `} onClick={handleOnClick}>
			<span
				className={`betslip-tab__text ${isActive ? 'betslip-tab__text_active' : ''} ${
					darkMode && isActive
						? 'betslip-tab__text__darkmode_active'
						: darkMode
						? 'betslip-tab__text__darkmode'
						: ''
				}`}>
				{tab}
			</span>
			{betsCount && (
				<div
					className={`betslip-tab__bets-count ${isActive ? 'betslip-tab__bets-count_active' : ''}`}>
					<span>{betsCount}</span>
				</div>
			)}
		</div>
	);
};

export default BetslipTab;
