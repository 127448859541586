import {Button, ConfigProvider, Result} from 'antd';
import {OddsHandlePanelProps} from 'components/Layout/Betslip/components/OddsHandlePanel';
import Helmet from 'components/Layout/Header';
import LoaderSimple from 'components/Loading/LoaderSimple';
import {PrivateRoute} from 'components/PrivateRoute';
import {Suspense, useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Route, Routes, useNavigate} from 'react-router-dom';
import {routes} from 'routes';
import {RootState} from 'store';
import {useLazyGetUserInfoQuery, useLazyGetWebsiteDataQuery} from 'store/services/authAPI';
import {setUser, setUserCredentials} from 'store/slices/authSlice';
import {deleteSingleBet} from 'store/slices/createBetSlice';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {CONDITIONS_PAGE_PATHNAME, authorizationTypes, breakpoint, themeTypes} from 'utils/types';
import './App.less';
import Layout from './components/Layout';

const App = () => {
	const [authorizationStatus, setAuthorizationStatus] = useState(false);
	const [getUserInfo, {data}] = useLazyGetUserInfoQuery();
	const [getWebsiteData] = useLazyGetWebsiteDataQuery();
	const [websiteData, setWebsiteData] = useState<any>();
	const {isAuthenticated, userCredentials} = useSelector((state: RootState) => state.userAuth);
	const navigate = useNavigate();
	const oddsData = useSelector((state: RootState) => state.createbetSlice) as OddsHandlePanelProps;
	const dispatch = useDispatch();
	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.MOBILE;

	const currentCouterString = localStorage.getItem('POT');
	const currentCouter = currentCouterString && JSON.parse(currentCouterString);
	const [counter, setCounter] = useState(currentCouter?.counter && 600);

	const handleWebsiteData = async () => {
		const response = await getWebsiteData({});
		setWebsiteData(response?.data?.payload);
	};
	useEffect(() => {
		handleWebsiteData();
	}, []);

	useEffect(() => {
		document.title = websiteData?.title;
		localStorage.setItem('language', websiteData?.defaultLanguage.toLowerCase());
		localStorage.setItem('oddType', websiteData?.defaultOddsFormat);
		localStorage.setItem('bannerImage', websiteData?.bannerImage);
		localStorage.setItem('bannerLink', websiteData?.bannerLink);

		websiteData?.logo && localStorage.setItem('logo', websiteData?.logo);
	}, [websiteData]);

	useEffect(() => {
		return () => {
			if (!location.pathname.includes('conditions' || 'user-bets')) {
				localStorage.removeItem('termsAndConditions');
			}
		};
	}, [location.pathname]);

	useEffect(() => {
		const fetchData = async () => {
			try {
				const userCredentialsData = await getUserInfo({}).unwrap();
				const modifiedUserCredentials = setUserCredentials(userCredentialsData);
				dispatch(
					setUser({
						isAuthenticated: authorizationTypes.AUTHORIZED,
						userCredentials: modifiedUserCredentials
					})
				);
				setAuthorizationStatus(true);
			} catch (e) {
				dispatch(setUser({isAuthenticated: authorizationTypes.UNAUTHORIZED, userCredentials: null}));
				setAuthorizationStatus(false);
			}
		};
		fetchData();
		// const intervalId = setInterval(() => {
		// 	isAuthenticated === authorizationTypes.AUTHORIZED &&
		// 		!location.pathname.includes('/user-bets/details') &&
		// 		fetchData();
		// }, 10000);
		const intervalId = setInterval(() => {
			if (
				isAuthenticated === authorizationTypes.AUTHORIZED &&
				!location.pathname.includes('/user-bets/details')
			) {
				fetchData(); // Call fetchData every 10 seconds if the conditions are met
			}
		}, 10000);
		return () => {
			clearInterval(intervalId);
		};
	}, [isAuthenticated, location.pathname]);

	useEffect(() => {
		if (location.pathname.includes('user-bets') || location.pathname.includes('conditions')) {
			document.querySelector('body')?.setAttribute('data-theme', 'dark');
			return;
		}
		const themeMode = localStorage.getItem('theme') === themeTypes.darkMode ? 'dark' : 'light';
		document.querySelector('body')?.setAttribute('data-theme', themeMode);
	}, [location.pathname]);

	useEffect(() => {
		const currentDate = new Date();
		for (const [key, value] of Object.entries(oddsData)) {
			let timeDiff = Math.abs(new Date(value?.startsAt).getTime() - currentDate.getTime());

			let diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

			if (diffDays < 1) {
				dispatch(deleteSingleBet({id: key}));
			}
		}
	}, []);

	useEffect(() => {
		const documentHeight = () => {
			const doc = document.documentElement;
			doc.style.setProperty('--doc-height', `${window.innerHeight}px`);
		};

		window.addEventListener('resize', documentHeight);
		return () => {
			window.removeEventListener('resize', documentHeight);
		};
	}, []);

	useEffect(() => {
		const lockOrientation = () => {
			const {screen} = window;
			if (screen && screen.orientation && screen.orientation.lock) {
				screen.orientation
					.lock('portrait')
					.then(() => {
						console.log('Orientation locked to portrait');
					})
					.catch(err => {
						console.error('Failed to lock orientation:', err);
					});
			}
		};

		document.addEventListener('DOMContentLoaded', lockOrientation);

		return () => {
			document.removeEventListener('DOMContentLoaded', lockOrientation);
		};
	}, []);

	useEffect(() => {
		const setVH = () => {
			let vh = window.innerHeight * 0.01;
			document.documentElement.style.setProperty('--window-inner-height', `${vh}px`);
		};

		window.addEventListener('resize', setVH);
		setVH();

		return () => window.removeEventListener('resize', setVH);
	}, []);

	// useEffect(() => {
	// 	let timer = null as any;
	// 	if (counter > 0) {
	// 		timer = setTimeout(() => {
	// 			localStorage.setItem('POT', JSON.stringify({counter: counter - 1}));
	// 			setCounter(counter - 1);
	// 		}, 1000);
	// 	}
	// 	return () => {
	// 		clearTimeout(timer);
	// 	};
	// }, [counter]);

	return (
		<>
			<div id="lock">
				<div className="device"></div>
				<span className="rotate">Please rotate your device</span>
			</div>
			<div className="App" id="container">
				<ConfigProvider
					dropdownMatchSelectWidth={false}
					theme={{
						token: {
							screenLGMin: 1100,
							screenLG: 1200,
							screenLGMax: 1200,

							screenMDMin: 950,
							screenMD: 1100,
							screenMDMax: 1100,

							screenSMMin: 576,
							screenSM: 950,
							screenSMMax: 950
						}
					}}>
					{!location.pathname.includes(CONDITIONS_PAGE_PATHNAME) && <Helmet />}
					<Layout>
						<Suspense fallback={<LoaderSimple />}>
							<Routes>
								{routes.map((route: any) => (
									<Route
										key={`route-key-${route.path}`}
										path={route.path}
										element={
											route.privateRoute ? (
												<PrivateRoute isAuthenticated={isAuthenticated}>
													{route.element && <route.element />}
												</PrivateRoute>
											) : (
												route.element && <route.element />
											)
										}
									/>
								))}
								{/* Nested routes here */}
								{routes.map((route: any) =>
									route.subMenu
										? route.subMenu.map((subRoute: any) => (
												<Route
													key={`route-key-${subRoute.path}`}
													path={subRoute.path}
													element={
														subRoute.privateRoute ? (
															<PrivateRoute isAuthenticated={isAuthenticated}>
																{subRoute.element && <subRoute.element />}
															</PrivateRoute>
														) : (
															subRoute.element && <subRoute.element />
														)
													}
												/>
										  ))
										: null
								)}

								<Route
									path="*"
									element={
										<Result
											status="404"
											title="404"
											subTitle="Sorry, the page you visited does not exist."
											style={{margin: '0 auto', color: 'white'}}
											className="not-found-page"
											extra={
												<Button type="primary" onClick={() => navigate('/')}>
													Back Home
												</Button>
											}
										/>
									}
								/>
							</Routes>
						</Suspense>
					</Layout>
				</ConfigProvider>
			</div>
		</>
	);
};

export default App;
