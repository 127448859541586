import PropTypes from 'prop-types';
import {useCallback, useEffect, useMemo, useRef, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate, useParams, useSearchParams} from 'react-router-dom';
import Slider, {Settings} from 'react-slick';
import {RootState} from 'store';
import {useLazyGetAllSportsQuery} from 'store/services/sportsAPI';
import {setCurrentActiveMenuType} from 'store/slices/menuSlice';
import {setCurrentTopSport} from 'store/slices/topSportSlice';
import {breakpoint, menuTypes} from 'utils/types';
import './EventSwiper.less';
import SliderButton from './components/SliderButtons';
import SliderItem from './components/SliderItem';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {League} from '../Sider/components/Menu';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import {openIndexes} from 'views/LiveOverview/components/config';

export interface SportsProps {
	id: string;
	refId: number;
	name: string;
	image: string;
	createdAt: string;
	updatedAt: string;
	eventCount: number;
	eventSpecialsCount: number;
	leagueSpecialsCount: number;
	hasOfferings: boolean;
	img: string;
}

const getSlidesToShow = (screenWidth: number) => {
	if (screenWidth > 950) {
		return 7;
	} else if (screenWidth > 500 && screenWidth < 950) {
		return 6;
	} else if (screenWidth > 430 && screenWidth < 500) {
		return 5;
	} else if (screenWidth > 360 && screenWidth < 430) {
		return 4;
	}
	return 3;
};

const SimpleSlider = ({
	isVisible,
	setCollapseActiveKeys
}: {
	isVisible: boolean;
	setCollapseActiveKeys: any;
}) => {
	const ordinarResponsive = [
		{
			breakpoint: 10000,
			settings: {
				slidesToShow: 8,
				slidesToScroll: 8,
				initialSlide: 0
			}
		},
		{
			breakpoint: 2000,
			settings: {
				slidesToShow: 8,
				slidesToScroll: 8,
				initialSlide: 0
			}
		},
		{
			breakpoint: 1280,
			settings: {
				slidesToShow: 8,
				slidesToScroll: 8,
				initialSlide: 0
			}
		},
		{
			breakpoint: 1172,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 7,
				initialSlide: 0
			}
		},
		{
			breakpoint: 1060,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 7,
				initialSlide: 0
			}
		},
		{
			breakpoint: 990,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 7,
				initialSlide: 0
			}
		},
		{
			breakpoint: 950,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 7,
				initialSlide: 0
			}
		},
		{
			breakpoint: 870,
			settings: {
				slidesToShow: 6,
				slidesToScroll: 6,
				initialSlide: 0
			}
		},
		{
			breakpoint: 780,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 5,
				initialSlide: 0
			}
		},
		{
			breakpoint: 772,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 7,
				initialSlide: 0
			}
		},
		{
			breakpoint: 670,
			settings: {
				slidesToShow: 6,
				slidesToScroll: 6,
				initialSlide: 0
			}
		}
	];

	const liveOverviewResponsive = [
		{
			breakpoint: 2000,
			settings: {
				slidesToShow: 10,
				slidesToScroll: 10,
				initialSlide: 0
			}
		},
		{
			breakpoint: 1200,
			settings: {
				slidesToShow: 9,
				slidesToScroll: 9,
				initialSlide: 0
			}
		},
		{
			breakpoint: 1060,
			settings: {
				slidesToShow: 8,
				slidesToScroll: 8,
				initialSlide: 0
			}
		},
		{
			breakpoint: 950,
			settings: {
				slidesToShow: 8,
				slidesToScroll: 8,
				initialSlide: 0
			}
		},
		{
			breakpoint: 775,
			settings: {
				slidesToShow: 7,
				slidesToScroll: 7,
				initialSlide: 0
			}
		},
		{
			breakpoint: 630,
			settings: {
				slidesToShow: 6,
				slidesToScroll: 6,
				initialSlide: 0
			}
		},
		{
			breakpoint: 500,
			settings: {
				slidesToShow: 6,
				slidesToScroll: 6,
				initialSlide: 0
			}
		},
		{
			breakpoint: 430,
			settings: {
				slidesToShow: 5,
				slidesToScroll: 5,
				initialSlide: 0
			}
		},
		{
			breakpoint: 360,
			settings: {
				slidesToShow: 4,
				slidesToScroll: 4,
				initialSlide: 0
			}
		}
	];
	const [slidesPosition, setSlidesPosition] = useState(0);
	const [getAllSportsInfo, {data, isSuccess, isLoading}] = useLazyGetAllSportsQuery();

	const isLiveOverview = location.pathname.includes('live-overview');

	const responsive = useMemo(() => {
		if (location.pathname.includes('live-overview')) {
			return liveOverviewResponsive;
		}
		return ordinarResponsive;
	}, [location.pathname, isSuccess, data?.payload]);

	const [searchParam] = useSearchParams();

	const liveSportId = searchParam.get('liveSportId');

	const topMenuData = useMemo(
		() =>
			data?.payload
				.filter((value: League) => value.isTop === true)
				.sort(function (a: League, b: League) {
					return a.listOrder - b.listOrder; // ascending order
				}),
		[data?.payload, isSuccess]
	);

	const screenWidth = useScreenWidth();
	const isMobile = screenWidth < breakpoint.DESKTOP;

	// const SliderSettingsOrdinar: Settings = {
	// 	arrows: false,
	// 	onLazyLoad: () => {
	// 		setSlidesPosition(0);
	// 	},
	// 	infinite: false,
	// 	speed: 500,
	// 	// slidesToShow: 8,
	// 	slidesToScroll: 8,
	// 	initialSlide: 0,
	// 	afterChange: currentSlide => setSlidesPosition(currentSlide),
	// 	responsive: ordinarResponsive
	// };

	const settings: Settings = {
		arrows: false,
		onLazyLoad: () => {
			setSlidesPosition(0);
		},
		infinite: false,
		speed: 500,
		slidesToShow: 8,
		slidesToScroll: 8,
		initialSlide: 0,
		afterChange: currentSlide => setSlidesPosition(currentSlide),
		responsive: isLiveOverview ? liveOverviewResponsive : ordinarResponsive
	};

	const sliderRef = useRef<Slider>(null);

	const [activeMenuID, setActiveMenuID] = useState<string>(data?.payload?.[0]?.id);

	const dispatch = useDispatch();

	const currentOpenMenuType = useSelector((state: RootState) => state.menuSlice);

	const navigate = useNavigate();

	const handleOnClick = (id: string) => {
		setCollapseActiveKeys(openIndexes);
		setActiveMenuID(id);
		navigate(!isLiveOverview ? `/bettings/nearby/${id}` : `/bettings/live-overview?liveSportId=${id}`);
		dispatch(setCurrentActiveMenuType(menuTypes.SWIPER_MENU));
	};

	const slideNext = () => {
		sliderRef.current?.slickNext();
	};

	const slidePrev = () => {
		sliderRef.current?.slickPrev();
	};

	const [isInitialized, setIsInitialized] = useState(false);

	const fetchData = useCallback(async () => {
		try {
			const response = await getAllSportsInfo(
				isLiveOverview ? {hasLiveOfferings: true} : {hasLiveOfferings: false}
			).unwrap();
		} catch (e) {
			console.log(e);
		}
	}, [liveSportId, isLiveOverview]);

	useEffect(() => {
		if (location.pathname === '/') {
			dispatch(setCurrentActiveMenuType(menuTypes.SWIPER_MENU));
			setActiveMenuID(topMenuData?.[0]?.id);
		}
	}, [location.pathname]);

	useEffect(() => {
		fetchData();
	}, [liveSportId, isLiveOverview, getAllSportsInfo]);

	useEffect(() => {
		if (location.pathname.includes('nearby')) {
			const nearbySportId = location.pathname.split('/')[3];
			setActiveMenuID(nearbySportId);
		} else if (location.pathname.includes('live-overview') && liveSportId) {
			setActiveMenuID(liveSportId as string);
		} else {
			setActiveMenuID(topMenuData?.[0]?.id);
			dispatch(setCurrentTopSport({id: topMenuData?.[0]?.id, name: topMenuData?.[0]?.name}));
		}
	}, [topMenuData, location, liveSportId]);

	useEffect(() => {
		const timer = setInterval(() => {
			setIsInitialized(true);
		}, 500); // Delay can be adjusted according to your needs

		return () => clearTimeout(timer); // Cleanup to prevent memory leaks
	}, [isSuccess, data?.payload]);

	useEffect(() => {
		if (isInitialized) {
			sliderRef?.current?.slickGoTo(0);
		}
	}, [isInitialized, isSuccess, data?.payload]);

	useEffect(() => {
		setSlidesPosition(0);
	}, [isVisible]);

	useEffect(() => {
		if (currentOpenMenuType === menuTypes.SIDER_MENU) {
			setActiveMenuID('');
			return;
		} else if (currentOpenMenuType === menuTypes.SWIPER_MENU && location.pathname === '/') {
			setActiveMenuID(topMenuData?.[0]?.id);
		}
	}, [currentOpenMenuType, location.pathname, topMenuData]);

	const ordinarSports = useMemo(
		() => data?.payload.filter((value: League) => value.isTop === false),
		[data?.payload, isSuccess]
	);

	return isVisible && !isMobile && !!data?.payload?.length && isSuccess ? (
		<div className={`events-swiper-wrapper`}>
			<Slider {...settings} ref={sliderRef}>
				{topMenuData?.map((sport: SportsProps, index: number) =>
					sport.eventCount ? (
						<SliderItem
							key={'slider-item' + index}
							id={sport?.id}
							icon={sport?.image}
							sportName={
								sport?.name?.length < 15 ? sport?.name : sport?.name.slice(0, 10) + '...'
							}
							isActive={activeMenuID === sport?.id}
							onClick={handleOnClick}
						/>
					) : null
				)}
				{ordinarSports?.map((sport: SportsProps, index: number) =>
					sport.eventCount ? (
						<SliderItem
							key={'slider-item' + index}
							id={sport?.id}
							icon={sport?.image}
							sportName={
								sport?.name?.length < 15 ? sport?.name : sport?.name.slice(0, 10) + '...'
							}
							isActive={activeMenuID === sport?.id}
							onClick={handleOnClick}
						/>
					) : null
				)}
			</Slider>
			{slidesPosition !== 0 && <SliderButton direction="prev" onClick={slidePrev} isVisible={true} />}
			{slidesPosition !== data?.payload?.length - getSlidesToShow(screenWidth) - 1 && (
				<SliderButton direction="next" onClick={slideNext} isVisible={true} />
			)}
		</div>
	) : isVisible && isMobile && !!data?.payload?.length ? (
		<div className={`events-swiper-wrapper events-swiper-wrapper__mobile`}>
			{topMenuData?.map((sport: SportsProps, index: number) =>
				sport.eventCount ? (
					<SliderItem
						key={'slider-item' + index}
						id={sport?.id}
						icon={sport?.image}
						sportName={sport?.name?.length < 15 ? sport?.name : sport?.name.slice(0, 10) + '...'}
						isActive={activeMenuID === sport?.id}
						onClick={handleOnClick}
					/>
				) : null
			)}
			{ordinarSports?.map((sport: SportsProps, index: number) =>
				sport.eventCount ? (
					<SliderItem
						key={'slider-item' + index}
						id={sport?.id}
						icon={sport?.image}
						sportName={sport?.name?.length < 15 ? sport?.name : sport?.name.slice(0, 10) + '...'}
						isActive={activeMenuID === sport?.id}
						onClick={handleOnClick}
					/>
				) : null
			)}
		</div>
	) : null;
};

export default SimpleSlider;

SimpleSlider.propTypes = {
	setCollapseActiveKeys: PropTypes.any,
	payload: PropTypes.arrayOf(
		PropTypes.shape({
			id: PropTypes.string.isRequired,
			refId: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			image: PropTypes.string.isRequired,
			createdAt: PropTypes.string.isRequired,
			updatedAt: PropTypes.string.isRequired,
			eventCount: PropTypes.number.isRequired,
			eventSpecialsCount: PropTypes.number.isRequired,
			leagueSpecialsCount: PropTypes.number.isRequired,
			hasOfferings: PropTypes.bool.isRequired
		})
	)
};
