import {createSlice} from '@reduxjs/toolkit';

const allLeaguesGetterSlice = createSlice({
	name: 'allLeaguesGetterSlice',
	initialState: {},
	reducers: {
		getAllLeagues(_, action) {
			return action.payload;
		}
	}
});

export const {getAllLeagues} = allLeaguesGetterSlice.actions;
export default allLeaguesGetterSlice.reducer;
