import {Button, Select} from 'antd';
import LoaderSimple from 'components/Loading/LoaderSimple';
import {useCallback, useEffect, useRef, useState} from 'react';
import {IoIosArrowDropupCircle} from 'react-icons/io';
import {useSelector} from 'react-redux';
import {useParams, useSearchParams} from 'react-router-dom';
import {RootState} from 'store';
import {
	useGetNearbyGamesBySportIdQuery,
	useLazyGetNearbyGamesBySportIdQuery
} from 'store/services/fixturesAPI';
import {swapTeams} from 'utils/helpers/homeTeamTypeValidator';
import {OddsProps} from 'utils/interfaces';
import GridTemplate from 'views/MainPageBets/components/GridTemplate';
import NestedGridHeader from 'views/MainPageBets/components/NestedGridHeader';
import './NearbyGames.less';
import {betCloseStatus, betType} from 'utils/types';
import useScreenWidth from 'utils/hooks/useScreenWidth';
import {useTranslation} from 'react-i18next';
import Popup from 'components/Popup/Popup';
import ResetPasswordForm from 'components/Popup/ResetPasswordForm';
import ResetPassword from 'components/Popup/ResetPassword';
import {useEmailVerificationMutation} from 'store/services/authAPI';
import {toast} from 'react-toastify';

const PAGE_SIZE = 10;

export interface NearbyGameProps {
	id: string;
	leagueId: string;
	home: string;
	away: string;
	sportName?: string;
	specialMarketsCount: number;
	marketsCount: number;
	odd: {
		periods: {
			moneyline: {
				home: number;
				away: number;
				draw: number;
			};
			spreads: {
				home: number;
				away: number;
				hdp: number;
			}[];
			totals: {
				over: number;
				under: number;
				points: number;
			}[];
			teamTotal: {
				home: {
					points: number;
					over: number;
					under: number;
				};
				away: {
					points: number;
					over: number;
					under: number;
				};
			};
			maxSpread: number;
			maxTotal: number;
			maxMoneyline: number;
			cutoff: string;
			status: number;
		}[];
		marketsCount: string;
	};
	eventId: number;
	starts: string;
	leagueName: string;
	homeTeamType: 'Team1' | 'Team2';
}

interface NearbyGameDefaultProps {
	defaultShow?: boolean;
}

const NearbyGames = ({defaultShow}: NearbyGameDefaultProps) => {
	const {sportId} = useParams();
	const [currentPage, setCurrentPage] = useState<number>(1);
	const [defaultFields, setDefaultFields] = useState({
		draw: false,
		handicap: false
	});
	const [nearbyGamesState, setNearbyGamesState] = useState<NearbyGameProps[]>([]);
	const prevNearbyGamesRef = useRef<NearbyGameProps[]>([]);
	const [pagesCount, setPagesCount] = useState<number>();
	const [selectedBetType, setSelectedBetType] = useState<string>(betType.MONEYLINE);

	const [authModalOpen, setAuthModalOpen] = useState(true);

	const currentTopSport = useSelector((state: RootState) => state.topSportSlice) as {
		id: string;
		name: string;
	};

	const [searchParam] = useSearchParams();
	const token = searchParam.get('q');

	const screenWidth = useScreenWidth();

	const {t} = useTranslation();

	const id = defaultShow ? currentTopSport?.id : sportId;

	const [getNearbyGamesBySportId, {data: nearbyGames, isFetching, isLoading, currentData}] =
		useLazyGetNearbyGamesBySportIdQuery();

	const [loadDataOnclick, {data: loadedData}] = useLazyGetNearbyGamesBySportIdQuery();

	const [emailVerification] = useEmailVerificationMutation();

	const handleAuthModalClose = () => {
		setAuthModalOpen(false);
	};

	const getDefaultFields = (nearbyGames: any) => {
		if (id || defaultShow) {
			const defaultFieldsObj = {handicap: false, draw: false};
			for (let i = 0; i < nearbyGames?.payload?.data?.length; i++) {
				if (
					nearbyGames?.payload.data?.[i]?.odd?.periods?.[0].spreads?.length !== 0 &&
					nearbyGames?.payload.data?.[i]?.odd?.periods?.[0].moneyline?.draw
				) {
					defaultFieldsObj.draw = true;
					defaultFieldsObj.handicap = true;
					break;
				} else if (nearbyGames?.payload?.data?.[i]?.odd?.periods?.[0].spreads?.length !== 0) {
					defaultFieldsObj.handicap = true;
				} else if (nearbyGames?.payload?.data?.[i]?.odd?.periods?.[0].moneyline?.draw) {
					defaultFieldsObj.draw = true;
				}
			}
			setDefaultFields(defaultFieldsObj);
		} else {
			return;
		}
	};

	const fetchNearbyFixtures = async (loadOnclick?: boolean) => {
		prevNearbyGamesRef.current = nearbyGamesState;
		if (id) {
			const currentPageSize = loadOnclick ? PAGE_SIZE : currentPage * PAGE_SIZE;
			const currentPageToCall = loadOnclick ? currentPage : 1;
			const response = await getNearbyGamesBySportId({
				id,
				params: {page: currentPageToCall, pageSize: currentPageSize}
			}).unwrap();
			getDefaultFields(response);
			setNearbyGamesState(prev => [...response?.payload?.data]);
			setPagesCount(response?.payload?.totalPages);
		}
	};

	const loadCurrentPageData = useCallback(async () => {
		if (id) {
			const currentPageSize = PAGE_SIZE;
			const currentPageToCall = currentPage;
			const response = await loadDataOnclick({
				id,
				params: {page: currentPageToCall, pageSize: currentPageSize}
			}).unwrap();
			getDefaultFields(response);
			setNearbyGamesState(prev => [...prev, ...response?.payload?.data]);
		}
	}, [currentPage]);

	// basketball
	const handleBasketballhandicapCase = () => {
		if (id === '6579e876c629b8d3fb5295c8' || id === '65bd3063dbc21bd75df0031d') {
			setSelectedBetType(betType.HANDICAP);
		} else if (selectedBetType === betType.HANDICAP) {
			setSelectedBetType(betType.MONEYLINE);
		}
	};

	useEffect(() => {
		handleBasketballhandicapCase();
		setCurrentPage(1);
		prevNearbyGamesRef.current = [];
		setNearbyGamesState([]);
	}, [id]);

	useEffect(() => {
		const intervalId = setInterval(fetchNearbyFixtures, 10000);

		return () => {
			clearInterval(intervalId);
		};
	}, [nearbyGamesState]);

	useEffect(() => {
		fetchNearbyFixtures();
	}, [currentPage, nearbyGames, id]);

	useEffect(() => {
		loadCurrentPageData();
	}, [currentPage]);

	const handleEmailVerification = useCallback(() => {
		if (location.pathname.includes('email-confirmation') && token) {
			try {
				const response = emailVerification({token}).unwrap();
			} catch (e) {
				toast.warn('Network Error');
			}
		}
	}, []);

	useEffect(() => {
		handleEmailVerification();
	}, []);

	if (isLoading || (nearbyGamesState.length === 0 && isFetching)) return <LoaderSimple />;

	return (
		<div className="nearby-games-wrapper">
			<div className="nearby-games-wrapper__hint">
				<span>{t('startingNext')}</span>
				{screenWidth <= 1200 && (
					<Select
						className={`ant-select-selection-item header-down-common-text`}
						defaultValue={selectedBetType}
						onChange={(value: string) => {
							setSelectedBetType(value);
						}}
						bordered={false}
						options={[
							{value: betType.MONEYLINE, label: 'Money line Match'},
							{value: betType.HANDICAP, label: 'Handicap Match'},
							{value: betType.TOTAL, label: 'Match Total'}
						]}
						suffixIcon={null}
					/>
				)}
			</div>
			{nearbyGamesState?.map((value: NearbyGameProps, index: number) => {
				const validatedByHomeTeamType = swapTeams(value as unknown as OddsProps);
				const prevGame = prevNearbyGamesRef.current.find(item => item?.eventId === value?.eventId);
				const validatedPrevFixture = swapTeams(prevGame as unknown as OddsProps);
				const currentDate = new Date();
				const cutoffDate = new Date(validatedByHomeTeamType?.odd?.periods?.[0]?.cutoff);
				const isBetOpen =
					currentDate < cutoffDate &&
					validatedByHomeTeamType?.odd?.periods?.[0]?.status !== betCloseStatus;
				const isSuspended = currentDate > cutoffDate;
				const isBetClosedByStatus =
					validatedByHomeTeamType?.odd?.periods?.[0]?.status === betCloseStatus;

				return (
					<table key={'nearby-game' + value?.eventId + index + value.starts}>
						<NestedGridHeader
							eventId={validatedByHomeTeamType?.eventId}
							container={validatedByHomeTeamType.leagueName}
							defaultFields={defaultFields}
							leagueId={validatedByHomeTeamType.leagueId}
							currentSelectedBetType={selectedBetType}
						/>
						<GridTemplate
							sportName={value.sportName}
							parentId={validatedByHomeTeamType.parentId}
							liveStatus={validatedByHomeTeamType.liveStatus}
							isBetOpen={isBetOpen}
							isSuspended={isSuspended}
							isBetClosedByStatus={isBetClosedByStatus}
							periodNumber={validatedByHomeTeamType?.odd?.periods?.[0]?.number}
							currentSelectedBetType={selectedBetType}
							leagueId={validatedByHomeTeamType.leagueId}
							betID={validatedByHomeTeamType.id}
							key={validatedByHomeTeamType.id + index + validatedByHomeTeamType.leagueId}
							club1={validatedByHomeTeamType.home}
							club2={validatedByHomeTeamType.away}
							coefficents={
								validatedByHomeTeamType?.odd &&
								// validatedByHomeTeamType?.odd?.periods?.[0].number === 0 &&
								isBetOpen
									? {
											firstWinCF:
												validatedByHomeTeamType?.odd?.periods?.[0]?.moneyline?.home,
											secondWinCF:
												validatedByHomeTeamType?.odd?.periods?.[0]?.moneyline?.away,
											drawCF: validatedByHomeTeamType?.odd?.periods?.[0]?.moneyline
												?.draw,
											handicap1CF:
												validatedByHomeTeamType?.odd?.periods?.[0]?.spreads.find(
													i => i?.altLineId === undefined
												)?.home ?? 0,
											handicap2CF:
												validatedByHomeTeamType?.odd?.periods?.[0]?.spreads.find(
													i => i?.altLineId === undefined
												)?.away ?? 0,
											overCF:
												validatedByHomeTeamType?.odd?.periods?.[0]?.totals.find(
													i => i?.altLineId === undefined
												)?.over ?? 0,
											underCF:
												validatedByHomeTeamType?.odd?.periods?.[0]?.totals.find(
													i => i?.altLineId === undefined
												)?.under ?? 0,
											handicapDiff:
												validatedByHomeTeamType?.odd?.periods?.[0]?.spreads.find(
													i => i?.altLineId === undefined
												)?.hdp ?? 0,
											overDiff:
												validatedByHomeTeamType?.odd?.periods?.[0]?.totals.find(
													i => i?.altLineId === undefined
												)?.points ?? 0
									  }
									: {
											firstWinCF: -1,
											secondWinCF: -1,
											drawCF: -1,
											handicap1CF: -1,
											handicap2CF: -1,
											overCF: -1,
											underCF: -1,
											handicapDiff: -1,
											overDiff: -1
									  }
							}
							prevCoefficents={
								validatedByHomeTeamType?.odd
									? {
											firstWinCF:
												validatedPrevFixture?.odd?.periods?.[0]?.moneyline?.home,
											secondWinCF:
												validatedPrevFixture?.odd?.periods?.[0]?.moneyline?.away,
											drawCF: validatedPrevFixture?.odd?.periods?.[0]?.moneyline?.draw,
											handicap1CF:
												validatedPrevFixture?.odd?.periods?.[0]?.spreads.find(
													i => i?.altLineId === undefined
												)?.home ?? 0,
											handicap2CF:
												validatedPrevFixture?.odd?.periods?.[0]?.spreads.find(
													i => i?.altLineId === undefined
												)?.away ?? 0,
											overCF:
												validatedPrevFixture?.odd?.periods?.[0]?.totals.find(
													i => i?.altLineId === undefined
												)?.over ?? 0,
											underCF:
												validatedPrevFixture?.odd?.periods?.[0]?.totals.find(
													i => i?.altLineId === undefined
												)?.under ?? 0,
											handicapDiff:
												validatedPrevFixture?.odd?.periods?.[0]?.spreads.find(
													i => i?.altLineId === undefined
												)?.hdp ?? 0,
											overDiff:
												validatedPrevFixture?.odd?.periods?.[0]?.totals.find(
													i => i?.altLineId === undefined
												)?.points ?? 0
									  }
									: {
											firstWinCF: -1,
											secondWinCF: -1,
											drawCF: -1,
											handicap1CF: -1,
											handicap2CF: -1,
											overCF: -1,
											underCF: -1,
											handicapDiff: -1,
											overDiff: -1
									  }
							}
							eventId={validatedByHomeTeamType?.eventId}
							id={validatedByHomeTeamType?.id}
							startsAt={new Date(validatedByHomeTeamType?.starts).toLocaleString('en-US', {
								month: 'long',
								day: '2-digit',
								hour: 'numeric',
								minute: 'numeric',
								hour12: true
							})}
							nearbyGames
							leagueName={validatedByHomeTeamType?.leagueName}
							marketsCount={
								validatedByHomeTeamType?.marketsCount +
								validatedByHomeTeamType?.specialMarketsCount
							}
							maxBets={{
								maxMoneyline: validatedByHomeTeamType?.odd?.periods?.[0]?.maxMoneyline,
								maxSpread:
									validatedByHomeTeamType?.odd?.periods?.[0]?.spreads?.find(
										i => i?.altLineId === undefined
									)?.max || validatedByHomeTeamType?.odd?.periods?.[0]?.maxSpread, // if in object there is not a max, take from parent object
								maxTotal: validatedByHomeTeamType?.odd?.periods?.[0]?.maxTotal
							}}
							defaultFields={defaultFields}
							homeTeamType={value?.homeTeamType}
							startsAtNotFormatted={validatedByHomeTeamType?.starts}
						/>
					</table>
				);
			})}
			<div className="load-btn-wrapper">
				{pagesCount && pagesCount > currentPage && (
					<Button
						type="primary"
						onClick={() => {
							setCurrentPage((prev: number) => prev + 1);
						}}>
						{t('loadMore')}
					</Button>
				)}
			</div>

			{!!nearbyGamesState.length && location.pathname.includes('password-reset') && (
				<Popup headerText="Reset Password" onClose={handleAuthModalClose} open={authModalOpen}>
					<ResetPassword handleModalClose={handleAuthModalClose} />
				</Popup>
			)}
		</div>
	);
};

export default NearbyGames;
