import {useEffect, useState} from 'react';
import './CustomNumpad.less';
import {IoBackspace} from 'react-icons/io5';
import {numpadActionTypes} from 'utils/types';
import {RootState} from 'store';
import {useSelector} from 'react-redux';

interface CustomNumpadProps {
	setInputValue: (type: string, key?: string, id?: string, coefficent?: number, maxBet?: number) => void;
	closeNumpad: () => void;
}

const CustomNumpad = ({setInputValue, closeNumpad}: CustomNumpadProps) => {
	const numericKeys = ['1', '2', '3', '4', '5', '6', '7', '8', '9', '00', '0', '.'];
	const darkMode = useSelector((state: RootState) => state.darkMode);

	const [buttonID, setButtonID] = useState('');
	const [isAnimating, setIsAnimating] = useState(false);

	const handleClick = (id: string) => {
		setIsAnimating(true);
		setButtonID(id);
	};

	useEffect(() => {
		if (isAnimating) {
			const animationTimeout = setTimeout(() => {
				setIsAnimating(false);
			}, 200); // Adjust the time to match the duration of your animation (in milliseconds)

			return () => clearTimeout(animationTimeout);
		}
	}, [isAnimating]);

	return (
		<div className="custom-numpad-wrapper">
			<div className="numpad-numeric-keys">
				{numericKeys.map(key => (
					<div
						className={`numpad-key ${
							isAnimating && buttonID === `numpad-key-${key}` ? 'animate-click' : ''
						}`}
						key={key}
						onClick={() => {
							key && setInputValue(numpadActionTypes.ADD, key);
							handleClick(`numpad-key-${key}`);
						}}>
						{key}
					</div>
				))}
			</div>
			<div className="non-numeric-keys">
				<div
					className={`numpad-key ${
						isAnimating && buttonID === `numpad-key-delete` ? 'animate-click' : ''
					}`}
					onClick={() => {
						setInputValue(numpadActionTypes.DELETE);
						handleClick(`numpad-key-delete`);
					}}>
					<IoBackspace color={darkMode === 'darkMode' ? 'white' : 'black'} />
				</div>
				<div className="numpad-key approve-key" onClick={closeNumpad}>
					OK
				</div>
			</div>
		</div>
	);
};

export default CustomNumpad;
