import {Button, Form, Input} from 'antd';
import {PropsWithChildren} from 'react';
import {useTranslation} from 'react-i18next';
import {toast} from 'react-toastify';
import {useResetPasswordPermissionMutation} from 'store/services/authAPI';
import './Popup.less';

interface LoginPopupProps {
	handleModalClose: () => void;
}

export interface ResetPasswordFormProps {
	email: string;
}

const onFinishFailed = (errorInfo: any) => {};

const ResetPasswordForm = ({handleModalClose}: PropsWithChildren<LoginPopupProps>) => {
	const [resetPasswordPermission, {isLoading, isSuccess, isError, error, data}] =
		useResetPasswordPermissionMutation();

	const {t} = useTranslation();

	const onFinish = async (values: ResetPasswordFormProps) => {
		try {
			const response = await resetPasswordPermission(values).unwrap();
			handleModalClose();
			toast.success(response.message);
		} catch (error) {
			toast.error('Login or Password is incorrect');
		}
	};

	return (
		<div className="form-wrapper">
			<Form
				name="basic"
				style={{maxWidth: 600, minWidth: 130}}
				initialValues={{remember: true}}
				onFinish={onFinish}
				layout="vertical">
				<div className="field-row">
					<Form.Item
						label="Please enter your email address"
						name="email"
						rules={[{required: true, message: 'Required'}]}>
						<Input type="email" placeholder={t('email') || 'Email'} />
					</Form.Item>
				</div>

				<Form.Item wrapperCol={{offset: 8, span: 16}} className="submit-button">
					<Button type="primary" htmlType="submit" style={{width: '100%'}}>
						<span className="submit-button__btn">RESET</span>
					</Button>
				</Form.Item>
			</Form>
		</div>
	);
};

export default ResetPasswordForm;
