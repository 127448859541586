import {Button, Divider, Form, Input} from 'antd';
import {FormEvent, PropsWithChildren} from 'react';
import {useLogInMutation} from 'store/services/authAPI';
import './Popup.less';
import {useDispatch} from 'react-redux';
import {toast} from 'react-toastify';
import {setUser, setUserCredentials} from 'store/slices/authSlice';
import {authorizationTypes, modalTypes} from 'utils/types';
import {useTranslation} from 'react-i18next';
import PASSWORDICON from 'assets/icons/from-password-icon.svg';
import EMAILICON from 'assets/icons/form-message-icon.svg';

interface LoginPopupProps {
	onClick: () => void;
	handleModalClose: () => void;
	handleModalType: (val: string) => void | any;
}

export interface LoginFormProps {
	email: string;
	password: string;
}

const onFinishFailed = (errorInfo: any) => {};

const LoginForm = ({onClick, handleModalClose, handleModalType}: PropsWithChildren<LoginPopupProps>) => {
	const [logIn, {isLoading, isSuccess, isError, error, data}] = useLogInMutation();
	const dispatch = useDispatch();

	const {t} = useTranslation();

	const onFinish = async (values: LoginFormProps) => {
		try {
			const response = await logIn(values).unwrap();
			const modifiedUserCredentials = setUserCredentials(response);
			dispatch(
				setUser({
					isAuthenticated: authorizationTypes.AUTHORIZED,
					userCredentials: modifiedUserCredentials
				})
			);
			handleModalClose();
		} catch (error) {
			toast.error('Login or Password is incorrect');
		}
	};

	return (
		<div
			className="form-wrapper"
			id="login-form-wrapper"
			style={{display: 'flex', flexDirection: 'column'}}>
			<Divider
				style={{
					background: '#2a2a2e'
				}}
			/>
			<Form
				name="basic"
				style={{maxWidth: 600}}
				initialValues={{remember: true}}
				onFinish={onFinish}
				onFinishFailed={onFinishFailed}
				layout="vertical">
				<div>
					<div className="field-row">
						<Form.Item label="Email" name="email" rules={[{required: true, message: 'Required'}]}>
							<Input
								prefix={
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<img src={EMAILICON} />{' '}
										<Divider
											type="vertical"
											style={{background: '#262626', height: 30, margin: '0 14px'}}
										/>
									</div>
								}
								type="email"
							/>
						</Form.Item>
					</div>

					<div className="user-password field-row">
						<Form.Item
							label={t('password')}
							name="password"
							rules={[{required: true, message: 'Required'}]}>
							<Input
								prefix={
									<div style={{display: 'flex', flexDirection: 'row'}}>
										<img src={PASSWORDICON} style={{marginLeft: 2}} />{' '}
										<Divider
											type="vertical"
											style={{margin: '0 14px', background: '#262626', height: 30}}
										/>
									</div>
								}
								type="password"
								autoComplete="passwordd"
							/>
						</Form.Item>
					</div>
					<div style={{textAlign: 'center'}}>
						<span className="login-join-hint__regular">Forgot your</span>&nbsp;&nbsp;
						<span
							className="login-join-hint__bold"
							onClick={() => handleModalType(modalTypes.RESET_PASSWORD)}>
							Password ?
						</span>
					</div>
				</div>
				<div>
					<Form.Item wrapperCol={{offset: 8, span: 16}} className="submit-button">
						<Button
							type="primary"
							htmlType="submit"
							style={{
								width: '100%',

								background: '#2B90FF',
								boxShadow: '0px 0px 7px rgba(0, 0, 0, 0.4)',
								borderRadius: '2px'
							}}
							// onClick={() => handleModalType(modalTypes.SIGN_UP)}
						>
							<span className="submit-button__btn">{t('logIn')}</span>
						</Button>
					</Form.Item>
					<Divider
						style={{
							background: '#2a2a2e'
						}}
					/>
					<div className="login-join-hint">
						<div className="text-line">
							<span className="login-join-hint__regular">Not a member yet?</span>
							<span className="login-join-hint__bold" onClick={onClick}>
								Join Now
							</span>
						</div>
						<div className="text-line">
							{/* <span className="login-join-hint__regular">Forgot</span>
					<span className="login-join-hint__bold">Email</span> */}
						</div>
					</div>
				</div>
			</Form>
		</div>
	);
};

export default LoginForm;
