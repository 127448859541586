import DOMPurify from 'dompurify';
import {useEffect, useState} from 'react';
import {API_PREFIX} from 'store/services/api';
import axiosClient from './axiosClient';
import MenuLoader from 'components/Loading/MenuLoader';

interface SVGComponentProps {
	path: string;
	width: number;
	height: number;
}

function handleSVG(svgString: string, newWidth: number, newHeight: number) {
	// Create a temporary DOM element for the SVG
	const tempElement = document.createElement('div');
	tempElement.innerHTML = svgString;

	// Get the SVG element
	const svgElement = tempElement.querySelector('svg');

	// Update the width and height attributes
	svgElement?.setAttribute('width', newWidth + 'px');
	svgElement?.setAttribute('height', newHeight + 'px');

	for (const key in svgElement?.childNodes) {
		if (Object.prototype.hasOwnProperty.call(svgElement?.childNodes, key)) {
			const element = svgElement?.childNodes[key as keyof typeof svgElement.childNodes] as SVGElement;
			element?.classList?.add('svg-stroke');
		}
	}

	// Serialize the updated SVG element to XML
	const serializer = new XMLSerializer();
	const resizedSvgString = serializer.serializeToString(svgElement || tempElement);

	return resizedSvgString;
}

const SVGComponent = ({path, height, width}: SVGComponentProps) => {
	const [loading, setLoading] = useState(true);
	const [imageData, setImageData] = useState<string | null>(null);

	useEffect(() => {
		axiosClient
			.get(API_PREFIX + path)
			.then(res => {
				let untrustedSVGString = res.data as string;

				let resizedTrustedSVGString = handleSVG(
					DOMPurify.sanitize(untrustedSVGString),
					width,
					height
				);

				setImageData(resizedTrustedSVGString);
			})
			.finally(() => {
				setLoading(false);
			});
	}, [path]);

	if (loading || !imageData) return <MenuLoader />;

	return <div className="svg_container" dangerouslySetInnerHTML={{__html: imageData}} />;
};

export default SVGComponent;
